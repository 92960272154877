.img-container img {
  max-width: 100%;
  display: block;
  position: relative;
}

.cross-icon {
  position: absolute;
  right: 15px;
  top: 13px;
  border: 2px solid #ffffff;
  opacity: 1;
  border-radius: 50%;
  color: #ffffff;
  cursor: pointer;
  line-height: 77%;
  font-size: 30px;
}

.event-info .event {
  display: flex;
  justify-content: space-around;
}

.event-info {
  padding: 5%;
}

.event-info h4 {
  opacity: 0.6;
}

.event-btn {
  text-align: center;
  padding-top: 8px;
  /* margin-bottom: 5%; */
}

.message {
  font-size: 18px;
}
