body {
  margin: 0;
  padding: 0;
  font-family: 'SFProDisplay-Regular';
}

*,
:after,
:before {
  box-sizing: border-box;
}

a,
button {
  text-decoration: none;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@font-face {
  font-family: 'FontsFree-Net-SFProDisplay-Regular';
  src: url('../../../src/common/fonts/FontsFree-Net-SFProDisplay-Regular.eot');
  src: url('../../../src/common/fonts/FontsFree-Net-SFProDisplay-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../../../src/common/fonts/FontsFree-Net-SFProDisplay-Regular.woff2')
      format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFProDisplay-Regular';
  src: url('../../../src/common/fonts/SFProDisplay-Regular.svg#SFProDisplay-Regular')
      format('svg'),
    url('../../../src/common/fonts/SFProDisplay-Regular.ttf') format('truetype'),
    url('../../../src/common/fonts/SFProDisplay-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FontsFree-Net-SFProDisplay-Bold';
  src: url('../../../src/common/fonts/FontsFree-Net-SFProDisplay-Bold.eot');
  src: url('../../../src/common/fonts/FontsFree-Net-SFProDisplay-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('../../../src/common/fonts/FontsFree-Net-SFProDisplay-Bold.woff2')
      format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFProDisplay-Bold';
  src: url('../../../src/common/fonts/SFProDisplay-Bold.svg#SFProDisplay-Bold')
      format('svg'),
    url('../../../src/common/fonts/SFProDisplay-Bold.ttf') format('truetype'),
    url('../../../src/common/fonts/SFProDisplay-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* Login Screen Css Start */
.logo-header a.logo img {
  /* padding-left: 85px;
  padding-top: 70px; */
  padding-left: 5px;
  padding-top: 20px;
}

.login-back-img {
  width: 100%;
  height: 100vh;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../../../src/common/img/login-back.png);
  position: relative;
}

.email-confirmed-back-img {
  width: 100%;
  height: 100vh;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../../../src/common/img/confirmed-back.png);
  position: relative;
}

.loginpass-back-img {
  width: 100%;
  height: 100vh;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../../../src/common/img/login-screen-back.png);
  position: relative;
}

.modal-layout {
  width: 740px;
  max-width: 90%;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #6c6c6c5c;
  border-radius: 46px;
  padding: 70px 70px;
}
.modal-layout h2 {
  color: #287fde;
  font-size: 55px;
  letter-spacing: 0;
  font-family: 'SFProDisplay-Bold';
  font-weight: 400;
  margin-bottom: 50px;
  text-align: center;
}
.mb-50 {
  margin-bottom: 50px;
}

.modal-layout .email-input {
  border: 1px solid #6c6c6c;
  border-radius: 4px;
  font-size: 31px;
  font-family: 'SFProDisplay-Light';
  /* opacity: 0.5; */
}

.modal-layout input.form-control,
.modal-layout select.form-control {
  padding: 10px 25px;
  border: none;
  margin-bottom: 0;

  color: #6c6c6c;
  background-color: white;
  border-bottom: none;
  font-size: 22px;
  font-family: 'SFProDisplay-Regular';
  font-weight: 300;
  letter-spacing: 0;
  background-color: #fff !important;
  border-radius: 4px;
  width: -webkit-fill-available;
}

.modal-layout input.form-control:focus {
  border-bottom: none !important;
}

.modal-layout input.form-control::placeholder,
.css-1uccc91-singleValue::placeholder {
  opacity: 0.5;
}

.modal-layout p {
  color: #6c6c6c;
  font-size: 22px;
  font-family: 'SFProDisplay-Regular';
  font-weight: 400;
  letter-spacing: 0;
  margin: 30px 0 70px 0;
  text-align: left;
}

/* Drop down */
.css-yk16xz-control {
  background-color: #fff;
  border-color: #6c6c6c !important;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
}
.css-1okebmr-indicatorSeparator {
  background-color: #6c6c6c !important;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  width: 1px !important;
  box-sizing: none !important;
  border-width: 10px !important;
}
.css-1hb7zxy-IndicatorsContainer,
.css-1wy0on6 {
  visibility: hidden;
}

.css-1pahdxg-control,
.css-1pahdxg-control :hover {
  border-color: #6c6c6c !important;
  box-shadow: none !important;
  outline: none !important;
}

.css-b8ldur-Input {
  margin: 2px;
  padding-bottom: 2px;
  padding-top: 2px;
  visibility: visible;
  font-size: 22px !important;
  color: #6c6c6c !important;
  box-sizing: border-box;
}
.css-1uccc91-singleValue {
  color: #6c6c6c !important;
  margin-left: 2px;
  margin-right: 2px;
  font-size: 22px !important;
  max-width: calc(100% - 8px);
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  white-space: nowrap;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  box-sizing: border-box;
  /* padding: 2px 15px !important; */
}

.css-1hwfws3 {
  color: #6c6c6c;
  margin-left: 0px !important;
  margin-right: 0px !important;
  position: absolute;
  box-sizing: border-box;
  font-size: 22px;
  padding: 2px 22px !important;
  height: 67px;
}

.css-g1d714-ValueContainer {
  padding: 2px 23px !important;
  border: none;
  margin-bottom: 0;

  color: #6c6c6c;
  box-shadow: none;
  background-color: white !important;
  border-bottom: none;
  font-size: 22px !important;
  font-family: 'SFProDisplay-Regular';
  font-weight: 300;
  letter-spacing: 0;
  border-radius: 4px;
  width: -webkit-fill-available;
}

.css-1wa3eu0-placeholder {
  color: #6c6c6c;
  margin-left: -15px !important;
  margin-right: 0px !important;
  position: absolute;
  box-sizing: border-box;
  font-size: 22px;
  padding: 15px 15px !important;
  /* height: 67px; */
  opacity: 0.5;
}
/* Drop down */

.btn-next a {
  background: #287fde 0% 0% no-repeat padding-box;
  border: 1px solid #287fde;
  border-radius: 6px;
  padding: 20px;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'SFProDisplay-Bold';
  font-weight: 400;
  text-decoration: none;
  cursor: pointer;
  width: 100%;
  display: block;
  text-align: center;
  margin-bottom: 30px;
}
.btn-next a:hover {
  opacity: 0.9;
  color: #fff;
}

.link-info a {
  letter-spacing: 0px;
  color: #287fde;
  font-size: 22px;
  font-family: 'SFProDisplay-Regular';
  font-weight: 400;
  text-decoration: underline;
  text-align: left;
  border-bottom: 1px;
}

.link-info span {
  color: #6c6c6c;
  font-size: 22px;
  font-family: 'SFProDisplay';
  font-weight: 400;
}
h2.mb-30 {
  margin-bottom: 30px;
}

.forgot-pass {
  color: #287fde;
  font-size: 22px;
  font-family: 'SFProDisplay';
  font-weight: 400;
  text-decoration: none;
}
.forgot-pass:hover {
  color: #287fde;
  text-decoration: none;
}

/*========Responsive===========*/

@media screen and (max-width: 991px) {
  /* .modal-layout {
    padding: 50px 30px;
    width: 650px;
  }
  .modal-layout h2 {
    margin-bottom: 30px;
  }
  .modal-layout p {
    margin-bottom: 30px;
  }
  .btn-next a {
    padding: 10px;
    margin-bottom: 30px;
  } */

  /* .modal-layout h2 {
    font-size: 30px;
  }
  .modal-layout p {
    font-size: 16px;
  }
  .modal-layout input.form-control,
  .css-g1d714-ValueContainer {
    font-size: 16px;
    padding-left: 10px !important;
  }
  .css-g1d714-ValueContainer {
    font-size: 16px;
    padding: 2px 10px !important;
  } */
}

@media screen and (max-width: 768px) {
  .modal-layout h2 {
    font-size: 30px;
  }
  .modal-layout p {
    font-size: 16px;
  }
  .modal-layout input.form-control,
  .css-g1d714-ValueContainer,
  .css-1hwfws3 {
    font-size: 16px;
    padding-left: 10px !important;
  }
  .css-g1d714-ValueContainer {
    font-size: 16px;
    /* padding: 2px 10px !important; */
  }
  .css-1wa3eu0-placeholder,
  .css-1uccc91-singleValue {
    font-size: 16px !important;
    /* padding: 2px 2px !important; */
  }

  .btn-next a {
    font-size: 16px;
    padding: 10px;
    margin-bottom: 20px;
  }
  .modal-layout p {
    margin-bottom: 20px;
  }
  .link-info a {
    font-size: 16px;
  }
  .logo-header a.logo img {
    max-width: 144px;
    padding-left: 0;
  }
  .link-info span {
    font-size: 16px;
  }
  /* .xs-text-center {
    text-align: center;
  } */
}

/* Login Screen Css End */
