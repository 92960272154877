body {
  margin: 0;
  padding: 0;
  font-family: 'SFProDisplay-Regular';
}

*,
:after,
:before {
  box-sizing: border-box;
}

a,
button {
  text-decoration: none;
}

@font-face {
  font-family: 'FontsFree-Net-SFProDisplay-Regular';
  src: url('fonts/FontsFree-Net-SFProDisplay-Regular.eot');
  src: url('fonts/FontsFree-Net-SFProDisplay-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('fonts/FontsFree-Net-SFProDisplay-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFProDisplay-Regular';
  src: url('fonts/SFProDisplay-Regular.svg#SFProDisplay-Regular') format('svg'),
    url('fonts/SFProDisplay-Regular.ttf') format('truetype'),
    url('fonts/SFProDisplay-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FontsFree-Net-SFProDisplay-Bold';
  src: url('fonts/FontsFree-Net-SFProDisplay-Bold.eot');
  src: url('fonts/FontsFree-Net-SFProDisplay-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('fonts/FontsFree-Net-SFProDisplay-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFProDisplay-Bold';
  src: url('fonts/SFProDisplay-Bold.svg#SFProDisplay-Bold') format('svg'),
    url('fonts/SFProDisplay-Bold.ttf') format('truetype'),
    url('fonts/SFProDisplay-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

h2 {
  font-size: 44px;
  font-family: 'SFProDisplay-Bold';
  color: #ffffff;
  margin-top: 0;
  margin-bottom: 40px;
}

.field-input {
  margin-bottom: 15px;
  width: 100%;
  max-width: 415px;
  display: inline-block;
}

.field-input:last-child {
  margin-bottom: 0;
}

.custom-input {
  position: relative;
  margin: 0 auto;
  max-width: 415px;
  width: 100%;
}

.custom-input .icon-input {
  position: absolute;
  left: 10px;
  top: 44%;
  transform: translateY(-50%);
}

.custom-input .input-control {
  padding-left: 40px !important;
  max-width: 415px;
  width: 100%;
}
.navbrand-logo .hamburg-icon {
  cursor: pointer;
}

input.input-control:not([type]),
input.input-control[type='text']:not(.browser-default),
input.input-control[type='password']:not(.browser-default),
input.input-control[type='email']:not(.browser-default),
input.input-control[type='url']:not(.browser-default),
input.input-control[type='time']:not(.browser-default),
input.input-control[type='date']:not(.browser-default),
input[type='datetime']:not(.browser-default),
input.input-control[type='datetime-local']:not(.browser-default),
input.input-control[type='tel']:not(.browser-default),
input.input-control[type='number']:not(.browser-default),
input.input-control[type='search']:not(.browser-default),
textarea.materialize-textarea {
  background-color: #eceef0;
  border: 1px solid #adb1b0;
  height: 53px;
  border-radius: 6px;
  padding: 15px;
  font-size: 18px;
  line-height: 24px;
  color: #adb1b0;
  box-sizing: border-box;
}

.input-control {
  background-color: #eceef0;
  border: 1px solid #adb1b0;
  height: 53px;
  border-radius: 6px;
  padding: 15px;
  font-size: 18px;
  line-height: 24px;
  color: #adb1b0;
  box-sizing: border-box;
}

.btn {
  width: 100%;
  max-width: 94%;
  padding: 10px;
  border-radius: 6px;
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 21px;
  border: 1px solid #ffffff;
  text-transform: uppercase;
  cursor: pointer;
  margin: 0 auto;
  display: block;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
  height: auto;
  box-shadow: none;
}

.dropdown-menu {
  width: 100%;
  max-width: 94%;
}

.dropdown-item {
  font-size: 20px;
  padding: 10px;
  text-align: center;
}

.btn-blue {
  background-color: #287fde;
  color: #ffffff;
}

.btn-white {
  background-color: transparent;
  color: #ffffff;
}

.login-body {
  font-family: 'SFProDisplay-Regular';
  position: relative;
  text-align: center;
  display: flex;
  min-height: 100%;
  height: 100vh;
  background: #ffffff;
}

.sign-up-body {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.login-body .login-bg {
  background: linear-gradient(146deg, #7cf5ff 0%, #6bb4e5 27%, #3d009f 100%);
  width: 40%;
  text-align: left;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
}

.welcome {
  background: linear-gradient(146deg, #7cf5ff 0%, #6bb4e5 27%, #3d009f 100%);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  color: white;
  font-size: x-large;
  font-family: 'SFProDisplay-Regular';
}

.login-body .login-bg .login-bg-content {
  max-width: 480px;
  text-align: center;
}

.login-body .login-bg .login-bg-content p {
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  margin-bottom: 80px;
}

.login-body .login-content {
  display: flex;
  padding-top: 120px;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 40px;
  overflow-y: auto;
  position: relative;
}

.navbar-brand-logo {
  margin: 0;
  position: absolute;
  left: 40px;
  top: 40px;
  z-index: 1;
}

.navbar-brand-logo .logo {
  display: inline-block;
}

.navbar-brand-logo .logo img {
  max-width: 207px;
  width: 100%;
}

.login-body .login-content .login-content-inner {
  max-width: 480px;
}

.login-body .login-content .login-content-inner p {
  margin-top: 0;
  margin-bottom: 45px;
  font-size: 18px;
  line-height: 24px;
  color: #282c31;
}

.login-body .login-content .login-content-inner .forgot-password {
  font-size: 18px;
  line-height: 24px;
  color: #282c31;
  margin-top: 45px;
  display: inline-block;
  width: 100%;
  text-decoration: underline;
}

.login-body .login-content .login-content-inner .btn {
  margin-top: 50px;
}

.social-icon-group {
  margin-bottom: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-icon-group a {
  width: 53px;
  height: 54px;
  border-radius: 6px;
  border: 1px solid #adb1b0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 20px;
}

.social-icon-group a img {
  max-width: 27px;
  width: 100%;
}

.login-content-inner h2 {
  color: #287fde;
}

/*========Responsive===========*/

@media screen and (max-width: 991px) {
  h2 {
    font-size: 40px;
    margin-bottom: 30px;
  }
  .navbar-brand-logo .logo img {
    max-width: 180px;
  }
  .social-icon-group {
    margin-bottom: 30px;
  }
  .login-body .login-content .login-content-inner p {
    margin-bottom: 30px;
  }
  .login-body .login-content .login-content-inner .forgot-password {
    margin-top: 30px;
  }
  .login-body .login-content .login-content-inner .btn {
    margin-top: 30px;
  }
  .login-body .login-bg .login-bg-content p {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 767px) {
  h2 {
    font-size: 26px;
    margin-bottom: 25px;
  }
  .input-control {
    height: 47px;
    font-size: 15px;
  }
  .btn {
    max-width: 94%;
    padding: 15px;
    font-size: 17px;
    line-height: 17px;
  }
  .login-body {
    flex-wrap: wrap;
    height: auto;
    min-height: unset;
  }
  .login-bg .login-content {
    width: 100%;
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .login-body .login-bg {
    width: 100%;
  }
  .navbar-brand-logo {
    position: relative;
  }
  .navbar-brand-logo .logo img {
    max-width: 150px;
  }
  .login-body .login-content .login-content-inner {
    max-width: 100%;
  }
  .login-body .login-content .login-content-inner p {
    margin-bottom: 25px;
  }
  .social-icon-group {
    margin-bottom: 25px;
  }
  .social-icon-group a {
    width: 41px;
    height: 42px;
    margin: 0 15px;
  }
  .social-icon-group a img {
    max-width: 21px;
  }
  .login-body .login-content .login-content-inner p {
    font-size: 15px;
  }
  .login-body .login-content .login-content-inner .btn {
    margin-top: 5px;
  }
  .login-body .login-bg .login-bg-content p {
    display: none;
  }
  .login-body .login-bg .login-bg-content {
    max-width: 100%;
  }
  .sign-up-body {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: unset;
    flex-direction: row;
  }
  /* Login Screen Css Start */
  .login-body .login-content {
    margin-top: 60px;
  }
  .login-body .login-content .login-content-inner h2 {
    margin-bottom: 25px;
  }
  .login-body .login-content .login-content-inner form {
    padding: 0px;
    margin-top: 0px;
  }
  /* Login Screen Css End */
}
