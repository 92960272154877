.box_list_item.card-wrap img {
  height: 275px ;
}
#alert-cancel-modal  {
  margin-top: 10rem !important;
  /* margin-left: 23rem !important;  */
}

.invite-modal {
  width: 100%;
  height: 100%;
}

.invite-modal-close {
  position: absolute;
  top: 0;
  right: 0;
  /* padding: 1em; */
  color: black;
  background: none;
  border: 0;
  cursor: pointer;
  padding: 20px 10px 10px 10px !important;
}
#invite-close-icon {
  margin-bottom: 2rem;
  margin-left: 12rem;
}
.react-calendar {
  position: absolute;
  left: 11%;
  top: 5%;
}

.date-time-box .event-date {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-top: 1rem;
}

.date-time-box .event-date .modal-arrow {
  position: relative;
  cursor: pointer;
  /* padding: 10px !important; */
  border: none;
  background: transparent;
  margin: 0px 45px !important;
}

.date-time-box .event-date .modal-arrow img {
  width: 12px !important;
  height: auto !important;
}

.date-time-box .event-date big {
  color: #287fde;
  position: unset !important;
  font-size: 19px;
  white-space: nowrap;
  font-family: 'SFProDisplay-Bold';
  text-decoration: underline;
}

.searchContainer {
  display: flex;
  /* flex: 1 1 300px; */
  position: relative;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  align-items: center;
  margin-bottom: 2rem;
}

.searchIcon {
  padding: 0.5rem;
}

.searchBox {
  border: 0;
  padding: 0.5rem 0.5rem 0.5rem 0;
  flex: 1;
  outline: none;
  border-bottom: none;
}
input[type=search]:not(.browser-default), textarea.materialize-textarea {
  border-bottom: none;
}
input[type=search]:not(.browser-default):focus:not([readonly]), textarea.materialize-textarea:focus:not([readonly]) {
  border-bottom: none !important;
  webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.searchButton {
  background: #538AC5;
  border: 0;
  color: white;
  padding: 0.5rem;
  border-radius: 0;
}
#invite-name {
  margin-left:  0px !important;
}
.invite-alert-modal {
  max-width: 470px !important;
  min-height: 30vh !important;
}
/* #send-invitee-modal {
    z-index:  99999999999999 !important;
} */

.date-picker{
  border-bottom: none !important;
  opacity: 0;
  cursor: pointer;
}

.react-datepicker-ignore-onclickoutside{
  height: 2px !important;
}

.react-datepicker-wrapper{
  position: absolute;
  top: 30%;
}


.imgDiv{
  width: 15%;
  /* float: right; */
  justify-content: end;
  display: flex;
}

.no-found-class{
  font-weight: 500;
  text-align: center;
  width:500px;
  margin: auto;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 15px;
  font-size: 20px;
  color: #2d333e;
  word-spacing: 3px;
  padding: 2%;
  line-height: 2em;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}