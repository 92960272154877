.amenty-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}

.amenty-card {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  background: white;
  margin: 10px;
}

.amenities-info {
  color: black;
  margin-top: 15vh;
}

.amenity-description {
  padding: 15px;
}

.amenity-description__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* medial query enable css >>>>> */
.amenty-image {
  height: 240px;
  width: 100%;
  object-fit: cover;
}

.amenity__arrows {
  width: 35px;
}

@media (min-width: 500px) {
  .amenty-card {
    width: 443px;
    /* ^^ width of card in screens of width greater than 500px, i.e., tabs and desktops. */
  }
}
@media (max-width: 499px) {
  .amenty-image {
    min-width: calc(100vw - 50px);
  }
}
/* <<<< medial query enable css */
