body {
  margin: 0;
  padding: 0;
  font-family: 'SFProDisplay-Regular';
  background-color: #f4f4f4;
}

*,
:after,
:before {
  box-sizing: border-box;
  outline: none !important;
  /* box-shadow: none !important; */
}

a,
button {
  text-decoration: none;
}

@font-face {
  font-family: 'FontsFree-Net-SFProDisplay-Regular';
  src: url('../assets/fonts/FontsFree-Net-SFProDisplay-Regular.eot');
  src: url('../assets/fonts/FontsFree-Net-SFProDisplay-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/FontsFree-Net-SFProDisplay-Regular.woff2')
      format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFProDisplay-Regular';
  src: url('../assets/fonts/SFProDisplay-Regular.svg#SFProDisplay-Regular')
      format('svg'),
    url('../assets/fonts/SFProDisplay-Regular.ttf') format('truetype'),
    url('/fonts/SFProDisplay-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FontsFree-Net-SFProDisplay-Bold';
  src: url('../assets/fonts/FontsFree-Net-SFProDisplay-Bold.eot');
  src: url('../assets/fonts/FontsFree-Net-SFProDisplay-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/FontsFree-Net-SFProDisplay-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFProDisplay-Bold';
  src: url('../assets/fonts/SFProDisplay-Bold.svg#SFProDisplay-Bold')
      format('svg'),
    url('../assets/fonts/SFProDisplay-Bold.ttf') format('truetype'),
    url('../assets/fonts/SFProDisplay-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/*Navbar */
.navbar {
  background-color: #ffffff;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
  position: fixed;
  width: 100%;
  z-index: 99;
}

.navbar-collapse {
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}

.navbar-nav {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  -ms-flex-direction: row;
  flex-direction: row;
}
.navbrand-logo {
  display: flex;
  align-items: center;
  width: 50%;
}
.navbrand-logo .logo img {
  max-width: 144px;
}

.navbrand-logo .logo {
  margin-right: 29px;
}

.navbar-brand {
  display: block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  color: #2d333e;
  font-family: 'SFProDisplay-Bold';
  font-size: 18px;
  font-weight: 500;
  line-height: inherit;
  white-space: nowrap;
  margin-right: auto;
  margin-left: auto;
  width: 25%;
  text-align: center;
}

.navbar-collapse form input[type='text'] {
  width: 43px;
  height: 40px;
  border-radius: 5px;
  border: 2px solid #2d333e;
  background-color: #ffffff;
  box-sizing: border-box;
  font-size: 16px;
  background-image: url('../assets/img/ic-search.svg');
  background-position: 10px 10px;
  background-repeat: no-repeat;
  padding: 12px 12px 12px 27px;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  position: absolute;
  left: 20px;
  top: 20px;
}

.navbar-collapse form input[type='text']:focus {
  width: 272px;
  border-color: #2d333e;
  outline: 0;
}

.right-side-menu {
  display: flex;
  margin-left: auto;
  flex-direction: row;
  width: 50%;
  justify-content: flex-end;
}

.navbar-collapse .right-side-menu input[type='text'] {
  position: relative;
  left: auto;
  top: auto;
  width: 280px;
  background-color: #f4f4f4;
  color: #555b6a;
  border: 0;
  background-image: url('../assets/img/ic-search-new.svg');
  background-position: 12px 12px;
  padding-left: 45px;
}

.navbar-collapse form input[type='text']:focus {
  width: 280px;
}

.navbar-collapse form input[type='text']:focus + .btn-main {
  background-color: #000;
  color: #000;
}

.navbar-collapse form {
  margin-right: 45px;
}

.user-icon {
  width: 43px;
  height: 40px;
  background-color: #2d333e;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.exclamation {
  position: absolute;
  top: -16px;
  right: -6px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dc3537;
  border-radius: 50%;
}

.exclamation .text {
  font-size: 14px;
  color: #ffffff;
}

/**/

h5 {
  font-family: 'SFProDisplay-Regular';
  color: #2d333e;
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 40px;
}

.main-wrap {
  margin-left: 0;
  padding: 20px;
  -moz-animation: bodyslideout 300ms forwards;
  -o-animation: bodyslideout 300ms forwards;
  -webkit-animation: bodyslideout 300ms forwards;
  animation: bodyslideout 300ms forwards;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  position: relative;
  min-height: 100%;
  padding-top: 100px;
}

.event-layout-wrapper {
  display: flex;
  flex-wrap: wrap;

  padding-top: 90px !important;
  /* margin-left: 15px; */
  /* margin-top: 15px; */
  margin-right: 10px !important;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  justify-content: center;
  margin-top: 26px;
}

.open-sidebar .main-wrap {
  margin-left: 280px !important;
  -moz-animation: bodyslidein 300ms forwards;
  -o-animation: bodyslidein 300ms forwards;
  -webkit-animation: bodyslidein 300ms forwards;
  animation: bodyslidein 300ms forwards;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

/* Slide in animation */
@-moz-keyframes slidein {
  0% {
    left: -240px;
  }
  100% {
    left: 0;
  }
}
@-webkit-keyframes slidein {
  0% {
    left: -240px;
  }
  100% {
    left: 0;
  }
}
@keyframes slidein {
  0% {
    left: -240px;
  }
  100% {
    left: 0;
  }
}
@-moz-keyframes slideout {
  0% {
    left: 0;
  }
  100% {
    left: -240px;
  }
}
@-webkit-keyframes slideout {
  0% {
    left: 0;
  }
  100% {
    left: -240px;
  }
}
@keyframes slideout {
  0% {
    left: 0;
  }
  100% {
    left: -240px;
  }
}

@-moz-keyframes bodyslidein {
  0% {
    left: 0;
  }
  100% {
    margin-left: 240px;
  }
}
@-webkit-keyframes bodyslidein {
  0% {
    left: 0;
  }
  100% {
    left: 0;
  }
}
@keyframes bodyslidein {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: 240px;
  }
}
@-moz-keyframes bodyslideout {
  0% {
    margin-left: 240px;
  }
  100% {
    margin-right: 0;
  }
}
@-webkit-keyframes bodyslideout {
  0% {
    margin-left: 240px;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes bodyslideout {
  0% {
    margin-left: 240px;
  }
  100% {
    margin-left: 0;
  }
}

.family-detail {
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding-bottom: 15px;
  width: auto;
  overflow-x: auto;
  overflow-y: hidden;
}

.family-detail a {
  border-radius: 12px;
  background-color: #2d333e;
  color: #ffffff;
  font-size: 16px;
  padding: 9px 27px;
  margin-right: 10px;
}
.family-detail a.disabled {
  background-color: #dddddd;
}

.family-detail .family-detail-inner {
  display: flex;
}

.add-family-detail-button .modal-toggle {
  width: 32px;
  height: 32px;
  background-color: #2d333e;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 0;
  cursor: pointer;
}

.add-family-detail-button .modal-toggle:focus {
  outline: none;
}

.add-family-detail-button .modal-toggle span {
  line-height: 1;
}

.add-family-detail-button .modal-toggle span img {
  width: 18px;
}

.input-form-group {
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
}

.input-form-group .form-input {
  width: 100%;
  padding: 8px 12px;
  box-sizing: border-box;
  font-size: 16px;
  border: 2px solid #dddddd;
  border-radius: 4px;
}

/* Modals Start */

.modal {
  position: absolute;
  z-index: 10000; /* 1 */
  top: 0;
  left: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
}

.modal.is-visible {
  visibility: visible;
}

.modal-overlay {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: hsla(0, 0%, 0%, 0.5);
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.3s, opacity 0.3s;
}

.modal.is-visible .modal-overlay {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
}

.modal-wrapper {
  position: absolute;
  z-index: 9999;
  top: 6em;
  left: 50%;
  width: 32em;
  margin-left: -16em;
  background-color: #fff;
  box-shadow: 0 0 1.5em hsla(0, 0%, 0%, 0.35);
}

.modal-transition {
  transition: all 0.3s 0.12s;
  transform: translateY(-10%);
  opacity: 0;
}

.modal.is-visible .modal-transition {
  transform: translateY(0);
  opacity: 1;
}

.modal-header,
.modal-content {
  padding: 1em;
}

.modal-header {
  position: relative;
  background-color: #fff;
  box-shadow: 0 1px 2px hsla(0, 0%, 0%, 0.06);
  border-bottom: 1px solid #e8e8e8;
}

.modal-close-blue,
.modal-close-blue-event,
.modal-close-blue-event img,
.modal-close-blue img {
  position: absolute;
  height: 35px !important;
  width: 35px !important;
  top: 8px !important;
  right: 10px !important;
  /* padding: 1em; */
  color: #aaa;
  background: none;
  border: 0;
  cursor: pointer;
}

.modal-close-blue:focus,
.modal-close-blue-event:focus {
  outline: none;
  background: none;
}

.modal-share-btn {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 10px 25px 10px 25px;
  color: #fff;
  background-color: #eb3223;
  /* background: none; */
  border: 0;
  border-radius: 5px;
  cursor: pointer;
}

.modal-share-btn:focus {
  outline: none;
  background-color: #eb3223;
}

.modal-heading {
  font-size: 1.125em;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.modal-content > *:first-child {
  margin-top: 0;
}

.modal-content > *:last-child {
  margin-bottom: 0;
}

/*Modal End*/

.home-content-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.home-content-col {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.card-box-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.card-box-col {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  margin-bottom: 20px;
}

.card-box {
  padding: 24px;
  background-color: #ffffff;
  border-radius: 19px;
  border: 1px solid #dddddd;
  height: 100%;
}

.card-box .progressbar-sec {
  margin-bottom: 60px;
}

.graph-content {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
}
.graph-content img {
  max-width: 100%;
}

.graph-content p {
  font-size: 14px;
  color: #2d333e;
  margin-bottom: 0;
  margin-top: 34px;
}
.graph-content p a {
  color: #287fde;
  text-decoration: underline;
}

.graph-content .graph-img {
  width: 100%;
}

.detail-card-box {
  border-radius: 19px;
  border: 1px solid #dddddd;
  background-color: #ffffff;
  padding: 17px 33px 17px 17px;
  margin-bottom: 10px;
}
.card-box-inner {
  display: flex;
  align-items: center;
}

.card-box-icon {
  width: 40px;
  height: 40px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-box-icon img {
  max-width: 40px;
}

.card-box-content {
  width: calc(100% - 40px);
  padding-left: 23px;
}

.card-box-content .title {
  color: #2d333e;
  font-size: 14px;
  margin-bottom: 5px;
  display: inline-block;
  font-family: 'SFProDisplay-Bold';
}

.classes-percentage {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.classes-percentage span {
  color: #2d333e;
  font-size: 14px;
}

.viewmore-btn-outer {
  text-align: right;
  display: inline-block;
  width: 100%;
  margin-top: 5px;
}

.viewmore-btn {
  max-width: 60px;
  font-size: 14px;
  background-color: #2d333e;
  color: #ffffff;
  padding: 10px;
  border-radius: 6px;
  border: 0;
  margin: 5px;
}

.slot_btn_unbooked {
  font-size: 16px;
  background-color: #ffffff;
  color: #287fde;
  padding: 3%;
  border-radius: 6px;
  border: 1;
  border-color: #287fde;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 5px;
  width: 30%;
}

.slot_btn_booked {
  font-size: 16px;
  background-color: #287fde;
  color: #ffffff;
  padding: 3%;
  border-radius: 6px;
  border: 1;
  border-color: #287fde;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 5px;
  width: 30%;
}
.slot_btn_booked:focus {
  background-color: #287fde;
  outline: none;
}

.slot_btn_booked_by_others {
  font-size: 16px;
  background-color: #b9b9b9;
  color: #ffffff;
  padding: 10px 25px;
  border-radius: 6px;
  border: 1;
  border-color: #b9b9b9;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 5px;
}
.slot_btn_booked_by_others:focus {
  background-color: #b9b9b9;
  outline: none;
}

.slot_btn_unbooked:focus {
  background-color: #ffffff;
  outline: none;
}
.card-box-outer-scroll {
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
}

.recommend-box {
  border-radius: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.recommned-content {
  width: calc(100% - 122px);
  padding: 22px 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.recommned-content p {
  margin: 0;
  color: #ffffff;
  font-size: 15px;
  line-height: 22px;
  padding-right: 48px;
}

.btn-main {
  padding: 15px;
  font-size: 18px;
  background-color: #000;
  color: #ffffff;
}

.recommend-img {
  width: 122px;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: cover;
  display: flex;
  height: 90px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.progressbar-sec {
  margin-top: 13px;
}

.progressbar-sec .text {
  font-size: 15px;
  color: #2d333e;
  margin-top: 15px;
  display: inline-block;
  width: 100%;
}

.skillBarContainer {
  width: 100%;
  height: 5px;
  background: #dddddd;
  overflow: hidden;
  border-radius: 5px;
}

.skillBarContainer-md {
  height: 12px;
}

.skillBarValue {
  height: 5px;
  border-radius: 5px;
  background: #ef5180;
}

.skillBarValue-md {
  height: 12px;
}

.color-pink {
  background-color: #ef5180;
}
.color-yellow {
  background-color: #f3a611;
}
.color-red {
  background-color: #dc3537;
}
.color-purple {
  background-color: #976aff;
}
.color-skyblue {
  background-color: #35bfd8;
}
.color-green {
  background-color: #64f6d3;
}

/* Allowed values for the css skill bars */
.value-00 {
  width: 0;
}
.value-1 {
  width: 1%;
}
.value-2 {
  width: 2%;
}
.value-3 {
  width: 3%;
}
.value-4 {
  width: 4%;
}
.value-5 {
  width: 5%;
}
.value-6 {
  width: 6%;
}
.value-7 {
  width: 7%;
}
.value-8 {
  width: 8%;
}
.value-9 {
  width: 9%;
}
.value-10 {
  width: 10%;
}
.value-11 {
  width: 11%;
}
.value-12 {
  width: 12%;
}
.value-13 {
  width: 13%;
}
.value-14 {
  width: 14%;
}
.value-15 {
  width: 15%;
}
.value-16 {
  width: 16%;
}
.value-17 {
  width: 17%;
}
.value-18 {
  width: 18%;
}
.value-19 {
  width: 19%;
}
.value-20 {
  width: 20%;
}
.value-21 {
  width: 21%;
}
.value-22 {
  width: 22%;
}
.value-23 {
  width: 23%;
}
.value-24 {
  width: 24%;
}
.value-25 {
  width: 25%;
}
.value-26 {
  width: 26%;
}
.value-27 {
  width: 27%;
}
.value-28 {
  width: 28%;
}
.value-29 {
  width: 29%;
}
.value-30 {
  width: 30%;
}
.value-31 {
  width: 31%;
}
.value-32 {
  width: 32%;
}
.value-33 {
  width: 33%;
}
.value-34 {
  width: 34%;
}
.value-35 {
  width: 35%;
}
.value-36 {
  width: 36%;
}
.value-37 {
  width: 37%;
}
.value-38 {
  width: 38%;
}
.value-39 {
  width: 39%;
}
.value-40 {
  width: 40%;
}
.value-41 {
  width: 41%;
}
.value-42 {
  width: 42%;
}
.value-43 {
  width: 43%;
}
.value-44 {
  width: 44%;
}
.value-45 {
  width: 45%;
}
.value-46 {
  width: 46%;
}
.value-47 {
  width: 47%;
}
.value-48 {
  width: 48%;
}
.value-49 {
  width: 49%;
}
.value-50 {
  width: 50%;
}
.value-51 {
  width: 51%;
}
.value-52 {
  width: 52%;
}
.value-53 {
  width: 53%;
}
.value-54 {
  width: 54%;
}
.value-55 {
  width: 55%;
}
.value-56 {
  width: 56%;
}
.value-57 {
  width: 57%;
}
.value-58 {
  width: 58%;
}
.value-59 {
  width: 59%;
}
.value-60 {
  width: 60%;
}
.value-61 {
  width: 61%;
}
.value-62 {
  width: 62%;
}
.value-63 {
  width: 63%;
}
.value-64 {
  width: 64%;
}
.value-65 {
  width: 65%;
}
.value-66 {
  width: 66%;
}
.value-67 {
  width: 67%;
}
.value-68 {
  width: 68%;
}
.value-69 {
  width: 69%;
}
.value-70 {
  width: 70%;
}
.value-71 {
  width: 71%;
}
.value-72 {
  width: 72%;
}
.value-73 {
  width: 73%;
}
.value-74 {
  width: 74%;
}
.value-75 {
  width: 75%;
}
.value-76 {
  width: 76%;
}
.value-77 {
  width: 77%;
}
.value-78 {
  width: 78%;
}
.value-79 {
  width: 79%;
}
.value-80 {
  width: 80%;
}
.value-81 {
  width: 81%;
}
.value-82 {
  width: 82%;
}
.value-83 {
  width: 83%;
}
.value-84 {
  width: 84%;
}
.value-85 {
  width: 85%;
}
.value-86 {
  width: 86%;
}
.value-87 {
  width: 87%;
}
.value-88 {
  width: 88%;
}
.value-89 {
  width: 89%;
}
.value-90 {
  width: 90%;
}
.value-91 {
  width: 91%;
}
.value-92 {
  width: 92%;
}
.value-93 {
  width: 93%;
}
.value-94 {
  width: 94%;
}
.value-95 {
  width: 95%;
}
.value-96 {
  width: 96%;
}
.value-97 {
  width: 97%;
}
.value-98 {
  width: 98%;
}
.value-99 {
  width: 99%;
}
.value-100 {
  width: 100%;
}

/*///////////////////////////////////////////////////
    // Animation \\ 
///////////////////////////////////////////////////*/
@-webkit-keyframes slideIn {
  0% {
    width: 0;
  }
  25% {
    width: 100%;
  }
  100% {
    width: normal;
  }
}
@-moz-keyframes slideIn {
  0% {
    width: 0;
  }
  25% {
    width: 100%;
  }
  100% {
    width: normal;
  }
}
@-ms-keyframes slideIn {
  0% {
    width: 0;
  }
  25% {
    width: 100%;
  }
  100% {
    width: normal;
  }
}
@-o-keyframes slideIn {
  0% {
    width: 0;
  }
  25% {
    width: 100%;
  }
  100% {
    width: normal;
  }
}
@keyframes slideIn {
  0% {
    width: 0;
  }
  25% {
    width: 100%;
  }
  100% {
    width: normal;
  }
}
.skillBarValue {
  -webkit-animation: slideIn 2s;
  -moz-animation: slideIn 2s;
  -o-animation: slideIn 2s;
  animation: slideIn 2s;
}

/*=============== Event Page Css Start ===============*/
/* .ReactModal__Content.ReactModal__Content--after-open {
  max-width: 576px;
  width: 100%;
  margin: 0 auto;
  bottom: auto !important;
  top: 0 !important;
  margin: 30px auto;
  border: none !important;
  background: transparent !important;
} */
.reservation-alert-modal .modal-body {
  background-color: white;
}

.share-modal .modal-body {
  background-color: white;
  border-radius: 15px;
  padding-left: 40px;
  padding-right: 30px;
}

.attendees-modal .modal-body {
  background-color: white;
  border-radius: 15px;
  padding-left: 20px;
  padding-right: 30px;
}

.attendees-title {
  background-color: transparent;
  text-align: left;
  margin-top: 25px;
  margin-bottom: 10px;
  margin-right: -5px;
  border-bottom: 1px solid #e5e5e5;

  padding-bottom: 5px;
  text-align: left;
  font-size: 23px;
  /* font-weight: bold; */
  font-family: 'SFProDisplay-Bold';
  letter-spacing: 0px;
  color: #6c6c6c;
  /* opacity: 1; */
}

.ReactModal__Content.ReactModal__Content--after-open .attending {
  background-color: transparent;
  padding: 10px 10px 5px 10px;
  border-radius: 0px 0px 4px 4px;
  color: #6c6c6c;
}
.views_row .box_row .box-bg {
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 30px 30px 30px 30px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.1);
}

.views_row_external .box_row .box-bg {
  margin-bottom: 0px;
  background-color: #fff;
  border-radius: 30px 30px 30px 30px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.1);
}

.views_row .single-event-modal,
.views_row_external .single-event-modal {
  /* max-width: 100% !important; */
  max-width: 536px !important;

  margin: auto;
  /* width: 505px !important; */
}

.event-layout-box {
  width: 370px;
  /* height: 240px; */
  margin-left: 15px;
  margin-right: 5px;
}

/* .event-layout-box:first-child {
  margin-left: 0;
} */

.views_row .box_list_item,
.views_row_external .box_list_item {
  position: relative;
  width: 100%;
}

.views_row .box_list_item img.thumb-img,
.views_row_external .box_list_item img.thumb-img {
  transition: all 0.4s ease-in-out;
  border-radius: 30px 30px 0px 0px;
  width: 100%;
  max-width: 100%;
  height: 240px;
  object-fit: cover;
}

.views_row .box_list_item h5,
.views_row_external .box_list_item h5 {
  display: inline-block;
  width: 80%;
  padding-left: 20px;
  margin-bottom: 0;
  transition: all 0.4s ease-in-out;
}

.box_row .box_list_item .date-time-box-event {
  /* padding: 10px 15px 15px 15px; */
  padding: 10px 10px 1px 15px !important;
}

.views_row .box_row .box_description,
.views_row_external .box_row .box_description {
  padding: 0px 15px 15px 15px;
  display: none;
  color: #000;
  font-size: 14px;
  /* line-height: 20px; */
}
.box_row {
  position: relative;

}
.box_row .d-flex-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.box_row .d-flex-box .date {
  font-size: 14px;
}

.box_row .box_list_item .main-title {
  margin-top: 5px;
  margin-bottom: 15px;
}
.box_row .box_list_item .date-time-box span {
  color: #626366;
  font-size: 14px;
}
.box_row h3 {
  margin: 0px;
  color: #626366;
  font-size: 19px;
  font-weight: 400;
}
.box_row h4 {
  margin: 0px;
  color: #626366;
  font-size: 19px;
  font-weight: 400;

  /* width: 240px; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.box_row p {
  color: #626366;
  font-size: 12px;
  margin-top: 8px;
  margin-bottom: 0px;
}
.box_row pre {
  color: #626366;
  font-size: 12px;
  margin-top: 8px;
  margin-bottom: 0px;
  white-space: pre-wrap;
  font-family: 'SFProDisplay-Regular';
}
.box_row .box_description .col-desc p a {
  font-size: 12px;
  color: #287fde;
}
.box_row .box_list_item .main-title img {
  margin-right: 10px;
}
.box_row .br-btm {
  border-bottom: 1px solid #e5e5e5;
  padding: 12px 0px;
}

.box_row .br-btm-desc {
  border-bottom: 1px solid #e5e5e5;
  padding: 0px 0px 12px 0px;
}

.box_row .br-btm p,
.box_row .br-btm-desc p {
  padding: 0px 0px 0px 0px !important;
}

.box_description .event-time .event-date small {
  display: block;
  color: #b9b9b9;
  font-size: 11px;
}
.box_description .event-time .event-date span {
  display: block;
  color: #626366;
  font-size: 16px;
}

/* .event-instructor { */
/* flex-wrap: wrap !important; */
/* width: 100% !important; */
/* display: flex !important; */
/* justify-content: flex-end !important; */
/* } */
.event-instructor .instructor-bio {
  display: flex;
  align-items: center;
  width: 100%;
}
.event-instructor .instructor-bio img {
  border-radius: 100%;
  object-fit: cover;
  width: 51px;
  height: 51px;
  margin-right: 12px;
}
.event-instructor .instructor-attending {
  display: flex;
  align-items: center;
  /* justify-content: flex-end !important; */
}

.event-instructor .instructor-attending img {
  border-radius: 100%;
  object-fit: cover;
  width: 15px;
  height: 15px;
  margin-right: 12px;
}

.event-instructor .instructor-bio .title h6,
.event-instructor .instructor-bio .title p {
  margin: 0px;
  color: #6c6c6c !important;
  font-size: 16px !important;
  margin-left: 0px !important;

  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: none; */
}

.event-atten .instructor-attending h6,
.event-atten .instructor-attending p {
  margin-bottom: 0;
  color: #6c6c6c !important;
  font-size: 15px !important;
  /* font-size: 50%; */
  /* font-size: calc(100% - width); */

  margin: 0px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.event-atten .instructor-attending img {
  margin-left: 15px;
}

.event-atten a {
  font-size: 14px;
  color: #6c6c6c;
  display: flex;
  margin-bottom: 0px;
  align-items: center;
}
.event-atten a img {
  margin-left: 5px;
}

.more_less-btn {
  /* font-size: 15px !important; */
  color: #287fde !important;
  border: none !important;
  background-color: transparent;
  width: 25%;
  /* padding: 6px 3px; */
}

.rsvp-btn {
  display: flex;
  margin-top: 12px;
  justify-content: center;
  width: 100%;
}
.rsvp-btn a {
  color: #fff;
  font-size: 14px;
  background: #287fde;
  width: 100%;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 10px;
}
.rsvp-btn a:hover {
  text-decoration: none;
  color: #fff;
}
.rsvp-btn .react-add-to-calendar {
  width: 100%;
  /* margin-top: 5px; */
}

.google-link {
  margin-top: 5px;
}

.outlookcom-link {
  margin-top: 5px;
}

.applecom-link {
  margin-top: 5px;
}

.event-image-container {
  position: relative;
  text-align: center;
  color: white;
}
.rsvp-button-drop-down {
  margin-top: 10px;
}
.bottom-left-rsvp-btn {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0px;
  left: 0px;
  color: #fff;
  font-size: 14px;
  background: #eb3223;
  /* width: 40%;
  height: 39px; */
  /* text-transform: uppercase; */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin: 0px 0px 0px 20px;
  padding: 5px 20px 5px 20px;
}

.bottom-left-rsvp-btn svg {
  margin-right: 10px;
}

.Demo__some-network {
  margin-right: 30px !important;
  margin-top: 30px !important;
}

.social-media-icon {
  width: 60px;
  height: 60px;
  border-radius: 10px;
}

.social-media-text1 {
  background-color: transparent;
  text-align: left;
  margin-top: 25px;
  /* margin-bottom: 30px; */
  margin-right: 10px;
  /* margin-left: 20px; */

  text-align: left;
  font-size: 23px;
  /* font-weight: bold; */
  font-family: 'SFProDisplay-Bold';
  letter-spacing: 0px;
  color: #287fde;
  /* opacity: 1; */
}

.social-media-text2 {
  background-color: transparent;
  text-align: left;
  margin-top: 30px;
  margin-right: 10px;
  /* margin-left: 20px; */

  text-align: left;
  font-size: 20px;
  /* font-weight: bold; */
  font-family: 'SFProDisplay-Bold';
  color: #313133;
  /* opacity: 1; */
}

.social-media-textbox {
  height: 40px;
  background-color: transparent;
  text-align: left;
  margin-top: 25px;
  margin-right: 10px;
  margin-bottom: 30px;

  align-items: center;
  display: flex;
  justify-content: space-between;
  /* justify-item; */

  border: 2px solid #287fde;
  border-radius: 5px;
  /* opacity: 1; */
}

.social-media-textbox-text {
  margin-top: 3px;
  margin-left: 10px;

  /* background-color: #35bfd8; */
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-size: 14px;
  /* font-weight: 700; */
  font-family: 'SFProDisplay-Bold';
  letter-spacing: 0px;
  color: #313133;
  /* opacity: 1; */
}

.social-media-copytext {
  margin-top: 3px;
  text-align: right;
  font-size: 14px;
  /* font-weight: bold; */
  font-family: 'SFProDisplay-Bold';

  margin-right: 10px;
  letter-spacing: 0px;
  color: #287fde;
  /* opacity: 1; */
}

.rsvped-btn {
  display: flex;
  margin-top: 12px;
  justify-content: center;
  width: 100%;
}
.rsvped-btn a,
.rsvped-btn button {
  color: #fff;
  font-size: 14px;
  background: #eb3223;
  width: 94%;
  /* text-transform: uppercase; */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 10px;
  border: 0;
}

.rsvped-btn-cancel-all {
  display: flex;
  margin-top: 12px;
  justify-content: center;
  width: 100%;
}
.rsvped-btn-cancel-all a,
.rsvped-btn-cancel-all button {
  color: #fff;
  font-size: 14px;
  background: red;
  width: 274px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 10px;
}

.breadcrumb-row {
  margin: 15px 0px;
}
.breadcrumb-row .rsvp-btn {
  width: auto;
}
.breadcrumb-row .rsvp-btn a {
  width: 274px;
}
.breadcrumb-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1000px;
  margin: 0px auto 20px;
  padding: 0px 15px;
}
.breadcrumb-title a {
  font-size: 22px;
  color: #626366;
  text-transform: uppercase;
  text-decoration: underline;
  position: relative;
}
.breadcrumb-title a.prev-btn img {
  margin-right: 15px;
}
.breadcrumb-title a.next-btn img {
  margin-left: 15px;
}
.breadcrumb-title h2 {
  margin: 0px;
  font-size: 45px;
  color: #626366;
  text-transform: uppercase;
  letter-spacing: 3.3px;
  font-weight: 400;
}

.btn-primary.focus,
.btn-primary:focus {
  box-shadow: none !important;
  outline: none !important;
}

.btn-primary {
  padding-top: 12px !important;
  background-color: #287fde !important;
  border-color: #287fde !important;
  color: #ffffff !important;
  font-size: 14px !important;
  /* font-weight: bold !important; */
  /* font-family: "SFProDisplay-Medium" !important; */
  font: normal normal medium 14px/11px SF Pro Display;
  letter-spacing: 0.6px !important;
  height: 43px !important;
}

/* Confirmed Modal */
.post-confirmation-modal {
  position: relative;
  background-color: #287fde;
  max-width: 565px !important;
  min-height: 68vh;
  width: 90% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.post-confirmation-modal h2 {
  font-size: 40px;
  color: #ffffff;
  font-weight: bold;
}
.post-confirmation-modal .imgbox {
  width: 100%;
  height: 100%;
  max-width: 236px;
  height: 236px;
  margin: 0 auto 20px;
}
.post-confirmation-modal .imgbox img {
  width: 100%;
}

/*=============== Event Page Css End ===============*/

/*========Responsive===========*/
@media screen and (max-width: 1440px) {
  .open-sidebar .views_row .box_list_item img.thumb-img {
    height: 240px;
  }
  .views_row .box_list_item img.thumb-img,
  .views_row_external .box_list_item img.thumb-img {
    height: 240px;
  }
  /* .news-banner {
    width:200;
    height:auto;
   } */
}

@media screen and (max-width: 1440px) {
  .event-atten {
    width: 100%;
  }
  .event-instructor .instructor-attending {
    justify-content: flex-end !important;
  }
  /* .instructor-details {
    display: flex;
    flex-direction: column;
    justify-content: right !important;
  } */
  /* .news-banner {
    width:200;
    height:auto;
   } */
}

@media screen and (max-width: 1200px) {
  /* .instructor-details {
    justify-content: space-between !important;
    display: flex !important;
    flex-direction: initial;
  } */
}

@media screen and (max-width: 1024px) {
  .views_row .box_list_item img.thumb-img {
    height: 240px;
  }
  .open-sidebar .views_row .box_list_item img.thumb-img {
    height: 240px;
  }
  /* .box_row h3 {
    font-size: 14px;
  } */
  /* .box_row h4 {
    font-size: 14px;
  } */
  .publish-btn {
    font-size: 14px;
    padding: 5px 10px;
  }
}

@media only screen and (max-width: 600px) {
  /* .news-banner{
    display: inline-block;
    width: 417px;
    height: auto;
    background-color: rgb(0, 191, 255);
    margin-left: -19px ;
    margin-top: -8px;
   } */
}

@media screen and (max-width: 991px) {
  .home-content-col {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .desc-detail {
    margin-top: 10px;
  }
  .recommned-content p {
    padding-right: 20px;
  }

  .breadcrumb-title {
    max-width: 100% !important;
    padding: 0px 0px;
  }
  .breadcrumb-title a span {
    display: none;
  }
  .breadcrumb-title h2 {
    font-size: 30px !important;
  }
}

@media screen and (max-width: 767px) {
  .card-box-col {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .graph-sec .card-box-col {
    margin-bottom: 10px;
  }
  .graph-sec .card-box-col:last-child {
    margin-bottom: 0;
  }
  .navbar-collapse form input[type='text']:focus {
    width: 230px;
  }
  .recommned-content {
    padding: 10px;
  }
  /* .views_row .box_list_item img.thumb-img {
    height: 100%;
  } */
  .breadcrumb-title h2 {
    font-size: 20px !important;
  }
}

@media screen and (max-width: 600px) {
  .Demo__some-network {
    margin-right: 20px !important;
  }
  .social-media-icon {
    width: 55px;
    height: 55px;
    border-radius: 8px;
  }

  .share-modal .modal-body {
    padding-left: 30px;
    padding-right: 20px;
  }

  .social-media-text1 {
    margin-top: 35px;
    margin-right: 10px;
    font-size: 20px;
  }

  .social-media-text2 {
    margin-top: 30px;
    margin-right: 10px;
    font-size: 18px;
  }
  .social-media-textbox {
    height: 40px;
    text-align: left;
    margin-top: 25px;
    margin-right: 10px;
    margin-bottom: 30px;
    /* opacity: 1; */
    word-wrap: break-word;
  }
  .social-media-textbox-text,
  .social-media-copytext {
    font-size: 12.5px;
  }
}

/* .testing{
  color: #000;
} */
.news-banner {
  position: sticky;
  top: 90px;
  z-index: 1000;
  width: 100% !important;
  height: auto;
  background-color: #287fde;
  /* top: 78px; */
  /* width: 1279px !important; */
  /* margin-left: -19px;
      margin-right: -30px;
      margin-top: -20px; */
  /* ^^ old, ~sahil */
}

.lunch-menu {
  color: #ffffff;
  margin-top: 22px;
  font-size: 20px;
}

.move-center {
  text-align: center;
}
.order-lunch {
  color: #ffffff;
  margin-top: 4px;
}

.banner_image {
  width: auto;
}

.ship {
  text-align: center;
  background-color: #000;
}
.image-change {
  height: 745px;
  width: 18%;
  margin-top: 28px;
  margin-bottom: 16px;
}
.frame_work {
  height: 700px;
}

@media screen and (max-width: 500px) {
  /* .event-layout-box {
    width: 70%;
  } */

  .row {
    display: flex;
    flex-wrap: wrap;
    /* margin-right: 5px; */
    margin-right: -5px;
    flex-direction: column;
  }
  .event-layout-box {
    width: 100%;
    /* max-width: 80%; */
    /* height: 240px; */
    margin-left: 10px;
    margin-right: 10px;
  }
  .event-layout-wrapper {
    display: inherit;
    flex-wrap: initial;
    padding-top: 95px !important;
  }
  /* .news-banner{
    width: 417px !important;
    height: auto;
    background-color: rgb(0, 191, 255);
    margin-left: -19px;
    margin-top: -8px;
   } */
}

.ios-popconfirm {
  position: relative;
  background-color: #a8323280;
}

.ios-popconfirm .rsvp-confirm-model-wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #40404180;
  cursor: default;
  display: flex;
  align-items: center;
  place-content: center;
  border-radius: 18px;
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.cancel-modal {
  background: #fff;
  border-right: 1px #6c6c6c solid !important;
  border-radius: 0 0 0 18px !important;
}

.confirm-modal {
  background: #fff;
}

.button-model{
  width: 30% !important;
  border-right: none !important;
}
.red-button{
  color: red !important;
}
.seprater {
  border-right: solid 1px #6c6c6c !important;
border-radius: 0 !important;
/* border-bottom-left-radius: 18px !important;
border-bottom-right-radius: 18px !important; */
}

.rsvp-confirm-model-wrapper .rsvp-confirm-model {
  background-color: #fff;
  width: 275px;
  max-width: 90%;
  height: 150px;
  border-radius: 18px;
  position: relative;
  padding-top: 24px;
  text-align: center;
}

.rsvp-confirm-model h5 {
  color: #6c6c6c;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0px;
  /* font-family: "SFProDisplay"; */
}

.rsvp-confirm-model p {
  color: #6c6c6c;
  font-size: 14px;
  margin: 0;
  padding: 12px;
}

.rsvp-confirm-model-wrapper .confirm-button-wrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top: 1px #6c6c6c solid;
}

.rsvp-confirm-model-wrapper .confirm-button-wrapper button {
  width: 50%;
  height: 42px;
  border-radius: 18px;
  color: #287fde;
  border: 0;
  font-size: 16px;
}

.rsvp-confirm-model-wrapper .confirm-button-wrapper button:focus {
  background-color: transparent;
}

.rsvp-confirm-card-wrapper {
  background-color: #287fde;
  max-width: 565px !important;
  min-height: 68vh;
  width: 90% !important;
  position: absolute;
  /* height: 64%;
  width: 32%; */
  cursor: default;
  display: flex;
  align-items: center;
  place-content: center;
  border-radius: 18px;
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  /* z-index: 99999; */
}

.confirm-popup-wraper {
  background-color: #313133cf;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  cursor: default;
  display: flex;
  align-items: center;
  place-content: center;
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  z-index: 2222;
}

.rsvp-confirm-card-wrapper .close-icon-card {
  position: absolute;
  top: 28px;
  right: 22px;
  cursor: pointer;
}

.rsvp-confirm-card-wrapper .checksymbol-confirm-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  /* width: 100%;
  height: 100%;
  max-width: 236px;
  height: 236px;
  margin: 0 auto 20px; */
}
.rsvp-confirm-card-wrapper .checksymbol-confirm-card p {
  /* color: white;
  margin-top: 48px;
  font-size: 28px; */

  font-size: 40px;
  color: #ffffff;
  font-weight: bold;
}

.rsvp-confirm-card-wrapper .addcalander-button-wrapper-confirm-card p {
  color: white;
  margin: 0;
  margin-left: 12px;
  font-size: 20px;
}

.rsvp-confirm-card-wrapper .addcalander-button-wrapper-confirm-card {
  display: flex;
  position: absolute;
  width: 80%;
  bottom: 28px;
  height: 50px;
  align-items: center;
  place-content: center;
  cursor: pointer;
  border: 2px solid white;
  border-radius: 5px;
}

.rsvp-chooseCalender-model-wrapper {
  background-color: white;
  max-width: 565px !important;
  min-height: 68vh;
  width: 90% !important;
  position: absolute;
  /* background-color: white;
  position: absolute;
  height: 71%;
  width: 32%; */
  cursor: default;
  display: flex;
  padding: 62px;
  align-items: center;
  place-content: center;
  border-radius: 18px;
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  z-index: 2323;
}

.rsvp-chooseCalender-model-wrapper .close-icon-card {
  position: absolute;
  top: 28px;
  right: 22px;
  cursor: pointer;
}

.rsvp-chooseCalender-model-wrapper .chooseCalender-model {
  text-align: center;
  width: 100%;
  height: 100%;
  padding-top: 28px;
}

.rsvp-chooseCalender-model-wrapper .chooseCalender-model h5 {
  color: #287fde;
  font-size: 38px;
  margin-bottom: 58px;
  font-weight: bold !important;
}
.rsvp-chooseCalender-model-wrapper .chooseCalender-model p {
  /* border-top: 2px #b4cfec solid; */
  color: #287fde;
  font-size: 15px;
  margin: 0;
  padding: 12px;
  cursor: pointer;
  border: 2.5px solid #287fde;
  border-radius: 5px;
  margin-bottom: 25px;
  font-weight: bold !important;
}
.rsvp-chooseCalender-model-wrapper .cancle-button-wrapper {
  position: absolute;
  bottom: 0;
  height: 32px;
  width: 100%;
}

.rsvp-chooseCalender-model-wrapper .cancle-button-wrapper button {
  border: 0;
  background-color: transparent;
  color: #c14f63;
  font-size: 16px;
}
.rsvp-chooseCalender-model-wrapper .cancle-button-wrapper button:focus {
  background-color: transparent;
}

.box_description {
  transition: all 0.5s ease-in-out;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

.test {
  height: 300px;
  width: 300px;
  background: red;
}
