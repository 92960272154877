body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fafafa;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

form {
  padding: 20px;
  margin-top: 30px;
}

form button,
form h5 {
  margin: 20px 0;
}

input[type='text']:not(.browser-default):focus:not([readonly]),
input[type='email']:not(.browser-default):focus:not([readonly]),
input[type='password']:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
  border-color: #ec407a;
  box-shadow: none;
}

input[type='text']:not(.browser-default):focus:not([readonly]) + label,
textarea.materialize-textarea:focus:not([readonly]) + label,
input[type='email']:not(.browser-default):focus:not([readonly]) + label,
input[type='password']:not(.browser-default):focus:not([readonly]) + label {
  color: #ec407a !important;
}

.display_login {
  font-size: 20px;
}

.admin_page {
  height: 100vh;
}

.admin_form {
  padding-top: 0px;
  margin-top: 0px;
  width: 100%;
}

.admin_heading {
  color: #287fde;
  font-size: 55px;
  font-family: 'SFProDisplay-Bold';
}

.admin-page_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  max-width: 500px;
  margin: auto;

  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #6c6c6c5c;
  border-radius: 46px;
  padding: 70px 70px;
}

.prebook-model {
  padding: 4%;
}

.user-name {
  margin-top: 15px;
  font-weight: 500;
  font-size: 18px !important;
  color:black !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  gap:10px
}
.name-line{
  display: inline-block;
  width: 370px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  cursor: pointer;
}
.user-logo {
  width: 30px;
  height: 30px;
  background: black;
  border-radius: 100%;
  display: flex;
  text-align: center;
  color: white;
  justify-content: center;
  align-items: center;
}
.time-duration {
  font-weight: 500;
  margin-bottom: 4px;
  margin-top: 0px !important;
  border-bottom: solid black 1.5px;
  color: black !important;
  font-size: 1;
  font-size: 20px !important;
  padding: 2%;

}

.prebook-model-div {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background: #00000052;
  border-radius: 20px;


  /* background-color: black;
  top: 0;
  left: 0;
  opacity: 0.5; */
  z-index: 9;
}
.header-list {
  padding: 3%;
  font-weight: 600;
  margin-top: -8%;
  position: absolute;
}

.desc-class{
  color: #626366;
  font-size: 12px;
  margin-top: 8px;
  margin-bottom: 0px;
  white-space: pre-wrap;
  font-family: 'SFProDisplay-Regular';
  overflow: hidden;
}

.my-booking-header{
  width: 100%;
    font-size: 26px;
    font-weight: 500;
    margin-left:15px;
    border-bottom: solid 1px;
    /* margin-top: 0px; */
    text-align: center;
    margin-bottom: 40px;

}
.main-wrap {
  height: 100vh;
  position: relative;
}