body {
  margin: 0;
  padding: 0;
  font-family: 'SFProDisplay-Regular';
  background-color: #f4f4f4;
}

*,
:after,
:before {
  box-sizing: border-box;
}

a,
button {
  text-decoration: none;
}

@font-face {
  font-family: 'FontsFree-Net-SFProDisplay-Regular';
  src: url('../assets/fonts/FontsFree-Net-SFProDisplay-Regular.eot');
  src: url('../assets/fonts/FontsFree-Net-SFProDisplay-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/FontsFree-Net-SFProDisplay-Regular.woff2')
      format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFProDisplay-Regular';
  src: url('../assets/fonts/SFProDisplay-Regular.svg#SFProDisplay-Regular')
      format('svg'),
    url('../assets/fonts/SFProDisplay-Regular.ttf') format('truetype'),
    url('/fonts/SFProDisplay-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FontsFree-Net-SFProDisplay-Bold';
  src: url('../assets/fonts/FontsFree-Net-SFProDisplay-Bold.eot');
  src: url('../assets/fonts/FontsFree-Net-SFProDisplay-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/FontsFree-Net-SFProDisplay-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFProDisplay-Bold';
  src: url('../assets/fonts/SFProDisplay-Bold.svg#SFProDisplay-Bold')
      format('svg'),
    url('../assets/fonts/SFProDisplay-Bold.ttf') format('truetype'),
    url('../assets/fonts/SFProDisplay-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/**/

h5 {
  font-family: 'SFProDisplay-Regular';
  color: #2d333e;
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 40px;
}

.explore_main-wrap {
  margin-left: 0;
  padding: 20px;
  -moz-animation: bodyslideout 300ms forwards;
  -o-animation: bodyslideout 300ms forwards;
  -webkit-animation: bodyslideout 300ms forwards;
  animation: bodyslideout 300ms forwards;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  position: relative;
  min-height: 100%;
  padding-top: 100px;
}

.explore-layout-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* padding-top: 80px !important; */
  /* margin-left: 15px; */
  /* margin-top: 15px; */

  /* margin-left: 10px !important;
  margin-right: 10px !important; */
}

.open-sidebar .explore_main-wrap {
  margin-left: 240px !important;
  -moz-animation: bodyslidein 300ms forwards;
  -o-animation: bodyslidein 300ms forwards;
  -webkit-animation: bodyslidein 300ms forwards;
  animation: bodyslidein 300ms forwards;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

/* Slide in animation */
@-moz-keyframes slidein {
  0% {
    left: -240px;
  }
  100% {
    left: 0;
  }
}
@-webkit-keyframes slidein {
  0% {
    left: -240px;
  }
  100% {
    left: 0;
  }
}
@keyframes slidein {
  0% {
    left: -240px;
  }
  100% {
    left: 0;
  }
}
@-moz-keyframes slideout {
  0% {
    left: 0;
  }
  100% {
    left: -240px;
  }
}
@-webkit-keyframes slideout {
  0% {
    left: 0;
  }
  100% {
    left: -240px;
  }
}
@keyframes slideout {
  0% {
    left: 0;
  }
  100% {
    left: -240px;
  }
}

@-moz-keyframes bodyslidein {
  0% {
    left: 0;
  }
  100% {
    margin-left: 240px;
  }
}
@-webkit-keyframes bodyslidein {
  0% {
    left: 0;
  }
  100% {
    left: 0;
  }
}
@keyframes bodyslidein {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: 240px;
  }
}
@-moz-keyframes bodyslideout {
  0% {
    margin-left: 240px;
  }
  100% {
    margin-right: 0;
  }
}
@-webkit-keyframes bodyslideout {
  0% {
    margin-left: 240px;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes bodyslideout {
  0% {
    margin-left: 240px;
  }
  100% {
    margin-left: 0;
  }
}

.player-detail {
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding-bottom: 15px;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.player-detail a {
  border-radius: 12px;
  background-color: #2d333e;
  color: #ffffff;
  font-size: 16px;
  padding: 9px 27px;
  margin-right: 10px;
}
.player-detail a.disabled {
  background-color: #dddddd;
}

.player-detail .player-detail-inner {
  display: flex;
}

.add-player-detail-button .modal-toggle {
  width: 32px;
  height: 32px;
  background-color: #2d333e;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 0;
  cursor: pointer;
  margin-right: 15px;
}

.add-player-detail-button .btn-back {
  width: 32px;
  height: 32px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 0;
  cursor: pointer;
  margin-right: 15px;
}

.add-player-detail-button .modal-toggle-close,
.add-player-detail-button .modal-toggle-close img {
  /* width: 32px;
  height: 32px;
  background-color: gainsboro;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
  border-radius: 50%;
  border-color: #000000;
  border: 0;
  cursor: pointer; */

  width: 32px;
  height: 32px;
  top: 0px !important;
  right: 0px !important;
  padding-left: 0px;
  padding-top: 0px;
  /* padding: 1em; */
  color: #aaa;
  background: none;
  border: 0;
  cursor: pointer;
  object-fit: cover;
}

.modal-toggle-close:focus {
  outline: none;
}

.add-player-detail-button .modal-toggle-search {
  width: 32px;
  height: 32px;
  background-color: gainsboro;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  border-radius: 50%;
  border-color: #000000;
  border: 0;
  cursor: pointer;
}
.modal-toggle-search:focus {
  outline: none;
}

.add-player-detail-button .modal-toggle:focus {
  outline: none;
}

.add-player-detail-button .modal-toggle span {
  line-height: 1;
}

.add-player-detail-button .modal-toggle span img {
  width: 18px;
}

.search-space-div {
  width: 0px;
  height: 55px;
  background-color: #ffffff;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  /* margin-left: 15px; */
}

.input-form {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.input-form-group {
  display: inline-block;
  width: 100%;
}

.input-form-group-desc {
  display: inline-block;
  width: 100%;
  margin-bottom: 15px;
}

.input-form-group .form-input {
  width: 100%;
  padding: 8px 12px;
  /* padding: 8px 20px !important; */
  box-sizing: border-box;
  font-size: 16px;
  /* font-size: 22px !important; */
  border-radius: 4px;
  border: 1px solid #000000 !important;
  color: #6c6c6c;
  height: 64px !important;
}

.input-form-group-desc .form-input {
  width: 100%;
  /* padding: 8px 12px; */
  padding: 8px 20px !important;
  box-sizing: border-box;
  /* font-size: 16px; */
  font-size: 22px !important;
  border-radius: 4px;
  border: 1px solid #000000 !important;
  color: #6c6c6c;
  height: 64px !important;
}

.input-form-group-central-div {
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Drop down */
/* .css-yk16xz-control {
  background-color: #fff;
  border-color: #6c6c6c !important;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
}
.css-1okebmr-indicatorSeparator {
  background-color: #6c6c6c !important;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  width: 1px !important;
  box-sizing: none !important;
  border-width: 10px !important;
}
.css-1hb7zxy-IndicatorsContainer,
.css-1wy0on6 {
  visibility: hidden;
}

.css-1pahdxg-control, 
.css-1pahdxg-control :hover {
  border-color: #6c6c6c !important;
  box-shadow: none !important;
  outline: none !important;
}

.css-b8ldur-Input {
  margin: 2px;
  padding-bottom: 2px;
  padding-top: 2px;
  visibility: visible;
  font-size: 16px !important;
  color: #6c6c6c !important;
  box-sizing: border-box;
}
.css-1uccc91-singleValue {
  color: #6c6c6c !important;
  margin-left: 2px;
  margin-right: 2px;
  font-size: 16px !important;
  max-width: calc(100% - 8px);
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  white-space: nowrap;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  box-sizing: border-box;
}

.css-1hwfws3 {
  color: #6c6c6c;
  margin-left: 0px !important;
  margin-right: 0px !important;
  position: absolute;
  box-sizing: border-box;
  font-size: 16px;
  padding: 2px 22px !important;
  height: 67px;
}

.css-g1d714-ValueContainer {
  padding: 2px 23px !important;
  border: none;
  margin-bottom: 0;

  color: #6c6c6c;
  box-shadow: none;
  background-color: white !important;
  border-bottom: none;
  font-size: 22px !important;
  font-family: "SFProDisplay-Regular";
  font-weight: 300;
  letter-spacing: 0;
  border-radius: 4px;
  width: -webkit-fill-available;
}

.css-1wa3eu0-placeholder {
  color: #6c6c6c;
  margin-left: -15px !important;
  margin-right: 0px !important;
  position: absolute;
  box-sizing: border-box;
  font-size: 16px;
  padding: 15px 15px !important;
  opacity: 0.5;
} */
/* Drop down */

/* Modals Start */

.modal {
  position: absolute;
  z-index: 10000; /* 1 */
  top: 0;
  left: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
}

.modal.is-visible {
  visibility: visible;
}

.modal-overlay {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: hsla(0, 0%, 0%, 0.5);
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.3s, opacity 0.3s;
}

.modal.is-visible .modal-overlay {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
}

.modal-wrapper {
  position: absolute;
  z-index: 9999;
  top: 6em;
  left: 50%;
  width: 32em;
  margin-left: -16em;
  background-color: #fff;
  box-shadow: 0 0 1.5em hsla(0, 0%, 0%, 0.35);
}

.modal-transition {
  transition: all 0.3s 0.12s;
  transform: translateY(-10%);
  opacity: 0;
}

.modal.is-visible .modal-transition {
  transform: translateY(0);
  opacity: 1;
}

.modal-header,
.modal-content {
  padding: 1em;
}

.modal-header {
  position: relative;
  background-color: #fff;
  box-shadow: 0 1px 2px hsla(0, 0%, 0%, 0.06);
  border-bottom: 1px solid #e8e8e8;
}

.modal-close,
.modal-close-blue {
  position: absolute;
  top: -10px !important;
  right: 0px !important;
  padding: 1em;
  color: #aaa;
  background: none;
  border: 0;
  cursor: pointer;
}

.modal-close:focus,
.modal-close-blue:focus {
  outline: none;
}

.modal-heading {
  /* font-size: 1.125em;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */

  background-color: transparent;
  text-align: left;
  margin-top: 25px;
  margin-bottom: 10px;
  margin-right: -5px;
  padding-right: 40px;
  border-bottom: 1px solid #e5e5e5;

  padding-bottom: 5px;
  text-align: left;
  font-size: 23px;
  /* font-weight: bold; */
  font-family: 'SFProDisplay-Bold';
  letter-spacing: 0px;
  color: #6c6c6c !important;
}

.modal-content > *:first-child {
  margin-top: 0;
}

.modal-content > *:last-child {
  margin-bottom: 0;
}

/*Modal End*/

.home-content-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.home-content-col {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.card-box-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.card-box-col {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  margin-bottom: 20px;
}

.card-box {
  padding: 24px;
  background-color: #ffffff;
  border-radius: 19px;
  border: 1px solid #dddddd;
  height: 100%;
}

.card-box .progressbar-sec {
  margin-bottom: 60px;
}

.graph-content {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
}
.graph-content img {
  max-width: 100%;
}

.graph-content p {
  font-size: 14px;
  color: #2d333e;
  margin-bottom: 0;
  margin-top: 34px;
}
.graph-content p a {
  color: #287fde;
  text-decoration: underline;
}

.graph-content .graph-img {
  width: 100%;
}

.detail-card-box {
  border-radius: 19px;
  border: 1px solid #dddddd;
  background-color: #ffffff;
  padding: 17px 33px 17px 17px;
  margin-bottom: 10px;
}
.card-box-inner {
  display: flex;
  align-items: center;
}

.card-box-icon {
  width: 40px;
  height: 40px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-box-icon img {
  max-width: 40px;
}

.card-box-content {
  width: calc(100% - 40px);
  padding-left: 23px;
}

.card-box-content .title {
  color: #2d333e;
  font-size: 14px;
  margin-bottom: 5px;
  display: inline-block;
  font-family: 'SFProDisplay-Bold';
}

.classes-percentage {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.classes-percentage span {
  color: #2d333e;
  font-size: 14px;
}

.searchbar-input-div {
  /* padding-bottom: 5px;
  padding-top: 5px; */
  padding-left: 5px;
  padding-right: 5px;
  width: 100%;
  max-width: 400px;
  /* background-color: gainsboro;
  border-radius: 10px;
  border: 1px;
  border-color: gainsboro; */
}

.searchbar-input {
  position: relative;
  width: 100%;
  max-width: 100%;
  padding-left: 20px;
  margin-left: 20px;
  flex: 1;
  flex-direction: row;
}

/* .searchbar-input:focus {
  border-color: ghostwhite;
  box-shadow: none;
} */

.viewmore-btn-outer {
  text-align: right;
  display: inline-block;
  width: 100%;
  margin-top: 5px;
}

.viewmore-btn {
  font-size: 16px;
  background-color: #2d333e;
  color: #ffffff;
  padding: 10px;
  border-radius: 6px;
  border: 0;
}

.publish-btn {
  font-size: 15px;
  background-color: #287fde;
  color: #ffffff;
  padding: 7px 10.5px;
  border-radius: 6px;
  border: 0;
  /* margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 5px; */
  margin: 1%;
}
.publish-btn:focus {
  background-color: #287fde;
  outline: none;
}
.dlt-btn {
  background-color: rgb(211, 27, 27);
}

.dlt-btn:focus {
  background-color: rgb(211, 27, 27);
  outline: none;
}

.edit-btn {
  font-size: 16px;
  background-color: #287fde;
  color: #ffffff;
  padding: 10px;
  border-radius: 6px;
  border: 0;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 15px;
}

.card-box-outer-scroll {
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
}

.recommend-box {
  border-radius: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.recommned-content {
  width: calc(100% - 122px);
  padding: 22px 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.recommned-content p {
  margin: 0;
  color: #ffffff;
  font-size: 15px;
  line-height: 22px;
  padding-right: 48px;
}

.btn-main {
  padding: 15px;
  font-size: 18px;
  background-color: #000;
  color: #ffffff;
}

.recommend-img {
  width: 122px;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: cover;
  display: flex;
  height: 90px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.progressbar-sec {
  margin-top: 13px;
}

.progressbar-sec .text {
  font-size: 15px;
  color: #2d333e;
  margin-top: 15px;
  display: inline-block;
  width: 100%;
}

.skillBarContainer {
  width: 100%;
  height: 5px;
  background: #dddddd;
  overflow: hidden;
  border-radius: 5px;
}

.skillBarContainer-md {
  height: 12px;
}

.skillBarValue {
  height: 5px;
  border-radius: 5px;
  background: #ef5180;
}

.skillBarValue-md {
  height: 12px;
}

.color-pink {
  background-color: #ef5180;
}
.color-yellow {
  background-color: #f3a611;
}
.color-red {
  background-color: #dc3537;
}
.color-purple {
  background-color: #976aff;
}
.color-skyblue {
  background-color: #35bfd8;
}
.color-green {
  background-color: #64f6d3;
}

/* Allowed values for the css skill bars */
.value-00 {
  width: 0;
}
.value-1 {
  width: 1%;
}
.value-2 {
  width: 2%;
}
.value-3 {
  width: 3%;
}
.value-4 {
  width: 4%;
}
.value-5 {
  width: 5%;
}
.value-6 {
  width: 6%;
}
.value-7 {
  width: 7%;
}
.value-8 {
  width: 8%;
}
.value-9 {
  width: 9%;
}
.value-10 {
  width: 10%;
}
.value-11 {
  width: 11%;
}
.value-12 {
  width: 12%;
}
.value-13 {
  width: 13%;
}
.value-14 {
  width: 14%;
}
.value-15 {
  width: 15%;
}
.value-16 {
  width: 16%;
}
.value-17 {
  width: 17%;
}
.value-18 {
  width: 18%;
}
.value-19 {
  width: 19%;
}
.value-20 {
  width: 20%;
}
.value-21 {
  width: 21%;
}
.value-22 {
  width: 22%;
}
.value-23 {
  width: 23%;
}
.value-24 {
  width: 24%;
}
.value-25 {
  width: 25%;
}
.value-26 {
  width: 26%;
}
.value-27 {
  width: 27%;
}
.value-28 {
  width: 28%;
}
.value-29 {
  width: 29%;
}
.value-30 {
  width: 30%;
}
.value-31 {
  width: 31%;
}
.value-32 {
  width: 32%;
}
.value-33 {
  width: 33%;
}
.value-34 {
  width: 34%;
}
.value-35 {
  width: 35%;
}
.value-36 {
  width: 36%;
}
.value-37 {
  width: 37%;
}
.value-38 {
  width: 38%;
}
.value-39 {
  width: 39%;
}
.value-40 {
  width: 40%;
}
.value-41 {
  width: 41%;
}
.value-42 {
  width: 42%;
}
.value-43 {
  width: 43%;
}
.value-44 {
  width: 44%;
}
.value-45 {
  width: 45%;
}
.value-46 {
  width: 46%;
}
.value-47 {
  width: 47%;
}
.value-48 {
  width: 48%;
}
.value-49 {
  width: 49%;
}
.value-50 {
  width: 50%;
}
.value-51 {
  width: 51%;
}
.value-52 {
  width: 52%;
}
.value-53 {
  width: 53%;
}
.value-54 {
  width: 54%;
}
.value-55 {
  width: 55%;
}
.value-56 {
  width: 56%;
}
.value-57 {
  width: 57%;
}
.value-58 {
  width: 58%;
}
.value-59 {
  width: 59%;
}
.value-60 {
  width: 60%;
}
.value-61 {
  width: 61%;
}
.value-62 {
  width: 62%;
}
.value-63 {
  width: 63%;
}
.value-64 {
  width: 64%;
}
.value-65 {
  width: 65%;
}
.value-66 {
  width: 66%;
}
.value-67 {
  width: 67%;
}
.value-68 {
  width: 68%;
}
.value-69 {
  width: 69%;
}
.value-70 {
  width: 70%;
}
.value-71 {
  width: 71%;
}
.value-72 {
  width: 72%;
}
.value-73 {
  width: 73%;
}
.value-74 {
  width: 74%;
}
.value-75 {
  width: 75%;
}
.value-76 {
  width: 76%;
}
.value-77 {
  width: 77%;
}
.value-78 {
  width: 78%;
}
.value-79 {
  width: 79%;
}
.value-80 {
  width: 80%;
}
.value-81 {
  width: 81%;
}
.value-82 {
  width: 82%;
}
.value-83 {
  width: 83%;
}
.value-84 {
  width: 84%;
}
.value-85 {
  width: 85%;
}
.value-86 {
  width: 86%;
}
.value-87 {
  width: 87%;
}
.value-88 {
  width: 88%;
}
.value-89 {
  width: 89%;
}
.value-90 {
  width: 90%;
}
.value-91 {
  width: 91%;
}
.value-92 {
  width: 92%;
}
.value-93 {
  width: 93%;
}
.value-94 {
  width: 94%;
}
.value-95 {
  width: 95%;
}
.value-96 {
  width: 96%;
}
.value-97 {
  width: 97%;
}
.value-98 {
  width: 98%;
}
.value-99 {
  width: 99%;
}
.value-100 {
  width: 100%;
}

/*///////////////////////////////////////////////////
    // Animation \\ 
///////////////////////////////////////////////////*/
@-webkit-keyframes slideIn {
  0% {
    width: 0;
  }
  25% {
    width: 100%;
  }
  100% {
    width: normal;
  }
}
@-moz-keyframes slideIn {
  0% {
    width: 0;
  }
  25% {
    width: 100%;
  }
  100% {
    width: normal;
  }
}
@-ms-keyframes slideIn {
  0% {
    width: 0;
  }
  25% {
    width: 100%;
  }
  100% {
    width: normal;
  }
}
@-o-keyframes slideIn {
  0% {
    width: 0;
  }
  25% {
    width: 100%;
  }
  100% {
    width: normal;
  }
}
@keyframes slideIn {
  0% {
    width: 0;
  }
  25% {
    width: 100%;
  }
  100% {
    width: normal;
  }
}
.skillBarValue {
  -webkit-animation: slideIn 2s;
  -moz-animation: slideIn 2s;
  -o-animation: slideIn 2s;
  animation: slideIn 2s;
}

/*=============== Event Page Css Start ===============*/
.explore_views_row .explore_box_row .box-bg {
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
  background-color: #fff;
  border-radius: 20px 20px 20px 20px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.1);

  width: 380px !important;
  /* height: 240px !important; */
}
.explore_views_row .explore_box_list_item {
  position: relative;
  width: 100%;
}
.explore_views_row
  .explore_box_list_item
  .explore_box_image
  img.explore_thumb-img {
  transition: all 0.4s ease-in-out;
  border-radius: 20px 20px 0px 0px;
  width: 100%;
  max-width: 100%;
  height: 250px;
  /* height: calc(width/2); */
  object-fit: cover;
}

.explore_views_row .explore_box_list_item .explore_box_image {
  position: relative;
}

.MuiBackdrop-root {
  z-index: 99999 !important;
}
.MuiCircularProgress-indeterminate {
  z-index: 99999;
}

.explore-image-container {
  position: relative;
  text-align: center;
  color: white;
}
.explore_views_row
  .explore_box_list_item
  .explore_box_image
  .explore_play_icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  /* background-color: white; */
}

.explore_views_row
  .explore_box_list_item
  .explore_box_image
  .explore_play_icon
  img {
  max-width: 50px;
}

.explore_views_row .explore_box_list_item h5 {
  display: inline-block;
  width: 80%;
  padding-left: 20px;
  margin-bottom: 0;
  transition: all 0.4s ease-in-out;
}
.explore_box_row .explore_box_list_item .explore_category_text {
  padding: 10px 15px 15px 15px;
}
.explore_views_row .explore_box_row .box_description {
  padding: 15px;
  display: none;
  color: #000;
  font-size: 14px;
  line-height: 20px;
}
.explore_box_row .explore_d-flex-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.explore_box_row .explore_d-flex-box-left {
  display: flex;
  align-items: center;
  justify-content: left;
}

.explore_box_row .explore_box_list_item .explore_main-title {
  margin-top: 5px;
}
.explore_box_row .explore_box_list_item .explore_category_text span {
  color: #626366;
  font-size: 14px;
}

.explore_box_row h4 {
  margin: 0px;
  color: #626366;
  font-size: 22px;
  font-weight: 400;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.explore_box_row h6 {
  margin: 0px;
  margin-left: 0px !important;
  color: #626366;
  font-size: 19px;
  font-weight: 400;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.explore_box_row p {
  color: #626366;
  font-size: 12px;
  margin-top: 8px;
  margin-bottom: 0px;
}
.explore_box_row .box_description .col-desc p a {
  font-size: 12px;
  color: #287fde;
}
.explore_box_row .explore_box_list_item .explore_main-title img {
  margin-right: 10px;
}
.explore_box_row .br-btm {
  border-bottom: 1px solid #e5e5e5;
  padding: 12px 0px;
}
/*=============== Explore Page Css End ===============*/

/* Tab Buttons */
.tab-nav-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 15px 0 25px;
}
.tab-nav-wrap.nav-pills .nav-link {
  border-radius: 7px;
  padding: 5px 10px;
  min-width: 120px;
  text-align: center;
  color: #2d333e;
}
.tab-nav-wrap.nav-pills .nav-link.active {
  color: #ffffff;
  background-color: #287fde;
}

/*========Responsive===========*/
@media screen and (max-width: 1440px) {
  /* .open-sidebar
    .explore_views_row
    .explore_box_list_item
    .explore_box_image
    img.explore_thumb-img {
    height: 150px;
  }
  .explore_views_row
    .explore_box_list_item
    .explore_box_image
    img.explore_thumb-img {
    height: 250px;
  } */
}

@media screen and (max-width: 1024px) {
  /* .explore_views_row
    .explore_box_list_item
    .explore_box_image
    img.explore_thumb-img {
    height: 200px;
  }
  .open-sidebar
    .explore_views_row
    .explore_box_list_item
    .explore_box_image
    img.explore_thumb-img {
    height: 150px;
  } */
  /* .explore_box_row h4 {
    font-size: 14px;
  } */
  /* .publish-btn {
    font-size: 14px;
    padding: 5px 10px;
  } */
}

@media screen and (max-width: 991px) {
  .home-content-col {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .desc-detail {
    margin-top: 10px;
  }
  .recommned-content p {
    padding-right: 20px;
  }
}

@media screen and (max-width: 767px) {
  .card-box-col {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .graph-sec .card-box-col {
    margin-bottom: 10px;
  }
  .graph-sec .card-box-col:last-child {
    margin-bottom: 0;
  }
  .navbar-collapse form input[type='text']:focus {
    width: 230px;
  }
  .recommned-content {
    padding: 10px;
  }
  /* .explore_views_row
    .explore_box_list_item
    .explore_box_image
    img.explore_thumb-img {
    height: 100%;
  } */
}

@media screen and (max-width: 500px) {
  /* .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: 5px;
    flex-direction: column;
  } */
  .explore_views_row .explore_box_row .box-bg {
    width: 100% !important;
    /* max-width: 80%; */
    /* height: 240px; */
    margin-left: 10px;
    margin-right: 10px;
  }
  .explore-layout-wrapper {
    display: inherit;
    flex-wrap: initial;
    /* padding-top: 100px !important; */

    margin-left: 0px !important;
    margin-right: 10px !important;
  }

  /* .tab-nav-wrap {
    font-size: 13.5px;
    margin: 5px 0 20px;
  }
  .tab-nav-wrap.nav-pills .nav-link {
    border-radius: 6px;
    padding: 5px 5px;
    min-width: 100px;
  } */
}
