.blur {
    /* display: none; */
    opacity: 0.3;
}

.myBookingClass {
    margin: 0 !important;
    padding: 0 !important;
    margin-left: 0 !important;
    -webkit-animation: bodyslidein 300ms forwards;
    animation: bodyslidein 300ms forwards;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.myBookingClass>.row {
    margin-top: 20px !important;
}



.myBookingCard {
    /* flex: 1 */
}

@media (max-width: 1050px) {
    .event-title-box {
        justify-content: center !important;
        width: 100%;
    }
}

@media (min-width: 1050px) {
    .event-title-box {
        justify-content: start !important;
        width: 100%;
    }
}

.imgDiv {
    width: 15%;
    display: flex;
    justify-content: flex-end;
}
.no-found-class{
    font-weight: 500;
    text-align: center;

}

.mybooking-layout-wrapper{
    display: flex;
    flex-wrap: wrap;
    padding-top: 80px !important;
    /* margin-left: 15px; */
    /* margin-top: 15px; */
    margin-left: 0px !important;
    margin-right: 10px !important;
}