body {
  margin: 0;
  padding: 0;
  font-family: 'SFProDisplay-Regular';
  background-color: #f4f4f4;
}

*,
:after,
:before {
  box-sizing: border-box;
}

a,
button {
  text-decoration: none;
}

@font-face {
  font-family: 'FontsFree-Net-SFProDisplay-Regular';
  src: url('../assets/fonts/FontsFree-Net-SFProDisplay-Regular.eot');
  src: url('../assets/fonts/FontsFree-Net-SFProDisplay-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/FontsFree-Net-SFProDisplay-Regular.woff2')
      format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFProDisplay-Regular';
  src: url('../assets/fonts/SFProDisplay-Regular.svg#SFProDisplay-Regular')
      format('svg'),
    url('../assets/fonts/SFProDisplay-Regular.ttf') format('truetype'),
    url('/fonts/SFProDisplay-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FontsFree-Net-SFProDisplay-Bold';
  src: url('../assets/fonts/FontsFree-Net-SFProDisplay-Bold.eot');
  src: url('../assets/fonts/FontsFree-Net-SFProDisplay-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/FontsFree-Net-SFProDisplay-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFProDisplay-Bold';
  src: url('../assets/fonts/SFProDisplay-Bold.svg#SFProDisplay-Bold')
      format('svg'),
    url('../assets/fonts/SFProDisplay-Bold.ttf') format('truetype'),
    url('../assets/fonts/SFProDisplay-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/*Navbar */
.navbar {
  background-color: #ffffff;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
  position: fixed;
  width: 100%;
  z-index: 99;
}

.navbar-collapse {
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}

.navbar-nav {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  -ms-flex-direction: row;
  flex-direction: row;
}
.navbrand-logo {
  display: flex;
  align-items: center;
  width: 50%;
}
.navbrand-logo .logo img {
  max-width: 144px;
}

.navbrand-logo .logo {
  margin-right: 29px;
}

.navbar-brand {
  display: block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  color: #2d333e;
  font-family: 'SFProDisplay-Bold';
  font-size: 18px;
  font-weight: 500;
  line-height: inherit;
  white-space: nowrap;
  margin-right: auto;
  margin-left: auto;
  width: 25%;
  text-align: center;
}

.navbar-collapse form input[type='text'] {
  width: 43px;
  height: 40px;
  border-radius: 5px;
  border: 2px solid #2d333e;
  background-color: #ffffff;
  box-sizing: border-box;
  font-size: 16px;
  background-image: url('../assets/img/ic-search.svg');
  background-position: 10px 10px;
  background-repeat: no-repeat;
  padding: 12px 12px 12px 27px;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  position: absolute;
  left: 20px;
  top: 20px;
}

.navbar-collapse form input[type='text']:focus {
  width: 272px;
  border-color: #2d333e;
  outline: 0;
}

.right-side-menu {
  display: flex;
  margin-left: auto;
  flex-direction: row;
  width: 50%;
  justify-content: flex-end;
}

.navbar-collapse .right-side-menu input[type='text'] {
  position: relative;
  left: auto;
  top: auto;
  width: 280px;
  background-color: #f4f4f4;
  color: #555b6a;
  border: 0;
  background-image: url('../assets/img/ic-search-new.svg');
  background-position: 12px 12px;
  padding-left: 45px;
}

.navbar-collapse form input[type='text']:focus {
  width: 280px;
}

.navbar-collapse form input[type='text']:focus + .btn-main {
  background-color: #000;
  color: #000;
}

.navbar-collapse form {
  margin-right: 45px;
}

.user-icon {
  width: 43px;
  height: 40px;
  background-color: #2d333e;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.exclamation {
  position: absolute;
  top: -16px;
  right: -6px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dc3537;
  border-radius: 50%;
}

.exclamation .text {
  font-size: 14px;
  color: #ffffff;
}

/**/

h5 {
  font-family: 'SFProDisplay-Regular';
  color: #2d333e;
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 40px;
}

h6 {
  font-family: 'SFProDisplay-Regular';
  color: #626366;
  font-size: 20px !important;
  margin-top: 5px !important;
  margin-left: 10px !important;
  margin-bottom: 5px !important;
}

h7 {
  font-family: 'SFProDisplay-Regular';
  color: #626366;
  font-size: 19px !important;
  margin-top: 5px !important;
  margin-left: 10px !important;
  margin-bottom: 5px !important;
}

.main-wrap {
  margin-left: 0;
  padding: 20px;
  -moz-animation: bodyslideout 300ms forwards;
  -o-animation: bodyslideout 300ms forwards;
  -webkit-animation: bodyslideout 300ms forwards;
  animation: bodyslideout 300ms forwards;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  position: relative;
  min-height: 100%;
  padding-top: 100px;
}

.location-layout-wrapper {
  display: flex;
  flex-wrap: wrap;

  padding-top: 80px !important;
  /* margin-left: 15px; */
  /* margin-top: 15px; */

  margin-left: 0px !important;
  margin-right: 10px !important;
}

.open-sidebar .main-wrap {
  margin-left: 280px;
  -moz-animation: bodyslidein 300ms forwards;
  -o-animation: bodyslidein 300ms forwards;
  -webkit-animation: bodyslidein 300ms forwards;
  animation: bodyslidein 300ms forwards;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

/* Slide in animation */
@-moz-keyframes slidein {
  0% {
    left: -240px;
  }
  100% {
    left: 0;
  }
}
@-webkit-keyframes slidein {
  0% {
    left: -240px;
  }
  100% {
    left: 0;
  }
}
@keyframes slidein {
  0% {
    left: -240px;
  }
  100% {
    left: 0;
  }
}
@-moz-keyframes slideout {
  0% {
    left: 0;
  }
  100% {
    left: -240px;
  }
}
@-webkit-keyframes slideout {
  0% {
    left: 0;
  }
  100% {
    left: -240px;
  }
}
@keyframes slideout {
  0% {
    left: 0;
  }
  100% {
    left: -240px;
  }
}

@-moz-keyframes bodyslidein {
  0% {
    left: 0;
  }
  100% {
    margin-left: 240px;
  }
}
@-webkit-keyframes bodyslidein {
  0% {
    left: 0;
  }
  100% {
    left: 0;
  }
}
@keyframes bodyslidein {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: 240px;
  }
}
@-moz-keyframes bodyslideout {
  0% {
    margin-left: 240px;
  }
  100% {
    margin-right: 0;
  }
}
@-webkit-keyframes bodyslideout {
  0% {
    margin-left: 240px;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes bodyslideout {
  0% {
    margin-left: 240px;
  }
  100% {
    margin-left: 0;
  }
}

.family-detail {
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding-bottom: 15px;
  width: auto;
  overflow-x: auto;
  overflow-y: hidden;
}

.family-detail a {
  border-radius: 12px;
  background-color: #2d333e;
  color: #ffffff;
  font-size: 16px;
  padding: 9px 27px;
  margin-right: 10px;
}
.family-detail a.disabled {
  background-color: #dddddd;
}

.family-detail .family-detail-inner {
  display: flex;
}

.add-family-detail-button .modal-toggle {
  width: 32px;
  height: 32px;
  background-color: #2d333e;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 0;
  cursor: pointer;
  margin-right: 15px;
}

.add-family-detail-button .modal-toggle:focus {
  outline: none;
}

.add-family-detail-button .modal-toggle span {
  line-height: 1;
}

.add-family-detail-button .modal-toggle span img {
  width: 18px;
}

.input-form-group {
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
}

.input-form-group .form-input {
  width: 100%;
  padding: 8px 12px;
  box-sizing: border-box;
  font-size: 16px;
  border: 2px solid #dddddd;
  border-radius: 4px;
}

/* Modals Start */

.modal {
  position: absolute;
  z-index: 10000; /* 1 */
  top: 0;
  left: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
}

.modal.is-visible {
  visibility: visible;
}

.modal-overlay {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: hsla(0, 0%, 0%, 0.5);
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.3s, opacity 0.3s;
}

.modal.is-visible .modal-overlay {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
}

.modal-wrapper {
  position: absolute;
  z-index: 9999;
  top: 6em;
  left: 50%;
  width: 32em;
  margin-left: -16em;
  background-color: #fff;
  box-shadow: 0 0 1.5em hsla(0, 0%, 0%, 0.35);
}

.modal-transition {
  transition: all 0.3s 0.12s;
  transform: translateY(-10%);
  opacity: 0;
}

.modal.is-visible .modal-transition {
  transform: translateY(0);
  opacity: 1;
}

.modal-header,
.modal-content {
  padding: 1em;
}

.modal-header {
  position: relative;
  background-color: #fff;
  box-shadow: 0 1px 2px hsla(0, 0%, 0%, 0.06);
  border-bottom: 1px solid #e8e8e8;
}

.modal-close {
  position: absolute;
  top: 0;
  right: 0;
  /* padding: 1em; */
  color: #aaa;
  background: none;
  border: 0;
  cursor: pointer;
  padding: 20px 10px 10px 10px !important;
}

.box-bg.rooms-card .modal-close img {
  height: 35px;
  width: 35px;
}

.modal-close img {
  height: 35px;
  width: 35px;
}

.modal-close:focus {
  outline: none;
  background-color: transparent;
}

.booking-details .rooms-card .date-time-box .event-date {
  display: flex;
  justify-content: center;
}

.booking-details .rooms-card .date-time-box .event-date .modal-arrow {
  position: relative;
  cursor: pointer;
  /* padding: 10px !important; */
  border: none;
  background: transparent;
  margin: 0px 45px !important;
}

.booking-details .rooms-card .date-time-box .event-date .modal-arrow img {
  width: 12px !important;
  height: auto !important;
}

.booking-details .rooms-card .date-time-box .event-date big {
  color: #287fde;
  font-size: 20px;
  font-family: 'SFProDisplay-Bold';
  text-decoration: underline;
}

.modal-heading {
  font-size: 1.125em;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.modal-content > *:first-child {
  margin-top: 0;
}

.modal-content > *:last-child {
  margin-bottom: 0;
}

/*Modal End*/

/* Alert Start */

.alert {
  position: absolute;
  z-index: 10000; /* 1 */
  top: 0;
  left: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
}

.alert.is-visible {
  visibility: visible;
}

.alert-overlay {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: hsla(0, 0%, 0%, 0.5);
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.3s, opacity 0.3s;
}

.alert.is-visible .alert-overlay {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
}

.alert-wrapper {
  position: absolute;
  z-index: 9999;
  top: 6em;
  left: 50%;
  width: 32em;
  margin-left: -16em;
  background-color: #fff;
  box-shadow: 0 0 1.5em hsla(0, 0%, 0%, 0.35);
}

.alert-transition {
  transition: all 0.3s 0.12s;
  transform: translateY(-10%);
  opacity: 0;
}

.alert.is-visible .alert-transition {
  transform: translateY(0);
  opacity: 1;
}

.alert-header,
.alert-content {
  padding: 1em;
}

.alert-header {
  position: relative;
  background-color: #fff;
  box-shadow: 0 1px 2px hsla(0, 0%, 0%, 0.06);
  border-bottom: 1px solid #e8e8e8;
}

.alert-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1em;
  color: #aaa;
  background: none;
  border: 0;
  cursor: pointer;
}

.alert-close:focus {
  outline: none;
}

.alert-heading {
  font-size: 1.125em;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.alert-content > *:first-child {
  margin-top: 0;
}

.alert-content > *:last-child {
  margin-bottom: 0;
}

/*Alert End*/

.home-content-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.home-content-col {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.card-box-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.card-box-col {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  margin-bottom: 20px;
}

.card-box {
  padding: 24px;
  background-color: #ffffff;
  border-radius: 19px;
  border: 1px solid #dddddd;
  height: 100%;
}

.card-box .progressbar-sec {
  margin-bottom: 60px;
}

.graph-content {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
}
.graph-content img {
  max-width: 100%;
}

.graph-content p {
  font-size: 14px;
  color: #2d333e;
  margin-bottom: 0;
  margin-top: 34px;
}
.graph-content p a {
  color: #287fde;
  text-decoration: underline;
}

.graph-content .graph-img {
  width: 100%;
}

.detail-card-box {
  border-radius: 19px;
  border: 1px solid #dddddd;
  background-color: #ffffff;
  padding: 17px 33px 17px 17px;
  margin-bottom: 10px;
}
.card-box-inner {
  display: flex;
  align-items: center;
}

.card-box-icon {
  width: 40px;
  height: 40px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-box-icon img {
  max-width: 40px;
}

.card-box-content {
  width: calc(100% - 40px);
  padding-left: 23px;
}

.card-box-content .title {
  color: #2d333e;
  font-size: 14px;
  margin-bottom: 5px;
  display: inline-block;
  font-family: 'SFProDisplay-Bold';
}

.classes-percentage {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.classes-percentage span {
  color: #2d333e;
  font-size: 14px;
}

.viewmore-btn-outer {
  text-align: right;
  display: inline-block;
  width: 100%;
  margin-top: 5px;
}

.viewmore-btn {
  max-width: 60px;
  font-size: 14px;
  background-color: #2d333e;
  color: #ffffff;
  padding: 10px;
  border-radius: 6px;
  border: 0;
  margin: 5px;
}
.more_less-btn {
  /* font-size: 15px !important; */
  color: #287fde !important;
  border: none !important;
  background-color: transparent;
  width: 25%;
  /* padding: 6px 3px; */
}

/****************** TIME SLOTS CSS START ******************/
.time-btn {
  font-size: 16px !important;
  color: #b9b9b9 !important;
  border: none !important;
  height: 37px;
  background-color: transparent;
  width: 25%;
  padding: 5px 3px;
  margin-top: 10px;
  text-align: center;
}

button.slot-available.time-btn:focus {
  /* background-color: #287fde; */
  background-color: transparent;
  /* opacity: 0.7; */
  border-radius: 20px 20px 20px 20px;
  color: #626366 !important;
}

.slot_btn_unbooked,
.slot-available {
  color: #626366 !important;
  font-family: 'SFProDisplay-Bold';
}

.slot_btn_unbooked:focus,
.slot-available:focus {
  outline: none;
}
.slot_btn_booked,
.slot-selected {
  background-color: #287fde;
  color: #ffffff !important;
  font-family: 'SFProDisplay-Bold';
}
.slot_btn_booked:focus,
.slot-selected:focus {
  background-color: #287fde;
  outline: none;
}

.slot_btn_booked_by_others,
.slot-unavailable {
  color: #b9b9b9 !important;
  font-family: 'SFProDisplay-Regular';
}
.slot_btn_booked_by_others:focus,
.slot-unavailable:focus {
  outline: none;
}
.selected-date-start {
  background-color: #287fde;
  border-radius: 7.5px 0 0 7.5px;
  color: #ffffff !important;
  font-family: 'SFProDisplay-Bold';
}
.selected-date-start-prebooking {
  background-color: #287fde;
  opacity: 0.55;
  border-radius: 7.5px 0 0 7.5px;
  color: #ffffff !important;
  font-family: 'SFProDisplay-Bold';
}
.selected-date-end {
  background-color: #287fde;
  border-radius: 0px 7.5px 7.5px 0px;
  color: #ffffff !important;
  font-family: 'SFProDisplay-Bold';
}
.selected-date-end-prebooking {
  background-color: #287fde;
  opacity: 0.55;
  border-radius: 0px 7.5px 7.5px 0px;
  color: #ffffff !important;
  font-family: 'SFProDisplay-Bold';
}

.selected-date-single {
  background-color: #287fde;
  border-radius: 7.5px 7.5px 7.5px 7.5px;
  color: #ffffff !important;
  font-family: 'SFProDisplay-Bold';
}

.selected-date-single-prebooking {
  background-color: #287fde;
  opacity: 0.55;
  border-radius: 7.5px 7.5px 7.5px 7.5px;
  color: #ffffff !important;
  font-family: 'SFProDisplay-Bold';
}

.selected-date-middle {
  background-color: #287fde;
  border-radius: 0 0 0 0;
  color: #ffffff !important;
  font-family: 'SFProDisplay-Bold';
}

.selected-date-middle-prebooking {
  background-color: #287fde;
  opacity: 0.55;
  border-radius: 0 0 0 0;
  color: #ffffff !important;
  font-family: 'SFProDisplay-Bold';
}

.selected-date-single:focus,
.selected-date-single-prebooking:focus,
.selected-date-middle:focus,
.selected-date-middle-prebooking:focus,
.selected-date-start:focus,
.selected-date-start-prebooking:focus,
.selected-date-end:focus,
.selected-date-end-prebooking:focus {
  background-color: #287fde;
  outline: none;
}
.in-selected-time {
  background-color: #287fde;
}
/****************** TIME SLOTS CSS END ******************/

.rooms-card .btn-wrap {
  display: block;
  margin-top: 20px;
  width: 100%;
}

.reservation-alert-modal.booking-details .rooms-card .btn-wrap {
  margin-bottom: 10px;
  outline: none;
}

.rooms-card .btn-wrap .btn {
  font-size: 14px;
  /* padding: 5px 15px; */
  max-width: 274px;
  margin: 0 auto 10px;
  text-transform: capitalize;
}

.card-box-outer-scroll {
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
}

.recommend-box {
  border-radius: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.recommned-content {
  width: calc(100% - 122px);
  padding: 22px 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.recommned-content p {
  margin: 0;
  color: #ffffff;
  font-size: 15px;
  line-height: 22px;
  padding-right: 48px;
}

.btn-main {
  padding: 15px;
  font-size: 18px;
  background-color: #000;
  color: #ffffff;
}

.recommend-img {
  width: 122px;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: cover;
  display: flex;
  height: 90px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.progressbar-sec {
  margin-top: 13px;
}

.progressbar-sec .text {
  font-size: 15px;
  color: #2d333e;
  margin-top: 15px;
  display: inline-block;
  width: 100%;
}

.skillBarContainer {
  width: 100%;
  height: 5px;
  background: #dddddd;
  overflow: hidden;
  border-radius: 5px;
}

.skillBarContainer-md {
  height: 12px;
}

.skillBarValue {
  height: 5px;
  border-radius: 5px;
  background: #ef5180;
}

.skillBarValue-md {
  height: 12px;
}

.color-pink {
  background-color: #ef5180;
}
.color-yellow {
  background-color: #f3a611;
}
.color-red {
  background-color: #dc3537;
}
.color-purple {
  background-color: #976aff;
}
.color-skyblue {
  background-color: #35bfd8;
}
.color-green {
  background-color: #64f6d3;
}

/* Allowed values for the css skill bars */
.value-00 {
  width: 0;
}
.value-1 {
  width: 1%;
}
.value-2 {
  width: 2%;
}
.value-3 {
  width: 3%;
}
.value-4 {
  width: 4%;
}
.value-5 {
  width: 5%;
}
.value-6 {
  width: 6%;
}
.value-7 {
  width: 7%;
}
.value-8 {
  width: 8%;
}
.value-9 {
  width: 9%;
}
.value-10 {
  width: 10%;
}
.value-11 {
  width: 11%;
}
.value-12 {
  width: 12%;
}
.value-13 {
  width: 13%;
}
.value-14 {
  width: 14%;
}
.value-15 {
  width: 15%;
}
.value-16 {
  width: 16%;
}
.value-17 {
  width: 17%;
}
.value-18 {
  width: 18%;
}
.value-19 {
  width: 19%;
}
.value-20 {
  width: 20%;
}
.value-21 {
  width: 21%;
}
.value-22 {
  width: 22%;
}
.value-23 {
  width: 23%;
}
.value-24 {
  width: 24%;
}
.value-25 {
  width: 25%;
}
.value-26 {
  width: 26%;
}
.value-27 {
  width: 27%;
}
.value-28 {
  width: 28%;
}
.value-29 {
  width: 29%;
}
.value-30 {
  width: 30%;
}
.value-31 {
  width: 31%;
}
.value-32 {
  width: 32%;
}
.value-33 {
  width: 33%;
}
.value-34 {
  width: 34%;
}
.value-35 {
  width: 35%;
}
.value-36 {
  width: 36%;
}
.value-37 {
  width: 37%;
}
.value-38 {
  width: 38%;
}
.value-39 {
  width: 39%;
}
.value-40 {
  width: 40%;
}
.value-41 {
  width: 41%;
}
.value-42 {
  width: 42%;
}
.value-43 {
  width: 43%;
}
.value-44 {
  width: 44%;
}
.value-45 {
  width: 45%;
}
.value-46 {
  width: 46%;
}
.value-47 {
  width: 47%;
}
.value-48 {
  width: 48%;
}
.value-49 {
  width: 49%;
}
.value-50 {
  width: 50%;
}
.value-51 {
  width: 51%;
}
.value-52 {
  width: 52%;
}
.value-53 {
  width: 53%;
}
.value-54 {
  width: 54%;
}
.value-55 {
  width: 55%;
}
.value-56 {
  width: 56%;
}
.value-57 {
  width: 57%;
}
.value-58 {
  width: 58%;
}
.value-59 {
  width: 59%;
}
.value-60 {
  width: 60%;
}
.value-61 {
  width: 61%;
}
.value-62 {
  width: 62%;
}
.value-63 {
  width: 63%;
}
.value-64 {
  width: 64%;
}
.value-65 {
  width: 65%;
}
.value-66 {
  width: 66%;
}
.value-67 {
  width: 67%;
}
.value-68 {
  width: 68%;
}
.value-69 {
  width: 69%;
}
.value-70 {
  width: 70%;
}
.value-71 {
  width: 71%;
}
.value-72 {
  width: 72%;
}
.value-73 {
  width: 73%;
}
.value-74 {
  width: 74%;
}
.value-75 {
  width: 75%;
}
.value-76 {
  width: 76%;
}
.value-77 {
  width: 77%;
}
.value-78 {
  width: 78%;
}
.value-79 {
  width: 79%;
}
.value-80 {
  width: 80%;
}
.value-81 {
  width: 81%;
}
.value-82 {
  width: 82%;
}
.value-83 {
  width: 83%;
}
.value-84 {
  width: 84%;
}
.value-85 {
  width: 85%;
}
.value-86 {
  width: 86%;
}
.value-87 {
  width: 87%;
}
.value-88 {
  width: 88%;
}
.value-89 {
  width: 89%;
}
.value-90 {
  width: 90%;
}
.value-91 {
  width: 91%;
}
.value-92 {
  width: 92%;
}
.value-93 {
  width: 93%;
}
.value-94 {
  width: 94%;
}
.value-95 {
  width: 95%;
}
.value-96 {
  width: 96%;
}
.value-97 {
  width: 97%;
}
.value-98 {
  width: 98%;
}
.value-99 {
  width: 99%;
}
.value-100 {
  width: 100%;
}

/*///////////////////////////////////////////////////
    // Animation \\ 
///////////////////////////////////////////////////*/
@-webkit-keyframes slideIn {
  0% {
    width: 0;
  }
  25% {
    width: 100%;
  }
  100% {
    width: normal;
  }
}
@-moz-keyframes slideIn {
  0% {
    width: 0;
  }
  25% {
    width: 100%;
  }
  100% {
    width: normal;
  }
}
@-ms-keyframes slideIn {
  0% {
    width: 0;
  }
  25% {
    width: 100%;
  }
  100% {
    width: normal;
  }
}
@-o-keyframes slideIn {
  0% {
    width: 0;
  }
  25% {
    width: 100%;
  }
  100% {
    width: normal;
  }
}
@keyframes slideIn {
  0% {
    width: 0;
  }
  25% {
    width: 100%;
  }
  100% {
    width: normal;
  }
}
.skillBarValue {
  -webkit-animation: slideIn 2s;
  -moz-animation: slideIn 2s;
  -o-animation: slideIn 2s;
  animation: slideIn 2s;
}

/*=============== Event Page Css Start ===============*/
.views_row .location_box_row .box-bg {
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 20px 20px 20px 20px !important;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.1);

  width: 380px;
  margin-left: 15px;
  margin-right: 5px;
}
.views_row .box_list_item {
  position: relative;
  width: 100%;
}
.views_row .box_list_item img.thumb-img {
  transition: all 0.4s ease-in-out;
  border-radius: 20px 20px 0px 0px !important;
  width: 100%;
  max-width: 100%;
  height: 220px;
  object-fit: cover;
}
.box_list_item.card-wrap img {
  height: 210px;
  width: 100%;
  object-fit: cover;
}

.views_row .box_list_item h5 {
  display: inline-block;
  width: 80%;
  padding-left: 20px;
  margin-bottom: 0;
  transition: all 0.4s ease-in-out;
}
.location_box_row .box_list_item .date-time-box {
  padding: 10px 10px 10px 15px !important;
}
.views_row .location_box_row .rooms-card .box_description {
  padding: 0 15px 20px;
  display: none;
  color: #000;
  font-size: 14px;
  line-height: 20px;
  position: relative;
}
.location_box_row .d-flex-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.location_box_row .d-flex-box-date-picker {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 60px;
}
.box-bg.rooms-card {
  position: relative;
}
.location_box_row .box_list_item.card-wrap .main-title {
  margin-top: 4px;
  margin-bottom: 4px;
  /* flex-direction: column; */
  /* justify-self: start; */
  /* align-items: flex-start; */
  /* height: 25px; */
  /* overflow: hidden;
  transition: all 1s ease-out; */
}
.location_box_row .box_list_item.card-wrap.active .main-title {
  height: auto;
  transition: all 1s ease-out;
}
.location_box_row .box_list_item .date-time-box span {
  color: #626366;
  font-size: 14px;
}
.location_box_row h3 {
  margin: 0px;
  color: #626366;
  font-size: 19px;
  font-weight: 400;
}
.location_box_row h4 {
  margin: 0px;
  color: #626366;
  font-size: 19px;
  font-weight: 400;

  /* width: 250px; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.location_box_row .box_list_item.card-wrap p {
  color: #626366;
  font-size: 11px;
  margin-top: 8px;
  margin-bottom: 0px;
}
.location_box_row .box_description .col-desc p a {
  font-size: 12px;
  color: #287fde;
}
.location_box_row .box_list_item .main-title img {
  margin-right: 10px;
}
.location_box_row .rooms-card .br-btm {
  border-bottom: none;
  padding: 0px 15px 10px 15px;
}
.box_description .event-time .event-date small {
  display: block;
  color: #b9b9b9;
  font-size: 11px;
}
.box_description .event-time .event-date big {
  display: block;
  color: #626366;
  font-family: 'SFProDisplay-Bold';
  font-size: 15px;
}

.box_description
  .event-time
  .event-date
  big
  react-datepicker-wrapper
  react-datepicker__input-container
  react-datepicker-ignore-onclickoutside:focus {
  display: block;
  color: #626366;
  font-family: 'SFProDisplay-Bold';
  font-size: 15px;
  border-bottom: 10px;
}

.react-datepicker-wrapper,
.react-datepicker-wrapper react-datepicker__input-container,
.react-datepicker-wrapper
  react-datepicker__input-container
  input.react-datepicker-ignore-onclickoutside {
  background-color: transparent;
}

input.react-datepicker-ignore-onclickoutside {
  background-color: teal;
}

.box_description .event-time .event-date span {
  display: block;
  color: #626366;
  font-size: 16px;
}
.event-instructor .instructor-bio {
  display: flex;
  align-items: center;
}
.event-instructor .instructor-bio img {
  border-radius: 100%;
  object-fit: cover;
  width: 51px;
  height: 51px;
  margin-right: 12px;
}
.event-instructor .instructor-attending {
  display: flex;
  align-items: center;
}

.event-instructor .instructor-attending img {
  border-radius: 100%;
  object-fit: cover;
  width: 15px;
  height: 15px;
  margin-right: 12px;
}

/* .event-instructor .instructor-bio .title h6 {
  margin: 0px;
  color: #6c6c6c;
  font-size: 14px;
} */
.event-atten {

  width: 100%;
  display: flex;
  flex-wrap: wrap;

}
.event-atten a {
  font-size: 14px;
  color: #6c6c6c;
  display: flex;
  margin-bottom: 0px;
  align-items: center;
}
.event-atten a img {
  margin-left: 5px;
}
.blue-btn {
  display: flex;
  margin-top: 12px;
  justify-content: center;
  width: 100%;
}
.blue-btn a {
  color: #fff;
  font-size: 14px;
  background: #287fde;
  width: 100%;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 10px;
}
.blue-btn a:hover {
  text-decoration: none;
  color: #fff;
}
.blue-btn .react-add-to-calendar {
  width: 100%;
  /* margin-top: 5px; */
}

.calendar-btn {
  display: flex;
  margin-top: 12px;
  justify-content: center;
  width: 100%;
}

/* .react-add-to-calendar__dropdown {
  height: 500px;
} */
.calendar-btn a {
  border: 3px solid #287fde;
  background-color: #ffffff;
  color: #287fde;
  margin-bottom: 2px;
  font-weight: 600;
  width: 90%;
  max-width: inherit;
  /* padding: 22px; */
  /* position: absolute; */
}
.calendar-btn a:hover {
  text-decoration: none;
  color: #fff;
}
.calendar-btn .react-add-to-calendar {
  width: 100%;
  /* margin-top: 5px; */
}

.btnbox .drpdown_closed,
.btnbox .drpdown_open ul li a {
  /* border: 3px solid #287fde; */
  background-color: #287fde;
  color: #ffffff;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 600;
  width: 100%;
  max-width: inherit;
  padding: 22px;
  border-radius: 5px;
  display: flex;
  margin: 15px auto;
  align-items: center;
  justify-content: center;
}

.drpdown .react-add-to-calendar__button,
.drpdown ul li a {
  border: 3px solid #287fde;
  background-color: #ffffff;
  color: #287fde;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 600;
  width: 100%;
  max-width: inherit;
  padding: 22px;
  border-radius: 5px;
  display: flex;
  margin: 15px auto;
  align-items: center;
  justify-content: center;
}

.btnbox .react-add-to-calendar {
  width: 90%;
  position: relative;
}
.applecom-link {
  margin-top: 5px;
}

.google-link {
  margin-top: 5px;
}

.outlookcom-link {
  margin-top: 5px;
}

.event-image-container {
  position: relative;
  text-align: center;
  color: white;
}
.rsvp-button-drop-down {
  margin-top: 10px;
}

.bottom-left-blue-btn {
  display: flex;
  margin-top: 12px;
  justify-content: center;
  position: absolute;
  bottom: 0px;
  left: 0px;
  color: #fff;
  font-size: 14px;
  background: #287fde;
  width: 40%;
  height: 39px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.rsvped-btn {
  display: flex;
  margin-top: 12px;
  justify-content: center;
  width: 100%;
}
.rsvped-btn a {
  color: #fff;
  font-size: 14px;
  background: red;
  width: 100%;
  /* text-transform: uppercase; */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 10px;
}

.rsvped-btn-cancel-all {
  display: flex;
  margin-top: 12px;
  justify-content: center;
  width: 100%;
}
.rsvped-btn-cancel-all a {
  color: #fff;
  font-size: 14px;
  background: red;
  width: 274px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 10px;
}

.breadcrumb-row {
  margin: 15px 0px;
}
.breadcrumb-row .blue-btn {
  width: auto;
}
.breadcrumb-row .blue-btn a {
  width: 274px;
}
.breadcrumb-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1000px;
  margin: 0px auto 20px;
  padding: 0px 15px;
}
.breadcrumb-title a {
  font-size: 22px;
  color: #626366;
  text-transform: uppercase;
  text-decoration: underline;
  position: relative;
}
.breadcrumb-title a.prev-btn img {
  margin-right: 15px;
}
.breadcrumb-title a.next-btn img {
  margin-left: 15px;
}
.breadcrumb-title h2 {
  margin: 0px;
  font-size: 45px;
  color: #626366;
  text-transform: uppercase;
  letter-spacing: 3.3px;
  font-weight: 400;
}
.rooms-card .box_description .calenday-wrap {
  height: auto;
  border-top: 1px solid #ededed;
  /* padding-top: 10px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 10px; */
  padding: 10px 0px 10px 0px;
}
/* .rooms-card .box_description.open-room-attending .calenday-wrap {
  opacity: 0;
  visibility: hidden;
  height: 0;
} */
.rooms-card .box_description.open-room-attending .calenday-wrap {
  opacity: 1;
  visibility: visible;
  height: auto;
}
/* .rooms-card .box_description .room-attending-info {
  opacity: 0;
  visibility: hidden;
  height: 0;
} */
.rooms-card .box_description .room-attending-info {
  opacity: 1;
  visibility: visible;
  height: auto;
}
.rooms-card .box_description.open-room-attending .room-attending-info {
  opacity: 1;
  visibility: visible;
  height: auto;
}
.room-attending-info .info {
  border-top: 1px solid #ededed;
  padding: 13px 15px 15px 15px;
}
.room-attending-info .info:last-child {
  margin-bottom: 30px;
}
.room-attending-info .info .row {
  margin-bottom: 0;
}
.room-attending-info .info label {
  font-size: 14px;
  color: #b9b9b9;
  margin-bottom: 2px;
  margin-left: 10px;
}
.room-attending-info .info h5 {
  font-size: 15px;
  color: #626366;
  margin-bottom: 0;
}

.date-info {
  display: flex;
  justify-content: space-between;
}
.date-info label {
  width: 100%;
}

.add-to-calendar {
  background-color: #fff;
}

.reservation-alert-modal {
  background-color: #ffffff;
  border-radius: 20px;
  overflow: hidden;
  max-width: 254px !important;
  outline: none;
}

.reservation-alert-modal.booking-details {
  background-color: #ffffff;
  border-radius: 20px;
  overflow: hidden;
  /* max-width: 318px !important; */
  width: 450px;
  max-width: 85% !important;
  outline: none;
}

.reservation-alert-modal .modal-body {
  text-align: center;
  outline: none;
}
.reservation-alert-modal .modal-footer {
  padding: 0;
}
.reservation-alert-modal .modal-footer .modal-btnbox {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin: 0;
  outline: none;
}
.reservation-alert-modal .modal-footer .modal-btnbox button {
  margin: 0;
  border-right: 1px solid #dee2e6;
  border-radius: 0;
  color: #287fde;
  font-size: 15px;
  font-weight: 600;
  outline: none;
}
.reservation-alert-modal .modal-footer .modal-btnbox button:hover,
.reservation-alert-modal .modal-footer .modal-btnbox button:focus {
  background-color: transparent;
  outline: none;
}
.reservation-alert-modal .modal-footer .modal-btnbox button:last-child {
  border-right: none;
}

/* .ReactModal__Content.ReactModal__Content--after-open {
  max-width: 576px;
  width: 100%;
  margin: 0 auto;
  bottom: auto !important;
  top: 0 !important;
  margin: 30px auto;
  border: none !important;
  background-color: white !important;
}
.reservation-alert-modal .modal-body {
  background-color: white !important;
} */

.MuiBackdrop-root {
  z-index: 99999 !important;
}
.MuiCircularProgress-indeterminate {
  z-index: 99999;
}
/*=============== Room Page Css End ===============*/

/* Common Css */
button:focus,
.btn:focus,
.btn-primary.focus,
.btn-primary:focus {
  box-shadow: none !important;
  outline: none !important;
}
.btn {
  font-size: 14px;
  padding: 8px 15px;
}
.btn-primary {
  background-color: #287fde !important;
  border-color: #287fde !important;
  color: #ffffff !important;
  font-size: 14px !important;
  font-weight: bold !important;
  height: 36px !important;
  margin-bottom: 20px !important;
}
.btn-theme {
  background-color: #eb3223 !important;
  border-color: #eb3223 !important;
  color: #ffffff !important;
  margin-bottom: 25px !important;
  font-size: 14px !important;
  font-weight: bold !important;
  height: 43px !important;
}

/* Confirmed Modal */
.post-confirmation-modal {
  position: relative;
  background-color: #287fde;
  max-width: 565px !important;
  min-height: 68vh;
  width: 90% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.post-confirmation-modal h2 {
  font-size: 35px !important;
  color: #ffffff;
  font-weight: bold;
  margin-top: 0px !important;
}
.post-confirmation-modal .imgbox {
  width: 180px !important;
  height: 180px;
  max-width: 180px;
  margin: 0 auto 10px !important;
}
.post-confirmation-modal .imgbox img {
  width: 180px;
}

.add-to-calendar-modal {
  max-width: 665px !important;
  width: 90% !important;
  position: relative;
  text-align: center;
  padding: 100px 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.add-to-calendar-modal h2 {
  color: #287fde;
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 100px;
}
.add-to-calendar-modal .btnbox {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 596px;
  width: 90%;
}
.add-to-calendar-modal .btnbox .btn {
  border: 3px solid #287fde;
  background-color: #ffffff;
  color: #287fde;
  margin-bottom: 20px;
  font-weight: 600;
  width: 90%;
  max-width: inherit;
  padding: 22px;
}
.add-to-calendar-modal .btnbox .btn:last-child {
  margin-bottom: 0;
}

/*========Responsive===========*/
@media screen and (max-width: 1680px) {
  .room-attending-info .info h5 {
    font-size: 14px;
  }
}
@media screen and (max-width: 1440px) {
  .open-sidebar .views_row .box_list_item img.thumb-img {
    height: 150px;
  }
  .views_row .box_list_item img.thumb-img {
    height: 220px;
  }
}
@media screen and (max-width: 1366px) {
  .room-attending-info .info h5 {
    font-size: 13px;
  }
}

@media screen and (max-width: 1024px) {
  .views_row .box_list_item img.thumb-img {
    height: 200px;
  }
  .open-sidebar .views_row .box_list_item img.thumb-img {
    height: 150px;
  }
  .location_box_row h3 {
    font-size: 14px;
  }
  .location_box_row h4 {
    font-size: 14px;
  }
  .publish-btn {
    font-size: 14px;
    padding: 5px 10px;
  }
}

@media screen and (max-width: 991px) {
  .home-content-col {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .desc-detail {
    margin-top: 10px;
  }
  .recommned-content p {
    padding-right: 20px;
  }

  .breadcrumb-title {
    max-width: 100% !important;
    padding: 0px 0px;
  }
  .breadcrumb-title a span {
    display: none;
  }
  .breadcrumb-title h2 {
    font-size: 30px !important;
  }
}

@media screen and (max-width: 767px) {
  .card-box-col {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .graph-sec .card-box-col {
    margin-bottom: 10px;
  }
  .graph-sec .card-box-col:last-child {
    margin-bottom: 0;
  }
  .navbar-collapse form input[type='text']:focus {
    width: 230px;
  }
  .recommned-content {
    padding: 10px;
  }
  .views_row .box_list_item img.thumb-img {
    height: 100%;
  }
  .breadcrumb-title h2 {
    font-size: 20px !important;
  }
  .time-btn {
    font-size: 15px !important;
    width: 25%;
    padding: 4px 2px;
    /* margin-top: 5px; */
  }
}

@media screen and (max-width: 500px) {
  /* .event-layout-box {
    width: 70%;
  } */

  /* .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: 5px;
    flex-direction: column;
  } */
  .views_row .location_box_row .box-bg {
    width: 100%;
  }
  .location-layout-wrapper {
    display: inherit;
    flex-wrap: initial;
    padding-top: 95px !important;
    /* margin-right: 10px !important;  */

    margin-left: 0px !important;
    margin-right: 15px !important;
  }
}
