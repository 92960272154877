body {
  margin: 0;
  padding: 0;
  font-family: 'SFProDisplay-Regular';
  background-color: #f4f4f4;
}

*,
:after,
:before {
  box-sizing: border-box;
}

a,
button {
  text-decoration: none;
}

@font-face {
  font-family: 'FontsFree-Net-SFProDisplay-Regular';
  src: url('/fonts/FontsFree-Net-SFProDisplay-Regular.eot');
  src: url('/fonts/FontsFree-Net-SFProDisplay-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('/fonts/FontsFree-Net-SFProDisplay-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFProDisplay-Regular';
  src: url('/fonts/SFProDisplay-Regular.svg#SFProDisplay-Regular') format('svg'),
    url('/fonts/SFProDisplay-Regular.ttf') format('truetype'),
    url('/fonts/SFProDisplay-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FontsFree-Net-SFProDisplay-Bold';
  src: url('/fonts/FontsFree-Net-SFProDisplay-Bold.eot');
  src: url('/fonts/FontsFree-Net-SFProDisplay-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('/fonts/FontsFree-Net-SFProDisplay-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFProDisplay-Bold';
  src: url('/fonts/SFProDisplay-Bold.svg#SFProDisplay-Bold') format('svg'),
    url('/fonts/SFProDisplay-Bold.ttf') format('truetype'),
    url('/fonts/SFProDisplay-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/*Navbar */
.navbar.navbar-home {
  background-color: #ffffff;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
  position: fixed;
  width: 100%;
  z-index: 99;
  height: auto;
  line-height: 1;
}

.navbar-collapse {
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}

.navbar-nav {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  -ms-flex-direction: row;
  flex-direction: row;
}
.navbrand-logo {
  display: flex;
  align-items: center;
  width: 50%;
}
.navbrand-logo .logo img {
  max-width: 144px;
}

.navbrand-logo .logo {
  margin-right: 29px !important;
}

.navbar-brand {
  display: block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  color: #2d333e;
  font-family: 'SFProDisplay-Bold';
  font-size: 18px;
  font-weight: 500;
  line-height: inherit;
  white-space: nowrap;
  margin-right: auto;
  margin-left: auto;
  width: 25%;
  text-align: center;
}

.navbar-collapse form input[type='text'] {
  width: 43px;
  height: 40px;
  border-radius: 5px;
  border: 2px solid #2d333e;
  background-color: #ffffff;
  box-sizing: border-box;
  font-size: 16px;
  background-image: url('../img/ic-search.svg');
  background-position: 10px 10px;
  background-repeat: no-repeat;
  padding: 12px 12px 12px 27px;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  position: absolute;
  left: 20px;
  top: 20px;
}

.navbar-collapse form input[type='text']:focus {
  width: 272px;
  border-color: #2d333e;
  outline: 0;
}

.right-side-menu {
  display: flex;
  margin-left: auto;
  flex-direction: row;
  width: 50%;
  justify-content: flex-end;
  align-items: center;
}

.navbar-collapse .right-side-menu input[type='text'] {
  position: relative;
  left: auto;
  top: auto;
  width: 280px;
  background-color: #f4f4f4;
  color: #555b6a;
  border: 0;
  background-image: url('../img/ic-search-new.svg');
  background-position: 12px 12px;
  padding-left: 45px;
}

.navbar-collapse form input[type='text']:focus {
  width: 280px;
}

.navbar-collapse form {
  margin: 0;
  padding: 0;
  margin-right: 45px;
}

/* .user-icon {
      width: 43px;
      height: 40px;
      background-color: #2d333e;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
  } */

.profile-pic {
  width: 100%;
  /* width: 40px; */
  /* height: 40px; */
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

a.username:hover {
  color: white;
  text-decoration: none;
}

.profile-pic-characters {
  background-color: greenyellow;
  border-radius: 100%;
  height: 50px;
  aspect-ratio: 1;

  /* centering its content */
  display: flex;
  aspect-ratio: 1;
  align-items: center;
  justify-content: center;
}
.profile-pic-characters--characters {
  font-size: 1.6em;
}

.user-icon > input {
  display: none;
}

.exclamation {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dc3537;
  border-radius: 50%;
}

.exclamation .text {
  font-size: 14px;
  color: #ffffff;
}

/**/

h5 {
  font-family: 'SFProDisplay-Regular';
  color: #2d333e;
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 40px;
}

.sidebar-menu {
  width: 280px;
  background-color: #ffffff;
  position: fixed;
  left: 0;
  top: 90px;
  right: auto;
  min-height: 100%;
  overflow-y: auto;
  white-space: nowrap;
  height: 100%;
  z-index: 1;
  -moz-animation: slideout 300ms forwards;
  -o-animation: slideout 300ms forwards;
  -webkit-animation: slideout 300ms forwards;
  animation: slideout 300ms forwards;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.sidebar-menu ul {
  padding-left: 0;
  margin: 0;
}
.sidebar-menu ul li {
  list-style: none;
}

.sidebar-menu ul li a {
  padding: 25px 29px;
  display: inline-block;
  text-transform: uppercase;
  cursor: pointer !important; 
  width: 100%;
}

.sidebar-menu ul li a:hover {
  background-color: #accdf3b0;
  /* color: #ffffff; */
  text-decoration: none;
}

.sidebar-menu ul li a.active {
  background-color: #287fde;
  color: #ffffff;
  text-decoration: none;
}

.sidebar-menu ul li a:active {
  outline: none;
}
.sidebar-menu ul li a.active :focus {
  outline: none;
}

.sidebar-menu ul li a .icon {
  margin-right: 29px;
}

.sidebar-menu ul li a .text {
  color: #2d333e;
  font-size: 15px;
  /* font-family: "Poppins-Regular"; */
  letter-spacing: 1px;
  line-height: 20px;
}

/* .sidebar-menu ul li a:hover .icon,
.sidebar-menu ul li a.active .icon {
   filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(316deg)
    brightness(101%) contrast(103%); 
}  */

/* .sidebar-menu ul li a:hover .text {
  color: #ffffff;
} */

.sidebar-menu ul li a.active .text {
  color: #ffffff;
}

.open-sidebar .sidebar-menu {
  -moz-animation: slidein 300ms forwards;
  -o-animation: slidein 300ms forwards;
  -webkit-animation: slidein 300ms forwards;
  animation: slidein 300ms forwards;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.main-wrap {
  margin-left: 0;
  padding: 20px;
  -moz-animation: bodyslideout 300ms forwards;
  -o-animation: bodyslideout 300ms forwards;
  -webkit-animation: bodyslideout 300ms forwards;
  animation: bodyslideout 300ms forwards;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  position: relative;
  min-height: 100%;
  padding-top: 100px;
}

.open-sidebar .main-wrap {
  margin-left: 240px;
  -moz-animation: bodyslidein 300ms forwards;
  -o-animation: bodyslidein 300ms forwards;
  -webkit-animation: bodyslidein 300ms forwards;
  animation: bodyslidein 300ms forwards;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

/* Slide in animation */
@-moz-keyframes slidein {
  0% {
    left: -240px;
  }
  100% {
    left: 0;
  }
}
@-webkit-keyframes slidein {
  0% {
    left: -240px;
  }
  100% {
    left: 0;
  }
}
@keyframes slidein {
  0% {
    left: -200px;
  }
  100% {
    left: 0;
  }
}
@-moz-keyframes slideout {
  0% {
    left: 0;
  }
  100% {
    left: -240px;
  }
}
@-webkit-keyframes slideout {
  0% {
    left: 0;
  }
  100% {
    left: -240px;
  }
}
@keyframes slideout {
  0% {
    left: 0;
  }
  100% {
    left: -240px;
  }
}

@-moz-keyframes bodyslidein {
  0% {
    left: 0;
  }
  100% {
    margin-left: 240px;
  }
}
@-webkit-keyframes bodyslidein {
  0% {
    left: 0;
  }
  100% {
    left: 0;
  }
}
@keyframes bodyslidein {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: 240px;
  }
}
@-moz-keyframes bodyslideout {
  0% {
    margin-left: 240px;
  }
  100% {
    margin-right: 0;
  }
}
@-webkit-keyframes bodyslideout {
  0% {
    margin-left: 240px;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes bodyslideout {
  0% {
    margin-left: 240px;
  }
  100% {
    margin-left: 0;
  }
}

/* .family-detail {
    display: flex;
    align-items: center;
    white-space: nowrap;
   padding-bottom: 15px;
    width: auto;
    overflow-x: auto;
    overflow-y: hidden;
}

.family-detail a {
    border-radius: 12px;
    background-color: #2d333e;
    color: #ffffff;
    font-size: 16px;
    padding: 9px 27px;
    margin-right: 10px;    
}
.family-detail a.disabled {
    background-color: #dddddd;
} */

.family-detail {
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding-bottom: 15px;
  width: auto;
  overflow-x: auto;
  overflow-y: hidden;
}

.family-detail a {
  border-radius: 12px;
  background-color: #2d333e;
  color: #ffffff;
  font-size: 16px;
  padding: 9px 27px;
  margin-right: 10px;
}
.family-detail a.disabled {
  background-color: #dddddd;
}

.family-detail .family-detail-inner {
  display: flex;
}

.add-family-detail-button .modal-toggle {
  width: 32px;
  height: 32px;
  background-color: #2d333e;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 0;
  cursor: pointer;
}

.add-family-detail-button .modal-toggle:focus {
  outline: none;
}

.add-family-detail-button .modal-toggle span {
  line-height: 1;
}

.add-family-detail-button .modal-toggle span img {
  width: 18px;
}

/*Modal Start*/
.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.75) !important;
  overflow-x: hidden;
  overflow-y: scroll;
}

.ReactModal__Content.ReactModal__Content--after-open {
  max-width: 576px;
  width: 100%;
  margin: 0 auto;
  bottom: auto !important;
  top: 0 !important;
  margin: 30px auto;
}

.ReactModal__Content.ReactModal__Content--after-open .modal-header {
  position: relative;
  background-color: #fff;
  box-shadow: 0 1px 2px hsla(0, 0%, 0%, 0.06);
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 16px;
}

.ReactModal__Content.ReactModal__Content--after-open
  .modal-header
  .modal-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  color: #aaa;
  background: none;
  border: 0;
  cursor: pointer;
}

.ReactModal__Content.ReactModal__Content--after-open .modal-heading {
  font-size: 1.125em;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000000;
}

.input-form-group {
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
}

.input-form-group .form-input,
input:not([type]).form-input,
input[type='text']:not(.browser-default).form-input,
input[type='password']:not(.browser-default).form-input,
input[type='email']:not(.browser-default).form-input,
input[type='url']:not(.browser-default).form-input,
input[type='time']:not(.browser-default).form-input,
input[type='date']:not(.browser-default).form-input,
input[type='datetime']:not(.browser-default).form-input,
input[type='datetime-local']:not(.browser-default).form-input,
input[type='tel']:not(.browser-default).form-input,
input[type='number']:not(.browser-default).form-input,
input[type='search']:not(.browser-default).form-input,
textarea.materialize-textarea.form-input {
  width: 100%;
  padding: 8px 12px;
  box-sizing: border-box;
  font-size: 16px;
  border: 2px solid #dddddd;
  border-radius: 4px;
}

.ReactModalPortal form {
  margin: 0;
  padding: 0;
  padding-top: 20px;
  font-family: 'SFProDisplay-Regular';
}

.ReactModalPortal form .viewmore-btn {
  margin: 0;
}

.viewmore-btn-outer {
  text-align: right;
  display: inline-block;
  width: 100%;
  margin-top: 5px;
}

.viewmore-btn {
  font-size: 16px;
  background-color: #2d333e;
  color: #ffffff;
  padding: 10px;
  border-radius: 6px;
  border: 0;
}

/*Modal End*/

.home-content-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.home-content-col {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.card-box-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.card-box-col {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  margin-bottom: 20px;
}

.card-box {
  padding: 24px;
  background-color: #ffffff;
  border-radius: 19px;
  border: 1px solid #dddddd;
  height: 100%;
}

.card-box .progressbar-sec {
  margin-bottom: 60px;
}

.graph-content {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
}
.graph-content img {
  max-width: 100%;
}

.graph-content p {
  font-size: 14px;
  color: #2d333e;
  margin-bottom: 0;
  margin-top: 34px;
}
.graph-content p a {
  color: #287fde;
  text-decoration: underline;
}

.graph-content .graph-img {
  width: 100%;
  max-width: 100%;
  display: inline-block;
}

.graph-content .graph-chart {
  width: 100%;
  max-width: 120%;
  display: inline-block;
  margin-top: -50px;
}

.detail-card-box {
  border-radius: 19px;
  border: 1px solid #dddddd;
  background-color: #ffffff;
  padding: 17px 33px 17px 17px;
  margin-bottom: 10px;
}

.detail-card-modal {
  margin-top: 30px;
  /* display: inline-block; */
}

.detail-card-modal .detail-card-box {
  padding: 17px;
}

.detail-card-modal .card-box-content {
  padding-left: 0px;
  width: 100%;
}

.detail-card-modal .card-box-inner {
  margin-bottom: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e8e8e8;
}

.card-box-inner {
  display: flex;
  align-items: center;
}

.card-box-icon {
  width: 40px;
  height: 40px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-box-icon-goal {
  overflow: hidden;
  padding-right: 40px;
  display: flex;
  align-items: center;
}

.card-box-icon-goal h2 {
  padding-left: 20px;
}

.card-box-icon img {
  max-width: 40px;
}

.card-box-content {
  width: calc(100% - 40px);
  padding-left: 23px;
}

.card-box-content .title {
  color: #2d333e;
  font-size: 14px;
  margin-bottom: 5px;
  display: inline-block;
  font-family: 'SFProDisplay-Bold';
}

.classes-percentage {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.classes-percentage span {
  color: #2d333e;
  font-size: 14px;
}

.recommend-box {
  border-radius: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.recommned-content {
  width: 100%;
  padding: 22px 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.recommned-content p {
  width: 90%;
  margin: 0;
  color: #ffffff;
  font-size: 15px;
  line-height: 22px;
  padding-right: 20px;
}

.recommned-content p.subtitle {
  width: 90%;
  margin: 0;
  color: #ffffff;
  font-size: 12px;
  line-height: 22px;
  padding-right: 20px;
}

.recommend-img {
  width: 100%;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: cover;
  display: flex;
  height: 170px;
  border-radius: 6px 6px 0 0;
}
.progressbar-sec {
  margin-top: 13px;
}

.progressbar-sec .text {
  font-size: 15px;
  color: #2d333e;
  margin-top: 15px;
  display: inline-block;
  width: 100%;
}

.skillBarContainer {
  width: 100%;
  height: 5px;
  background: #dddddd;
  overflow: hidden;
  border-radius: 5px;
}

.skillBarContainer-md {
  height: 12px;
}

.skillBarValue {
  height: 5px;
  border-radius: 5px;
  background: #ef5180;
}

.skillBarValue-md {
  height: 12px;
}

.color-pink {
  background-color: #ef5180;
}
.color-yellow {
  background-color: #f3a611;
}
.color-red {
  background-color: #dc3537;
}
.color-purple {
  background-color: #976aff;
}
.color-skyblue {
  background-color: #35bfd8;
}
.color-green {
  background-color: #64f6d3;
}

.username-dropdown {
  position: relative;
}
.username-dropdown .username {
  width: 50px;
  height: 50px;
  background-color: #2d333e;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition-duration: 0.5s;
}

.dropdown-menu-header-bar {
  visibility: visible;
  opacity: 1;
  /* min-width: 10rem; */
  min-width: 20rem;
  /* ^^ ~sahil#fix */
  position: absolute;
  transition: all 0.5s ease;
  margin-top: 5px;
  right: 0;
  display: block;
  background-color: #768e9c;
  top: 100%;
}

.dropdown-menu-header-bar:after {
  content: '';
  position: absolute;
  top: -5px;
  right: 20px;
  display: block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #768e9c;
}

/* .username:hover + .dropdown-menu-header-bar{
  visibility: visible;
  opacity: 1;
  display: block;
} */

.dropdown-menu-header-bar ul {
  padding: 5px 0;
  margin: 0;
  display: inline-block;
}

.dropdown-menu-header-bar ul li {
  display: block !important;
  width: 100%;
  background: #778f9c;
}

.dropdown-menu-header-bar ul li a {
  font-size: 17px;
  color: #fff;
  display: block;
  padding: 10px 15px;
  font-weight: 500;
}

/* Allowed values for the css skill bars */
.value-0 {
  width: 0;
}
.value-1 {
  width: 1%;
}
.value-2 {
  width: 2%;
}
.value-3 {
  width: 3%;
}
.value-4 {
  width: 4%;
}
.value-5 {
  width: 5%;
}
.value-6 {
  width: 6%;
}
.value-7 {
  width: 7%;
}
.value-8 {
  width: 8%;
}
.value-9 {
  width: 9%;
}
.value-10 {
  width: 10%;
}
.value-11 {
  width: 11%;
}
.value-12 {
  width: 12%;
}
.value-13 {
  width: 13%;
}
.value-14 {
  width: 14%;
}
.value-15 {
  width: 15%;
}
.value-16 {
  width: 16%;
}
.value-17 {
  width: 17%;
}
.value-18 {
  width: 18%;
}
.value-19 {
  width: 19%;
}
.value-20 {
  width: 20%;
}
.value-21 {
  width: 21%;
}
.value-22 {
  width: 22%;
}
.value-23 {
  width: 23%;
}
.value-24 {
  width: 24%;
}
.value-25 {
  width: 25%;
}
.value-26 {
  width: 26%;
}
.value-27 {
  width: 27%;
}
.value-28 {
  width: 28%;
}
.value-29 {
  width: 29%;
}
.value-30 {
  width: 30%;
}
.value-31 {
  width: 31%;
}
.value-32 {
  width: 32%;
}
.value-33 {
  width: 33%;
}
.value-34 {
  width: 34%;
}
.value-35 {
  width: 35%;
}
.value-36 {
  width: 36%;
}
.value-37 {
  width: 37%;
}
.value-38 {
  width: 38%;
}
.value-39 {
  width: 39%;
}
.value-40 {
  width: 40%;
}
.value-41 {
  width: 41%;
}
.value-42 {
  width: 42%;
}
.value-43 {
  width: 43%;
}
.value-44 {
  width: 44%;
}
.value-45 {
  width: 45%;
}
.value-46 {
  width: 46%;
}
.value-47 {
  width: 47%;
}
.value-48 {
  width: 48%;
}
.value-49 {
  width: 49%;
}
.value-50 {
  width: 50%;
}
.value-51 {
  width: 51%;
}
.value-52 {
  width: 52%;
}
.value-53 {
  width: 53%;
}
.value-54 {
  width: 54%;
}
.value-55 {
  width: 55%;
}
.value-56 {
  width: 56%;
}
.value-57 {
  width: 57%;
}
.value-58 {
  width: 58%;
}
.value-59 {
  width: 59%;
}
.value-60 {
  width: 60%;
}
.value-61 {
  width: 61%;
}
.value-62 {
  width: 62%;
}
.value-63 {
  width: 63%;
}
.value-64 {
  width: 64%;
}
.value-65 {
  width: 65%;
}
.value-66 {
  width: 66%;
}
.value-67 {
  width: 67%;
}
.value-68 {
  width: 68%;
}
.value-69 {
  width: 69%;
}
.value-70 {
  width: 70%;
}
.value-71 {
  width: 71%;
}
.value-72 {
  width: 72%;
}
.value-73 {
  width: 73%;
}
.value-74 {
  width: 74%;
}
.value-75 {
  width: 75%;
}
.value-76 {
  width: 76%;
}
.value-77 {
  width: 77%;
}
.value-78 {
  width: 78%;
}
.value-79 {
  width: 79%;
}
.value-80 {
  width: 80%;
}
.value-81 {
  width: 81%;
}
.value-82 {
  width: 82%;
}
.value-83 {
  width: 83%;
}
.value-84 {
  width: 84%;
}
.value-85 {
  width: 85%;
}
.value-86 {
  width: 86%;
}
.value-87 {
  width: 87%;
}
.value-88 {
  width: 88%;
}
.value-89 {
  width: 89%;
}
.value-90 {
  width: 90%;
}
.value-91 {
  width: 91%;
}
.value-92 {
  width: 92%;
}
.value-93 {
  width: 93%;
}
.value-94 {
  width: 94%;
}
.value-95 {
  width: 95%;
}
.value-96 {
  width: 96%;
}
.value-97 {
  width: 97%;
}
.value-98 {
  width: 98%;
}
.value-99 {
  width: 99%;
}
.value-100 {
  width: 100%;
}

/*///////////////////////////////////////////////////
    // Animation \\ 
///////////////////////////////////////////////////*/
@-webkit-keyframes slideIn {
  0% {
    width: 0;
  }
  25% {
    width: 100%;
  }
  100% {
    width: normal;
  }
}
@-moz-keyframes slideIn {
  0% {
    width: 0;
  }
  25% {
    width: 100%;
  }
  100% {
    width: normal;
  }
}
@-ms-keyframes slideIn {
  0% {
    width: 0;
  }
  25% {
    width: 100%;
  }
  100% {
    width: normal;
  }
}
@-o-keyframes slideIn {
  0% {
    width: 0;
  }
  25% {
    width: 100%;
  }
  100% {
    width: normal;
  }
}
@keyframes slideIn {
  0% {
    width: 0;
  }
  25% {
    width: 100%;
  }
  100% {
    width: normal;
  }
}
.skillBarValue {
  -webkit-animation: slideIn 2s;
  -moz-animation: slideIn 2s;
  -o-animation: slideIn 2s;
  animation: slideIn 2s;
}

/*========Responsive===========*/

@media only screen and (min-width: 1025px) and (max-width: 1300px) {
  .card-box-col {
    max-width: 100%;
    flex: 0 0 100%;
  }
}

@media screen and (max-width: 1024px) {
  .home-content-col {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .home-content-col {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .desc-detail {
    margin-top: 10px;
  }
  .recommned-content p {
    padding-right: 20px;
  }
}

@media screen and (max-width: 767px) {
  .navbrand-logo .logo img {
    max-width: 100px !important;
  }
  .navbar.navbar-home {
    padding: 20px 15px;
  }
  /* .open-sidebar .main-wrap {
    margin-left: 360px !important;
  } */
  .open-sidebar .sidebar-menu {
    /* width: 100% !important; */
    right: 0px !important;
  }
  .main-wrap {
    padding-top: 80px !important;
  }
  .navbar-brand {
    width: 50%;
    font-size: 16px;
  }
  /* .username-dropdown .username {
    width: 40px;
    height: 40px;
  } */
  .card-box-col {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .graph-sec .card-box-col {
    margin-bottom: 10px;
  }
  .graph-sec .card-box-col:last-child {
    margin-bottom: 0;
  }
  .navbar-collapse form input[type='text']:focus {
    width: 230px;
  }
  .recommned-content {
    padding: 10px;
  }
  .ReactModal__Content.ReactModal__Content--after-open {
    left: 0px !important;
    right: 0px !important;
    max-width: 95%;
  }

  @media screen and (max-width: 655px) {
    /* .open-sidebar .main-wrap {
      margin-left: 360px !important;
    } */
    .open-sidebar .sidebar-menu {
      width: 100% !important;
      right: 0px !important;
    }
  }
}
