@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fafafa;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

form {
  padding: 20px;
  margin-top: 30px;
}

form button,
form h5 {
  margin: 20px 0;
}

input[type='text']:not(.browser-default):focus:not([readonly]),
input[type='email']:not(.browser-default):focus:not([readonly]),
input[type='password']:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
  border-color: #ec407a;
  box-shadow: none;
}

input[type='text']:not(.browser-default):focus:not([readonly]) + label,
textarea.materialize-textarea:focus:not([readonly]) + label,
input[type='email']:not(.browser-default):focus:not([readonly]) + label,
input[type='password']:not(.browser-default):focus:not([readonly]) + label {
  color: #ec407a !important;
}

.display_login {
  font-size: 20px;
}

.admin_page {
  height: 100vh;
}

.admin_form {
  padding-top: 0px;
  margin-top: 0px;
  width: 100%;
}

.admin_heading {
  color: #287fde;
  font-size: 55px;
  font-family: 'SFProDisplay-Bold';
}

.admin-page_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  max-width: 500px;
  margin: auto;

  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #6c6c6c5c;
  border-radius: 46px;
  padding: 70px 70px;
}

.prebook-model {
  padding: 4%;
}

.user-name {
  margin-top: 15px;
  font-weight: 500;
  font-size: 18px !important;
  color:black !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  grid-gap:10px;
  gap:10px
}
.name-line{
  display: inline-block;
  width: 370px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  cursor: pointer;
}
.user-logo {
  width: 30px;
  height: 30px;
  background: black;
  border-radius: 100%;
  display: flex;
  text-align: center;
  color: white;
  justify-content: center;
  align-items: center;
}
.time-duration {
  font-weight: 500;
  margin-bottom: 4px;
  margin-top: 0px !important;
  border-bottom: solid black 1.5px;
  color: black !important;
  font-size: 1;
  font-size: 20px !important;
  padding: 2%;

}

.prebook-model-div {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background: #00000052;
  border-radius: 20px;


  /* background-color: black;
  top: 0;
  left: 0;
  opacity: 0.5; */
  z-index: 9;
}
.header-list {
  padding: 3%;
  font-weight: 600;
  margin-top: -8%;
  position: absolute;
}

.desc-class{
  color: #626366;
  font-size: 12px;
  margin-top: 8px;
  margin-bottom: 0px;
  white-space: pre-wrap;
  font-family: 'SFProDisplay-Regular';
  overflow: hidden;
}

.my-booking-header{
  width: 100%;
    font-size: 26px;
    font-weight: 500;
    margin-left:15px;
    border-bottom: solid 1px;
    /* margin-top: 0px; */
    text-align: center;
    margin-bottom: 40px;

}
.main-wrap {
  height: 100vh;
  position: relative;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'SFProDisplay-Regular';
  background-color: #f4f4f4;
}

*,
:after,
:before {
  box-sizing: border-box;
}

a,
button {
  text-decoration: none;
}

@font-face {
  font-family: 'FontsFree-Net-SFProDisplay-Regular';
  src: url('/fonts/FontsFree-Net-SFProDisplay-Regular.eot');
  src: url('/fonts/FontsFree-Net-SFProDisplay-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('/fonts/FontsFree-Net-SFProDisplay-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFProDisplay-Regular';
  src: url('/fonts/SFProDisplay-Regular.svg#SFProDisplay-Regular') format('svg'),
    url('/fonts/SFProDisplay-Regular.ttf') format('truetype'),
    url('/fonts/SFProDisplay-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FontsFree-Net-SFProDisplay-Bold';
  src: url('/fonts/FontsFree-Net-SFProDisplay-Bold.eot');
  src: url('/fonts/FontsFree-Net-SFProDisplay-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('/fonts/FontsFree-Net-SFProDisplay-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFProDisplay-Bold';
  src: url('/fonts/SFProDisplay-Bold.svg#SFProDisplay-Bold') format('svg'),
    url('/fonts/SFProDisplay-Bold.ttf') format('truetype'),
    url('/fonts/SFProDisplay-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/*Navbar */
.navbar.navbar-home {
  background-color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 20px;
  flex-flow: row nowrap;
  justify-content: flex-start;
  position: fixed;
  width: 100%;
  z-index: 99;
  height: auto;
  line-height: 1;
}

.navbar-collapse {
  flex-grow: 1;
  align-items: center;
  justify-content: space-between !important;
  display: flex !important;
  flex-basis: auto;
}

.navbar-nav {
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  flex-direction: row;
}
.navbrand-logo {
  display: flex;
  align-items: center;
  width: 50%;
}
.navbrand-logo .logo img {
  max-width: 144px;
}

.navbrand-logo .logo {
  margin-right: 29px !important;
}

.navbar-brand {
  display: block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  color: #2d333e;
  font-family: 'SFProDisplay-Bold';
  font-size: 18px;
  font-weight: 500;
  line-height: inherit;
  white-space: nowrap;
  margin-right: auto;
  margin-left: auto;
  width: 25%;
  text-align: center;
}

.navbar-collapse form input[type='text'] {
  width: 43px;
  height: 40px;
  border-radius: 5px;
  border: 2px solid #2d333e;
  background-color: #ffffff;
  box-sizing: border-box;
  font-size: 16px;
  background-image: url(/static/media/ic-search.cae804b7.svg);
  background-position: 10px 10px;
  background-repeat: no-repeat;
  padding: 12px 12px 12px 27px;
  transition: 0.4s ease-in-out;
  position: absolute;
  left: 20px;
  top: 20px;
}

.navbar-collapse form input[type='text']:focus {
  width: 272px;
  border-color: #2d333e;
  outline: 0;
}

.right-side-menu {
  display: flex;
  margin-left: auto;
  flex-direction: row;
  width: 50%;
  justify-content: flex-end;
  align-items: center;
}

.navbar-collapse .right-side-menu input[type='text'] {
  position: relative;
  left: auto;
  top: auto;
  width: 280px;
  background-color: #f4f4f4;
  color: #555b6a;
  border: 0;
  background-image: url(/static/media/ic-search-new.f57da257.svg);
  background-position: 12px 12px;
  padding-left: 45px;
}

.navbar-collapse form input[type='text']:focus {
  width: 280px;
}

.navbar-collapse form {
  margin: 0;
  padding: 0;
  margin-right: 45px;
}

/* .user-icon {
      width: 43px;
      height: 40px;
      background-color: #2d333e;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
  } */

.profile-pic {
  width: 100%;
  /* width: 40px; */
  /* height: 40px; */
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

a.username:hover {
  color: white;
  text-decoration: none;
}

.profile-pic-characters {
  background-color: greenyellow;
  border-radius: 100%;
  height: 50px;
  aspect-ratio: 1;

  /* centering its content */
  display: flex;
  aspect-ratio: 1;
  align-items: center;
  justify-content: center;
}
.profile-pic-characters--characters {
  font-size: 1.6em;
}

.user-icon > input {
  display: none;
}

.exclamation {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dc3537;
  border-radius: 50%;
}

.exclamation .text {
  font-size: 14px;
  color: #ffffff;
}

/**/

h5 {
  font-family: 'SFProDisplay-Regular';
  color: #2d333e;
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 40px;
}

.sidebar-menu {
  width: 280px;
  background-color: #ffffff;
  position: fixed;
  left: 0;
  top: 90px;
  right: auto;
  min-height: 100%;
  overflow-y: auto;
  white-space: nowrap;
  height: 100%;
  z-index: 1;
  -webkit-animation: slideout 300ms forwards;
  animation: slideout 300ms forwards;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.sidebar-menu ul {
  padding-left: 0;
  margin: 0;
}
.sidebar-menu ul li {
  list-style: none;
}

.sidebar-menu ul li a {
  padding: 25px 29px;
  display: inline-block;
  text-transform: uppercase;
  cursor: pointer !important; 
  width: 100%;
}

.sidebar-menu ul li a:hover {
  background-color: #accdf3b0;
  /* color: #ffffff; */
  text-decoration: none;
}

.sidebar-menu ul li a.active {
  background-color: #287fde;
  color: #ffffff;
  text-decoration: none;
}

.sidebar-menu ul li a:active {
  outline: none;
}
.sidebar-menu ul li a.active :focus {
  outline: none;
}

.sidebar-menu ul li a .icon {
  margin-right: 29px;
}

.sidebar-menu ul li a .text {
  color: #2d333e;
  font-size: 15px;
  /* font-family: "Poppins-Regular"; */
  letter-spacing: 1px;
  line-height: 20px;
}

/* .sidebar-menu ul li a:hover .icon,
.sidebar-menu ul li a.active .icon {
   filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(316deg)
    brightness(101%) contrast(103%); 
}  */

/* .sidebar-menu ul li a:hover .text {
  color: #ffffff;
} */

.sidebar-menu ul li a.active .text {
  color: #ffffff;
}

.open-sidebar .sidebar-menu {
  -webkit-animation: slidein 300ms forwards;
  animation: slidein 300ms forwards;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.main-wrap {
  margin-left: 0;
  padding: 20px;
  -webkit-animation: bodyslideout 300ms forwards;
  animation: bodyslideout 300ms forwards;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  position: relative;
  min-height: 100%;
  padding-top: 100px;
}

.open-sidebar .main-wrap {
  margin-left: 240px;
  -webkit-animation: bodyslidein 300ms forwards;
  animation: bodyslidein 300ms forwards;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

/* Slide in animation */
@-webkit-keyframes slidein {
  0% {
    left: -240px;
  }
  100% {
    left: 0;
  }
}
@keyframes slidein {
  0% {
    left: -200px;
  }
  100% {
    left: 0;
  }
}
@-webkit-keyframes slideout {
  0% {
    left: 0;
  }
  100% {
    left: -240px;
  }
}
@keyframes slideout {
  0% {
    left: 0;
  }
  100% {
    left: -240px;
  }
}
@-webkit-keyframes bodyslidein {
  0% {
    left: 0;
  }
  100% {
    left: 0;
  }
}
@keyframes bodyslidein {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: 240px;
  }
}
@-webkit-keyframes bodyslideout {
  0% {
    margin-left: 240px;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes bodyslideout {
  0% {
    margin-left: 240px;
  }
  100% {
    margin-left: 0;
  }
}

/* .family-detail {
    display: flex;
    align-items: center;
    white-space: nowrap;
   padding-bottom: 15px;
    width: auto;
    overflow-x: auto;
    overflow-y: hidden;
}

.family-detail a {
    border-radius: 12px;
    background-color: #2d333e;
    color: #ffffff;
    font-size: 16px;
    padding: 9px 27px;
    margin-right: 10px;    
}
.family-detail a.disabled {
    background-color: #dddddd;
} */

.family-detail {
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding-bottom: 15px;
  width: auto;
  overflow-x: auto;
  overflow-y: hidden;
}

.family-detail a {
  border-radius: 12px;
  background-color: #2d333e;
  color: #ffffff;
  font-size: 16px;
  padding: 9px 27px;
  margin-right: 10px;
}
.family-detail a.disabled {
  background-color: #dddddd;
}

.family-detail .family-detail-inner {
  display: flex;
}

.add-family-detail-button .modal-toggle {
  width: 32px;
  height: 32px;
  background-color: #2d333e;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 0;
  cursor: pointer;
}

.add-family-detail-button .modal-toggle:focus {
  outline: none;
}

.add-family-detail-button .modal-toggle span {
  line-height: 1;
}

.add-family-detail-button .modal-toggle span img {
  width: 18px;
}

/*Modal Start*/
.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.75) !important;
  overflow-x: hidden;
  overflow-y: scroll;
}

.ReactModal__Content.ReactModal__Content--after-open {
  max-width: 576px;
  width: 100%;
  margin: 0 auto;
  bottom: auto !important;
  top: 0 !important;
  margin: 30px auto;
}

.ReactModal__Content.ReactModal__Content--after-open .modal-header {
  position: relative;
  background-color: #fff;
  box-shadow: 0 1px 2px hsla(0, 0%, 0%, 0.06);
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 16px;
}

.ReactModal__Content.ReactModal__Content--after-open
  .modal-header
  .modal-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  color: #aaa;
  background: none;
  border: 0;
  cursor: pointer;
}

.ReactModal__Content.ReactModal__Content--after-open .modal-heading {
  font-size: 1.125em;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000000;
}

.input-form-group {
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
}

.input-form-group .form-input,
input:not([type]).form-input,
input[type='text']:not(.browser-default).form-input,
input[type='password']:not(.browser-default).form-input,
input[type='email']:not(.browser-default).form-input,
input[type='url']:not(.browser-default).form-input,
input[type='time']:not(.browser-default).form-input,
input[type='date']:not(.browser-default).form-input,
input[type='datetime']:not(.browser-default).form-input,
input[type='datetime-local']:not(.browser-default).form-input,
input[type='tel']:not(.browser-default).form-input,
input[type='number']:not(.browser-default).form-input,
input[type='search']:not(.browser-default).form-input,
textarea.materialize-textarea.form-input {
  width: 100%;
  padding: 8px 12px;
  box-sizing: border-box;
  font-size: 16px;
  border: 2px solid #dddddd;
  border-radius: 4px;
}

.ReactModalPortal form {
  margin: 0;
  padding: 0;
  padding-top: 20px;
  font-family: 'SFProDisplay-Regular';
}

.ReactModalPortal form .viewmore-btn {
  margin: 0;
}

.viewmore-btn-outer {
  text-align: right;
  display: inline-block;
  width: 100%;
  margin-top: 5px;
}

.viewmore-btn {
  font-size: 16px;
  background-color: #2d333e;
  color: #ffffff;
  padding: 10px;
  border-radius: 6px;
  border: 0;
}

/*Modal End*/

.home-content-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.home-content-col {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  flex: 0 0 50%;
  max-width: 50%;
}

.card-box-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.card-box-col {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  flex: 0 0 50%;
  max-width: 50%;
  margin-bottom: 20px;
}

.card-box {
  padding: 24px;
  background-color: #ffffff;
  border-radius: 19px;
  border: 1px solid #dddddd;
  height: 100%;
}

.card-box .progressbar-sec {
  margin-bottom: 60px;
}

.graph-content {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
}
.graph-content img {
  max-width: 100%;
}

.graph-content p {
  font-size: 14px;
  color: #2d333e;
  margin-bottom: 0;
  margin-top: 34px;
}
.graph-content p a {
  color: #287fde;
  text-decoration: underline;
}

.graph-content .graph-img {
  width: 100%;
  max-width: 100%;
  display: inline-block;
}

.graph-content .graph-chart {
  width: 100%;
  max-width: 120%;
  display: inline-block;
  margin-top: -50px;
}

.detail-card-box {
  border-radius: 19px;
  border: 1px solid #dddddd;
  background-color: #ffffff;
  padding: 17px 33px 17px 17px;
  margin-bottom: 10px;
}

.detail-card-modal {
  margin-top: 30px;
  /* display: inline-block; */
}

.detail-card-modal .detail-card-box {
  padding: 17px;
}

.detail-card-modal .card-box-content {
  padding-left: 0px;
  width: 100%;
}

.detail-card-modal .card-box-inner {
  margin-bottom: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e8e8e8;
}

.card-box-inner {
  display: flex;
  align-items: center;
}

.card-box-icon {
  width: 40px;
  height: 40px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-box-icon-goal {
  overflow: hidden;
  padding-right: 40px;
  display: flex;
  align-items: center;
}

.card-box-icon-goal h2 {
  padding-left: 20px;
}

.card-box-icon img {
  max-width: 40px;
}

.card-box-content {
  width: calc(100% - 40px);
  padding-left: 23px;
}

.card-box-content .title {
  color: #2d333e;
  font-size: 14px;
  margin-bottom: 5px;
  display: inline-block;
  font-family: 'SFProDisplay-Bold';
}

.classes-percentage {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.classes-percentage span {
  color: #2d333e;
  font-size: 14px;
}

.recommend-box {
  border-radius: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.recommned-content {
  width: 100%;
  padding: 22px 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.recommned-content p {
  width: 90%;
  margin: 0;
  color: #ffffff;
  font-size: 15px;
  line-height: 22px;
  padding-right: 20px;
}

.recommned-content p.subtitle {
  width: 90%;
  margin: 0;
  color: #ffffff;
  font-size: 12px;
  line-height: 22px;
  padding-right: 20px;
}

.recommend-img {
  width: 100%;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: cover;
  display: flex;
  height: 170px;
  border-radius: 6px 6px 0 0;
}
.progressbar-sec {
  margin-top: 13px;
}

.progressbar-sec .text {
  font-size: 15px;
  color: #2d333e;
  margin-top: 15px;
  display: inline-block;
  width: 100%;
}

.skillBarContainer {
  width: 100%;
  height: 5px;
  background: #dddddd;
  overflow: hidden;
  border-radius: 5px;
}

.skillBarContainer-md {
  height: 12px;
}

.skillBarValue {
  height: 5px;
  border-radius: 5px;
  background: #ef5180;
}

.skillBarValue-md {
  height: 12px;
}

.color-pink {
  background-color: #ef5180;
}
.color-yellow {
  background-color: #f3a611;
}
.color-red {
  background-color: #dc3537;
}
.color-purple {
  background-color: #976aff;
}
.color-skyblue {
  background-color: #35bfd8;
}
.color-green {
  background-color: #64f6d3;
}

.username-dropdown {
  position: relative;
}
.username-dropdown .username {
  width: 50px;
  height: 50px;
  background-color: #2d333e;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition-duration: 0.5s;
}

.dropdown-menu-header-bar {
  visibility: visible;
  opacity: 1;
  /* min-width: 10rem; */
  min-width: 20rem;
  /* ^^ ~sahil#fix */
  position: absolute;
  transition: all 0.5s ease;
  margin-top: 5px;
  right: 0;
  display: block;
  background-color: #768e9c;
  top: 100%;
}

.dropdown-menu-header-bar:after {
  content: '';
  position: absolute;
  top: -5px;
  right: 20px;
  display: block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #768e9c;
}

/* .username:hover + .dropdown-menu-header-bar{
  visibility: visible;
  opacity: 1;
  display: block;
} */

.dropdown-menu-header-bar ul {
  padding: 5px 0;
  margin: 0;
  display: inline-block;
}

.dropdown-menu-header-bar ul li {
  display: block !important;
  width: 100%;
  background: #778f9c;
}

.dropdown-menu-header-bar ul li a {
  font-size: 17px;
  color: #fff;
  display: block;
  padding: 10px 15px;
  font-weight: 500;
}

/* Allowed values for the css skill bars */
.value-0 {
  width: 0;
}
.value-1 {
  width: 1%;
}
.value-2 {
  width: 2%;
}
.value-3 {
  width: 3%;
}
.value-4 {
  width: 4%;
}
.value-5 {
  width: 5%;
}
.value-6 {
  width: 6%;
}
.value-7 {
  width: 7%;
}
.value-8 {
  width: 8%;
}
.value-9 {
  width: 9%;
}
.value-10 {
  width: 10%;
}
.value-11 {
  width: 11%;
}
.value-12 {
  width: 12%;
}
.value-13 {
  width: 13%;
}
.value-14 {
  width: 14%;
}
.value-15 {
  width: 15%;
}
.value-16 {
  width: 16%;
}
.value-17 {
  width: 17%;
}
.value-18 {
  width: 18%;
}
.value-19 {
  width: 19%;
}
.value-20 {
  width: 20%;
}
.value-21 {
  width: 21%;
}
.value-22 {
  width: 22%;
}
.value-23 {
  width: 23%;
}
.value-24 {
  width: 24%;
}
.value-25 {
  width: 25%;
}
.value-26 {
  width: 26%;
}
.value-27 {
  width: 27%;
}
.value-28 {
  width: 28%;
}
.value-29 {
  width: 29%;
}
.value-30 {
  width: 30%;
}
.value-31 {
  width: 31%;
}
.value-32 {
  width: 32%;
}
.value-33 {
  width: 33%;
}
.value-34 {
  width: 34%;
}
.value-35 {
  width: 35%;
}
.value-36 {
  width: 36%;
}
.value-37 {
  width: 37%;
}
.value-38 {
  width: 38%;
}
.value-39 {
  width: 39%;
}
.value-40 {
  width: 40%;
}
.value-41 {
  width: 41%;
}
.value-42 {
  width: 42%;
}
.value-43 {
  width: 43%;
}
.value-44 {
  width: 44%;
}
.value-45 {
  width: 45%;
}
.value-46 {
  width: 46%;
}
.value-47 {
  width: 47%;
}
.value-48 {
  width: 48%;
}
.value-49 {
  width: 49%;
}
.value-50 {
  width: 50%;
}
.value-51 {
  width: 51%;
}
.value-52 {
  width: 52%;
}
.value-53 {
  width: 53%;
}
.value-54 {
  width: 54%;
}
.value-55 {
  width: 55%;
}
.value-56 {
  width: 56%;
}
.value-57 {
  width: 57%;
}
.value-58 {
  width: 58%;
}
.value-59 {
  width: 59%;
}
.value-60 {
  width: 60%;
}
.value-61 {
  width: 61%;
}
.value-62 {
  width: 62%;
}
.value-63 {
  width: 63%;
}
.value-64 {
  width: 64%;
}
.value-65 {
  width: 65%;
}
.value-66 {
  width: 66%;
}
.value-67 {
  width: 67%;
}
.value-68 {
  width: 68%;
}
.value-69 {
  width: 69%;
}
.value-70 {
  width: 70%;
}
.value-71 {
  width: 71%;
}
.value-72 {
  width: 72%;
}
.value-73 {
  width: 73%;
}
.value-74 {
  width: 74%;
}
.value-75 {
  width: 75%;
}
.value-76 {
  width: 76%;
}
.value-77 {
  width: 77%;
}
.value-78 {
  width: 78%;
}
.value-79 {
  width: 79%;
}
.value-80 {
  width: 80%;
}
.value-81 {
  width: 81%;
}
.value-82 {
  width: 82%;
}
.value-83 {
  width: 83%;
}
.value-84 {
  width: 84%;
}
.value-85 {
  width: 85%;
}
.value-86 {
  width: 86%;
}
.value-87 {
  width: 87%;
}
.value-88 {
  width: 88%;
}
.value-89 {
  width: 89%;
}
.value-90 {
  width: 90%;
}
.value-91 {
  width: 91%;
}
.value-92 {
  width: 92%;
}
.value-93 {
  width: 93%;
}
.value-94 {
  width: 94%;
}
.value-95 {
  width: 95%;
}
.value-96 {
  width: 96%;
}
.value-97 {
  width: 97%;
}
.value-98 {
  width: 98%;
}
.value-99 {
  width: 99%;
}
.value-100 {
  width: 100%;
}

/*///////////////////////////////////////////////////
    // Animation \\ 
///////////////////////////////////////////////////*/
@-webkit-keyframes slideIn {
  0% {
    width: 0;
  }
  25% {
    width: 100%;
  }
  100% {
    width: normal;
  }
}
@keyframes slideIn {
  0% {
    width: 0;
  }
  25% {
    width: 100%;
  }
  100% {
    width: normal;
  }
}
.skillBarValue {
  -webkit-animation: slideIn 2s;
  animation: slideIn 2s;
}

/*========Responsive===========*/

@media only screen and (min-width: 1025px) and (max-width: 1300px) {
  .card-box-col {
    max-width: 100%;
    flex: 0 0 100%;
  }
}

@media screen and (max-width: 1024px) {
  .home-content-col {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .home-content-col {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .desc-detail {
    margin-top: 10px;
  }
  .recommned-content p {
    padding-right: 20px;
  }
}

@media screen and (max-width: 767px) {
  .navbrand-logo .logo img {
    max-width: 100px !important;
  }
  .navbar.navbar-home {
    padding: 20px 15px;
  }
  /* .open-sidebar .main-wrap {
    margin-left: 360px !important;
  } */
  .open-sidebar .sidebar-menu {
    /* width: 100% !important; */
    right: 0px !important;
  }
  .main-wrap {
    padding-top: 80px !important;
  }
  .navbar-brand {
    width: 50%;
    font-size: 16px;
  }
  /* .username-dropdown .username {
    width: 40px;
    height: 40px;
  } */
  .card-box-col {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .graph-sec .card-box-col {
    margin-bottom: 10px;
  }
  .graph-sec .card-box-col:last-child {
    margin-bottom: 0;
  }
  .navbar-collapse form input[type='text']:focus {
    width: 230px;
  }
  .recommned-content {
    padding: 10px;
  }
  .ReactModal__Content.ReactModal__Content--after-open {
    left: 0px !important;
    right: 0px !important;
    max-width: 95%;
  }

  @media screen and (max-width: 655px) {
    /* .open-sidebar .main-wrap {
      margin-left: 360px !important;
    } */
    .open-sidebar .sidebar-menu {
      width: 100% !important;
      right: 0px !important;
    }
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: 'SFProDisplay-Regular';
}

*,
:after,
:before {
  box-sizing: border-box;
}

a,
button {
  text-decoration: none;
}

@font-face {
  font-family: 'FontsFree-Net-SFProDisplay-Regular';
  src: url('/fonts/FontsFree-Net-SFProDisplay-Regular.eot');
  src: url('/fonts/FontsFree-Net-SFProDisplay-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('/fonts/FontsFree-Net-SFProDisplay-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFProDisplay-Regular';
  src: url('/fonts/SFProDisplay-Regular.svg#SFProDisplay-Regular') format('svg'),
    url('/fonts/SFProDisplay-Regular.ttf') format('truetype'),
    url('/fonts/SFProDisplay-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FontsFree-Net-SFProDisplay-Bold';
  src: url('/fonts/FontsFree-Net-SFProDisplay-Bold.eot');
  src: url('/fonts/FontsFree-Net-SFProDisplay-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('/fonts/FontsFree-Net-SFProDisplay-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFProDisplay-Bold';
  src: url('/fonts/SFProDisplay-Bold.svg#SFProDisplay-Bold') format('svg'),
    url('/fonts/SFProDisplay-Bold.ttf') format('truetype'),
    url('/fonts/SFProDisplay-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

h2 {
  font-size: 44px;
  font-family: 'SFProDisplay-Bold';
  color: #ffffff;
  margin-top: 0;
  margin-bottom: 40px;
}

.field-input {
  margin-bottom: 15px;
}

.field-input:last-child {
  margin-bottom: 0;
}

.custom-input {
  position: relative;
  margin: 0 auto;
  max-width: 415px;
  width: 100%;
}

.custom-input .icon-input {
  position: absolute;
  left: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.custom-input .input-control {
  padding-left: 40px;
  max-width: 415px;
  width: 100%;
}

.input-control {
  background-color: #eceef0;
  border: 1px solid #adb1b0;
  height: 53px;
  border-radius: 6px;
  padding: 15px;
  font-size: 18px;
  line-height: 24px;
  color: #adb1b0;
}

.btn {
  width: 100%;
  max-width: 94%;
  padding: 20px;
  border-radius: 6px;
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 21px;
  border: 1px solid #ffffff;
  text-transform: uppercase;
  cursor: pointer;
  display: inline-block;
}

.btn-blue {
  background-color: #287fde;
  color: #ffffff;
}

.btn-white {
  background-color: transparent;
  color: #ffffff;
}

.login-body {
  position: relative;
  text-align: center;
  display: flex;
  min-height: 100%;
  height: 100vh;
  background: #ffffff;
}

.sign-up-body {
  flex-direction: row-reverse;
}

.login-body .login-bg {
  background: linear-gradient(146deg, #7cf5ff 0%, #6bb4e5 27%, #3d009f 100%);
  width: 40%;
  text-align: left;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
}

.login-body .login-bg .login-bg-content {
  max-width: 480px;
  text-align: center;
}

.login-body .login-bg .login-bg-content p {
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  margin-bottom: 80px;
}

.login-body .login-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
  padding: 40px;
  overflow-y: auto;
  position: relative;
}

.navbar-brand-logo {
  margin: 0;
  position: absolute;
  left: 40px;
  top: 40px;
  z-index: 1;
}

.navbar-brand-logo .logo {
  display: inline-block;
}

.navbar-brand-logo .logo img {
  max-width: 207px;
  width: 100%;
}

.login-body .login-content .login-content-inner {
  max-width: 480px;
}

.login-body .login-content .login-content-inner p {
  margin-top: 0;
  margin-bottom: 45px;
  font-size: 18px;
  line-height: 24px;
  color: #282c31;
}

.login-body .login-content .login-content-inner .forgot-password {
  font-size: 18px;
  line-height: 24px;
  color: #282c31;
  margin-top: 45px;
  display: inline-block;
  width: 100%;
  text-decoration: underline;
}

.login-body .login-content .login-content-inner .btn {
  margin-top: 50px;
}

.social-icon-group {
  margin-bottom: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-icon-group a {
  width: 53px;
  height: 54px;
  border-radius: 6px;
  border: 1px solid #adb1b0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 20px;
}

.social-icon-group a img {
  max-width: 27px;
  width: 100%;
}

.login-content-inner h2 {
  color: #287fde;
}
/* ============Amenities Icon ============*/

.amenities-icon {
  width: 24px;
  height: 30px;
}

.amenities-active-icon {
  height: 30px;
}

/*========Responsive===========*/

@media screen and (max-width: 991px) {
  h2 {
    font-size: 40px;
    margin-bottom: 30px;
  }
  .navbar-brand-logo .logo img {
    max-width: 180px;
  }
  .social-icon-group {
    margin-bottom: 30px;
  }
  .login-body .login-content .login-content-inner p {
    margin-bottom: 30px;
  }
  .login-body .login-content .login-content-inner .forgot-password {
    margin-top: 30px;
  }
  .login-body .login-content .login-content-inner .btn {
    margin-top: 30px;
  }
  .login-body .login-bg .login-bg-content p {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 767px) {
  h2 {
    font-size: 26px;
    margin-bottom: 25px;
  }
  .input-control {
    height: 47px;
    font-size: 15px;
  }
  .btn {
    max-width: 100%;
    padding: 15px;
    font-size: 17px;
    line-height: 17px;
  }
  .login-body {
    flex-wrap: wrap;
    height: auto;
    min-height: unset;
  }
  .login-body .login-content {
    width: 100%;
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .login-body .login-bg {
    width: 100%;
  }
  .navbar-brand-logo {
    position: relative;
  }
  .navbar-brand-logo .logo img {
    max-width: 150px;
  }
  .login-body .login-content .login-content-inner {
    max-width: 100%;
  }
  .login-body .login-content .login-content-inner p {
    margin-bottom: 25px;
  }
  .social-icon-group {
    margin-bottom: 25px;
  }
  .social-icon-group a {
    width: 41px;
    height: 42px;
    margin: 0 15px;
  }
  .social-icon-group a img {
    max-width: 21px;
  }
  .login-body .login-content .login-content-inner p {
    font-size: 15px;
  }
  .login-body .login-content .login-content-inner .btn {
    margin-top: 25px;
  }
  .login-body .login-bg .login-bg-content p {
    display: none;
  }
  .login-body .login-bg .login-bg-content {
    max-width: 100%;
  }
  .sign-up-body {
    flex-direction: row;
  }
}

.menu-item {
  padding: 10px 15px;
  font-size: 17px;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'SFProDisplay-Regular';
  background-color: #f4f4f4;
}

*,
:after,
:before {
  box-sizing: border-box;
}

a,
button {
  text-decoration: none;
}

@font-face {
  font-family: 'FontsFree-Net-SFProDisplay-Regular';
  src: url(/static/media/FontsFree-Net-SFProDisplay-Regular.c9d4c1c9.eot);
  src: url(/static/media/FontsFree-Net-SFProDisplay-Regular.c9d4c1c9.eot?#iefix)
      format('embedded-opentype'),
    url(/static/media/FontsFree-Net-SFProDisplay-Regular.82ed45b4.woff2)
      format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFProDisplay-Regular';
  src: url(/static/media/SFProDisplay-Regular.bf6c83d5.svg#SFProDisplay-Regular)
      format('svg'),
    url(/static/media/SFProDisplay-Regular.379d4eaf.ttf) format('truetype'),
    url('/fonts/SFProDisplay-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FontsFree-Net-SFProDisplay-Bold';
  src: url(/static/media/FontsFree-Net-SFProDisplay-Bold.0ee69510.eot);
  src: url(/static/media/FontsFree-Net-SFProDisplay-Bold.0ee69510.eot?#iefix)
      format('embedded-opentype'),
    url(/static/media/FontsFree-Net-SFProDisplay-Bold.7f048ac3.woff2) format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFProDisplay-Bold';
  src: url(/static/media/SFProDisplay-Bold.5762e2ca.svg#SFProDisplay-Bold)
      format('svg'),
    url(/static/media/SFProDisplay-Bold.70b5d5cd.ttf) format('truetype'),
    url(/static/media/SFProDisplay-Bold.98c89023.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

/*Navbar */
.navbar {
  background-color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 20px;
  flex-flow: row nowrap;
  justify-content: flex-start;
  position: fixed;
  width: 100%;
  z-index: 99;
}

.navbar-collapse {
  flex-grow: 1;
  align-items: center;
  justify-content: space-between !important;
  display: flex !important;
  flex-basis: auto;
}

.navbar-nav {
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  flex-direction: row;
}
.navbrand-logo {
  display: flex;
  align-items: center;
  width: 50%;
}
.navbrand-logo .logo img {
  max-width: 144px;
}

.navbrand-logo .logo {
  margin-right: 29px;
}

.navbar-brand {
  display: block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  color: #2d333e;
  font-family: 'SFProDisplay-Bold';
  font-size: 18px;
  font-weight: 500;
  line-height: inherit;
  white-space: nowrap;
  margin-right: auto;
  margin-left: auto;
  width: 25%;
  text-align: center;
}

.navbar-collapse form input[type='text'] {
  width: 43px;
  height: 40px;
  border-radius: 5px;
  border: 2px solid #2d333e;
  background-color: #ffffff;
  box-sizing: border-box;
  font-size: 16px;
  background-image: url(/static/media/ic-search.cae804b7.svg);
  background-position: 10px 10px;
  background-repeat: no-repeat;
  padding: 12px 12px 12px 27px;
  transition: 0.4s ease-in-out;
  position: absolute;
  left: 20px;
  top: 20px;
}

.navbar-collapse form input[type='text']:focus {
  width: 272px;
  border-color: #2d333e;
  outline: 0;
}

.right-side-menu {
  display: flex;
  margin-left: auto;
  flex-direction: row;
  width: 50%;
  justify-content: flex-end;
}

.navbar-collapse .right-side-menu input[type='text'] {
  position: relative;
  left: auto;
  top: auto;
  width: 280px;
  background-color: #f4f4f4;
  color: #555b6a;
  border: 0;
  background-image: url(/static/media/ic-search-new.f57da257.svg);
  background-position: 12px 12px;
  padding-left: 45px;
}

.navbar-collapse form input[type='text']:focus {
  width: 280px;
}

.navbar-collapse form input[type='text']:focus + .btn-main {
  background-color: #000;
  color: #000;
}

.navbar-collapse form {
  margin-right: 45px;
}

.user-icon {
  width: 43px;
  height: 40px;
  background-color: #2d333e;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.exclamation {
  position: absolute;
  top: -16px;
  right: -6px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dc3537;
  border-radius: 50%;
}

.exclamation .text {
  font-size: 14px;
  color: #ffffff;
}

/**/

h5 {
  font-family: 'SFProDisplay-Regular';
  color: #2d333e;
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 40px;
}

h6 {
  font-family: 'SFProDisplay-Regular';
  color: #626366;
  font-size: 20px !important;
  margin-top: 5px !important;
  margin-left: 10px !important;
  margin-bottom: 5px !important;
}

h7 {
  font-family: 'SFProDisplay-Regular';
  color: #626366;
  font-size: 19px !important;
  margin-top: 5px !important;
  margin-left: 10px !important;
  margin-bottom: 5px !important;
}

.main-wrap {
  margin-left: 0;
  padding: 20px;
  -webkit-animation: bodyslideout 300ms forwards;
  animation: bodyslideout 300ms forwards;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  position: relative;
  min-height: 100%;
  padding-top: 100px;
}

.location-layout-wrapper {
  display: flex;
  flex-wrap: wrap;

  padding-top: 80px !important;
  /* margin-left: 15px; */
  /* margin-top: 15px; */

  margin-left: 0px !important;
  margin-right: 10px !important;
}

.open-sidebar .main-wrap {
  margin-left: 280px;
  -webkit-animation: bodyslidein 300ms forwards;
  animation: bodyslidein 300ms forwards;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

/* Slide in animation */
@-webkit-keyframes slidein {
  0% {
    left: -240px;
  }
  100% {
    left: 0;
  }
}
@keyframes slidein {
  0% {
    left: -240px;
  }
  100% {
    left: 0;
  }
}
@-webkit-keyframes slideout {
  0% {
    left: 0;
  }
  100% {
    left: -240px;
  }
}
@keyframes slideout {
  0% {
    left: 0;
  }
  100% {
    left: -240px;
  }
}
@-webkit-keyframes bodyslidein {
  0% {
    left: 0;
  }
  100% {
    left: 0;
  }
}
@keyframes bodyslidein {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: 240px;
  }
}
@-webkit-keyframes bodyslideout {
  0% {
    margin-left: 240px;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes bodyslideout {
  0% {
    margin-left: 240px;
  }
  100% {
    margin-left: 0;
  }
}

.family-detail {
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding-bottom: 15px;
  width: auto;
  overflow-x: auto;
  overflow-y: hidden;
}

.family-detail a {
  border-radius: 12px;
  background-color: #2d333e;
  color: #ffffff;
  font-size: 16px;
  padding: 9px 27px;
  margin-right: 10px;
}
.family-detail a.disabled {
  background-color: #dddddd;
}

.family-detail .family-detail-inner {
  display: flex;
}

.add-family-detail-button .modal-toggle {
  width: 32px;
  height: 32px;
  background-color: #2d333e;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 0;
  cursor: pointer;
  margin-right: 15px;
}

.add-family-detail-button .modal-toggle:focus {
  outline: none;
}

.add-family-detail-button .modal-toggle span {
  line-height: 1;
}

.add-family-detail-button .modal-toggle span img {
  width: 18px;
}

.input-form-group {
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
}

.input-form-group .form-input {
  width: 100%;
  padding: 8px 12px;
  box-sizing: border-box;
  font-size: 16px;
  border: 2px solid #dddddd;
  border-radius: 4px;
}

/* Modals Start */

.modal {
  position: absolute;
  z-index: 10000; /* 1 */
  top: 0;
  left: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
}

.modal.is-visible {
  visibility: visible;
}

.modal-overlay {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: hsla(0, 0%, 0%, 0.5);
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.3s, opacity 0.3s;
}

.modal.is-visible .modal-overlay {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
}

.modal-wrapper {
  position: absolute;
  z-index: 9999;
  top: 6em;
  left: 50%;
  width: 32em;
  margin-left: -16em;
  background-color: #fff;
  box-shadow: 0 0 1.5em hsla(0, 0%, 0%, 0.35);
}

.modal-transition {
  transition: all 0.3s 0.12s;
  -webkit-transform: translateY(-10%);
          transform: translateY(-10%);
  opacity: 0;
}

.modal.is-visible .modal-transition {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1;
}

.modal-header,
.modal-content {
  padding: 1em;
}

.modal-header {
  position: relative;
  background-color: #fff;
  box-shadow: 0 1px 2px hsla(0, 0%, 0%, 0.06);
  border-bottom: 1px solid #e8e8e8;
}

.modal-close {
  position: absolute;
  top: 0;
  right: 0;
  /* padding: 1em; */
  color: #aaa;
  background: none;
  border: 0;
  cursor: pointer;
  padding: 20px 10px 10px 10px !important;
}

.box-bg.rooms-card .modal-close img {
  height: 35px;
  width: 35px;
}

.modal-close img {
  height: 35px;
  width: 35px;
}

.modal-close:focus {
  outline: none;
  background-color: transparent;
}

.booking-details .rooms-card .date-time-box .event-date {
  display: flex;
  justify-content: center;
}

.booking-details .rooms-card .date-time-box .event-date .modal-arrow {
  position: relative;
  cursor: pointer;
  /* padding: 10px !important; */
  border: none;
  background: transparent;
  margin: 0px 45px !important;
}

.booking-details .rooms-card .date-time-box .event-date .modal-arrow img {
  width: 12px !important;
  height: auto !important;
}

.booking-details .rooms-card .date-time-box .event-date big {
  color: #287fde;
  font-size: 20px;
  font-family: 'SFProDisplay-Bold';
  text-decoration: underline;
}

.modal-heading {
  font-size: 1.125em;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.modal-content > *:first-child {
  margin-top: 0;
}

.modal-content > *:last-child {
  margin-bottom: 0;
}

/*Modal End*/

/* Alert Start */

.alert {
  position: absolute;
  z-index: 10000; /* 1 */
  top: 0;
  left: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
}

.alert.is-visible {
  visibility: visible;
}

.alert-overlay {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: hsla(0, 0%, 0%, 0.5);
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.3s, opacity 0.3s;
}

.alert.is-visible .alert-overlay {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
}

.alert-wrapper {
  position: absolute;
  z-index: 9999;
  top: 6em;
  left: 50%;
  width: 32em;
  margin-left: -16em;
  background-color: #fff;
  box-shadow: 0 0 1.5em hsla(0, 0%, 0%, 0.35);
}

.alert-transition {
  transition: all 0.3s 0.12s;
  -webkit-transform: translateY(-10%);
          transform: translateY(-10%);
  opacity: 0;
}

.alert.is-visible .alert-transition {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1;
}

.alert-header,
.alert-content {
  padding: 1em;
}

.alert-header {
  position: relative;
  background-color: #fff;
  box-shadow: 0 1px 2px hsla(0, 0%, 0%, 0.06);
  border-bottom: 1px solid #e8e8e8;
}

.alert-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1em;
  color: #aaa;
  background: none;
  border: 0;
  cursor: pointer;
}

.alert-close:focus {
  outline: none;
}

.alert-heading {
  font-size: 1.125em;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.alert-content > *:first-child {
  margin-top: 0;
}

.alert-content > *:last-child {
  margin-bottom: 0;
}

/*Alert End*/

.home-content-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.home-content-col {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  flex: 0 0 50%;
  max-width: 50%;
}

.card-box-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.card-box-col {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  flex: 0 0 50%;
  max-width: 50%;
  margin-bottom: 20px;
}

.card-box {
  padding: 24px;
  background-color: #ffffff;
  border-radius: 19px;
  border: 1px solid #dddddd;
  height: 100%;
}

.card-box .progressbar-sec {
  margin-bottom: 60px;
}

.graph-content {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
}
.graph-content img {
  max-width: 100%;
}

.graph-content p {
  font-size: 14px;
  color: #2d333e;
  margin-bottom: 0;
  margin-top: 34px;
}
.graph-content p a {
  color: #287fde;
  text-decoration: underline;
}

.graph-content .graph-img {
  width: 100%;
}

.detail-card-box {
  border-radius: 19px;
  border: 1px solid #dddddd;
  background-color: #ffffff;
  padding: 17px 33px 17px 17px;
  margin-bottom: 10px;
}
.card-box-inner {
  display: flex;
  align-items: center;
}

.card-box-icon {
  width: 40px;
  height: 40px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-box-icon img {
  max-width: 40px;
}

.card-box-content {
  width: calc(100% - 40px);
  padding-left: 23px;
}

.card-box-content .title {
  color: #2d333e;
  font-size: 14px;
  margin-bottom: 5px;
  display: inline-block;
  font-family: 'SFProDisplay-Bold';
}

.classes-percentage {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.classes-percentage span {
  color: #2d333e;
  font-size: 14px;
}

.viewmore-btn-outer {
  text-align: right;
  display: inline-block;
  width: 100%;
  margin-top: 5px;
}

.viewmore-btn {
  max-width: 60px;
  font-size: 14px;
  background-color: #2d333e;
  color: #ffffff;
  padding: 10px;
  border-radius: 6px;
  border: 0;
  margin: 5px;
}
.more_less-btn {
  /* font-size: 15px !important; */
  color: #287fde !important;
  border: none !important;
  background-color: transparent;
  width: 25%;
  /* padding: 6px 3px; */
}

/****************** TIME SLOTS CSS START ******************/
.time-btn {
  font-size: 16px !important;
  color: #b9b9b9 !important;
  border: none !important;
  height: 37px;
  background-color: transparent;
  width: 25%;
  padding: 5px 3px;
  margin-top: 10px;
  text-align: center;
}

button.slot-available.time-btn:focus {
  /* background-color: #287fde; */
  background-color: transparent;
  /* opacity: 0.7; */
  border-radius: 20px 20px 20px 20px;
  color: #626366 !important;
}

.slot_btn_unbooked,
.slot-available {
  color: #626366 !important;
  font-family: 'SFProDisplay-Bold';
}

.slot_btn_unbooked:focus,
.slot-available:focus {
  outline: none;
}
.slot_btn_booked,
.slot-selected {
  background-color: #287fde;
  color: #ffffff !important;
  font-family: 'SFProDisplay-Bold';
}
.slot_btn_booked:focus,
.slot-selected:focus {
  background-color: #287fde;
  outline: none;
}

.slot_btn_booked_by_others,
.slot-unavailable {
  color: #b9b9b9 !important;
  font-family: 'SFProDisplay-Regular';
}
.slot_btn_booked_by_others:focus,
.slot-unavailable:focus {
  outline: none;
}
.selected-date-start {
  background-color: #287fde;
  border-radius: 7.5px 0 0 7.5px;
  color: #ffffff !important;
  font-family: 'SFProDisplay-Bold';
}
.selected-date-start-prebooking {
  background-color: #287fde;
  opacity: 0.55;
  border-radius: 7.5px 0 0 7.5px;
  color: #ffffff !important;
  font-family: 'SFProDisplay-Bold';
}
.selected-date-end {
  background-color: #287fde;
  border-radius: 0px 7.5px 7.5px 0px;
  color: #ffffff !important;
  font-family: 'SFProDisplay-Bold';
}
.selected-date-end-prebooking {
  background-color: #287fde;
  opacity: 0.55;
  border-radius: 0px 7.5px 7.5px 0px;
  color: #ffffff !important;
  font-family: 'SFProDisplay-Bold';
}

.selected-date-single {
  background-color: #287fde;
  border-radius: 7.5px 7.5px 7.5px 7.5px;
  color: #ffffff !important;
  font-family: 'SFProDisplay-Bold';
}

.selected-date-single-prebooking {
  background-color: #287fde;
  opacity: 0.55;
  border-radius: 7.5px 7.5px 7.5px 7.5px;
  color: #ffffff !important;
  font-family: 'SFProDisplay-Bold';
}

.selected-date-middle {
  background-color: #287fde;
  border-radius: 0 0 0 0;
  color: #ffffff !important;
  font-family: 'SFProDisplay-Bold';
}

.selected-date-middle-prebooking {
  background-color: #287fde;
  opacity: 0.55;
  border-radius: 0 0 0 0;
  color: #ffffff !important;
  font-family: 'SFProDisplay-Bold';
}

.selected-date-single:focus,
.selected-date-single-prebooking:focus,
.selected-date-middle:focus,
.selected-date-middle-prebooking:focus,
.selected-date-start:focus,
.selected-date-start-prebooking:focus,
.selected-date-end:focus,
.selected-date-end-prebooking:focus {
  background-color: #287fde;
  outline: none;
}
.in-selected-time {
  background-color: #287fde;
}
/****************** TIME SLOTS CSS END ******************/

.rooms-card .btn-wrap {
  display: block;
  margin-top: 20px;
  width: 100%;
}

.reservation-alert-modal.booking-details .rooms-card .btn-wrap {
  margin-bottom: 10px;
  outline: none;
}

.rooms-card .btn-wrap .btn {
  font-size: 14px;
  /* padding: 5px 15px; */
  max-width: 274px;
  margin: 0 auto 10px;
  text-transform: capitalize;
}

.card-box-outer-scroll {
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
}

.recommend-box {
  border-radius: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.recommned-content {
  width: calc(100% - 122px);
  padding: 22px 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.recommned-content p {
  margin: 0;
  color: #ffffff;
  font-size: 15px;
  line-height: 22px;
  padding-right: 48px;
}

.btn-main {
  padding: 15px;
  font-size: 18px;
  background-color: #000;
  color: #ffffff;
}

.recommend-img {
  width: 122px;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: cover;
  display: flex;
  height: 90px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.progressbar-sec {
  margin-top: 13px;
}

.progressbar-sec .text {
  font-size: 15px;
  color: #2d333e;
  margin-top: 15px;
  display: inline-block;
  width: 100%;
}

.skillBarContainer {
  width: 100%;
  height: 5px;
  background: #dddddd;
  overflow: hidden;
  border-radius: 5px;
}

.skillBarContainer-md {
  height: 12px;
}

.skillBarValue {
  height: 5px;
  border-radius: 5px;
  background: #ef5180;
}

.skillBarValue-md {
  height: 12px;
}

.color-pink {
  background-color: #ef5180;
}
.color-yellow {
  background-color: #f3a611;
}
.color-red {
  background-color: #dc3537;
}
.color-purple {
  background-color: #976aff;
}
.color-skyblue {
  background-color: #35bfd8;
}
.color-green {
  background-color: #64f6d3;
}

/* Allowed values for the css skill bars */
.value-00 {
  width: 0;
}
.value-1 {
  width: 1%;
}
.value-2 {
  width: 2%;
}
.value-3 {
  width: 3%;
}
.value-4 {
  width: 4%;
}
.value-5 {
  width: 5%;
}
.value-6 {
  width: 6%;
}
.value-7 {
  width: 7%;
}
.value-8 {
  width: 8%;
}
.value-9 {
  width: 9%;
}
.value-10 {
  width: 10%;
}
.value-11 {
  width: 11%;
}
.value-12 {
  width: 12%;
}
.value-13 {
  width: 13%;
}
.value-14 {
  width: 14%;
}
.value-15 {
  width: 15%;
}
.value-16 {
  width: 16%;
}
.value-17 {
  width: 17%;
}
.value-18 {
  width: 18%;
}
.value-19 {
  width: 19%;
}
.value-20 {
  width: 20%;
}
.value-21 {
  width: 21%;
}
.value-22 {
  width: 22%;
}
.value-23 {
  width: 23%;
}
.value-24 {
  width: 24%;
}
.value-25 {
  width: 25%;
}
.value-26 {
  width: 26%;
}
.value-27 {
  width: 27%;
}
.value-28 {
  width: 28%;
}
.value-29 {
  width: 29%;
}
.value-30 {
  width: 30%;
}
.value-31 {
  width: 31%;
}
.value-32 {
  width: 32%;
}
.value-33 {
  width: 33%;
}
.value-34 {
  width: 34%;
}
.value-35 {
  width: 35%;
}
.value-36 {
  width: 36%;
}
.value-37 {
  width: 37%;
}
.value-38 {
  width: 38%;
}
.value-39 {
  width: 39%;
}
.value-40 {
  width: 40%;
}
.value-41 {
  width: 41%;
}
.value-42 {
  width: 42%;
}
.value-43 {
  width: 43%;
}
.value-44 {
  width: 44%;
}
.value-45 {
  width: 45%;
}
.value-46 {
  width: 46%;
}
.value-47 {
  width: 47%;
}
.value-48 {
  width: 48%;
}
.value-49 {
  width: 49%;
}
.value-50 {
  width: 50%;
}
.value-51 {
  width: 51%;
}
.value-52 {
  width: 52%;
}
.value-53 {
  width: 53%;
}
.value-54 {
  width: 54%;
}
.value-55 {
  width: 55%;
}
.value-56 {
  width: 56%;
}
.value-57 {
  width: 57%;
}
.value-58 {
  width: 58%;
}
.value-59 {
  width: 59%;
}
.value-60 {
  width: 60%;
}
.value-61 {
  width: 61%;
}
.value-62 {
  width: 62%;
}
.value-63 {
  width: 63%;
}
.value-64 {
  width: 64%;
}
.value-65 {
  width: 65%;
}
.value-66 {
  width: 66%;
}
.value-67 {
  width: 67%;
}
.value-68 {
  width: 68%;
}
.value-69 {
  width: 69%;
}
.value-70 {
  width: 70%;
}
.value-71 {
  width: 71%;
}
.value-72 {
  width: 72%;
}
.value-73 {
  width: 73%;
}
.value-74 {
  width: 74%;
}
.value-75 {
  width: 75%;
}
.value-76 {
  width: 76%;
}
.value-77 {
  width: 77%;
}
.value-78 {
  width: 78%;
}
.value-79 {
  width: 79%;
}
.value-80 {
  width: 80%;
}
.value-81 {
  width: 81%;
}
.value-82 {
  width: 82%;
}
.value-83 {
  width: 83%;
}
.value-84 {
  width: 84%;
}
.value-85 {
  width: 85%;
}
.value-86 {
  width: 86%;
}
.value-87 {
  width: 87%;
}
.value-88 {
  width: 88%;
}
.value-89 {
  width: 89%;
}
.value-90 {
  width: 90%;
}
.value-91 {
  width: 91%;
}
.value-92 {
  width: 92%;
}
.value-93 {
  width: 93%;
}
.value-94 {
  width: 94%;
}
.value-95 {
  width: 95%;
}
.value-96 {
  width: 96%;
}
.value-97 {
  width: 97%;
}
.value-98 {
  width: 98%;
}
.value-99 {
  width: 99%;
}
.value-100 {
  width: 100%;
}

/*///////////////////////////////////////////////////
    // Animation \\ 
///////////////////////////////////////////////////*/
@-webkit-keyframes slideIn {
  0% {
    width: 0;
  }
  25% {
    width: 100%;
  }
  100% {
    width: normal;
  }
}
@keyframes slideIn {
  0% {
    width: 0;
  }
  25% {
    width: 100%;
  }
  100% {
    width: normal;
  }
}
.skillBarValue {
  -webkit-animation: slideIn 2s;
  animation: slideIn 2s;
}

/*=============== Event Page Css Start ===============*/
.views_row .location_box_row .box-bg {
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 20px 20px 20px 20px !important;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.1);

  width: 380px;
  margin-left: 15px;
  margin-right: 5px;
}
.views_row .box_list_item {
  position: relative;
  width: 100%;
}
.views_row .box_list_item img.thumb-img {
  transition: all 0.4s ease-in-out;
  border-radius: 20px 20px 0px 0px !important;
  width: 100%;
  max-width: 100%;
  height: 220px;
  object-fit: cover;
}
.box_list_item.card-wrap img {
  height: 210px;
  width: 100%;
  object-fit: cover;
}

.views_row .box_list_item h5 {
  display: inline-block;
  width: 80%;
  padding-left: 20px;
  margin-bottom: 0;
  transition: all 0.4s ease-in-out;
}
.location_box_row .box_list_item .date-time-box {
  padding: 10px 10px 10px 15px !important;
}
.views_row .location_box_row .rooms-card .box_description {
  padding: 0 15px 20px;
  display: none;
  color: #000;
  font-size: 14px;
  line-height: 20px;
  position: relative;
}
.location_box_row .d-flex-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.location_box_row .d-flex-box-date-picker {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 60px;
}
.box-bg.rooms-card {
  position: relative;
}
.location_box_row .box_list_item.card-wrap .main-title {
  margin-top: 4px;
  margin-bottom: 4px;
  /* flex-direction: column; */
  /* justify-self: start; */
  /* align-items: flex-start; */
  /* height: 25px; */
  /* overflow: hidden;
  transition: all 1s ease-out; */
}
.location_box_row .box_list_item.card-wrap.active .main-title {
  height: auto;
  transition: all 1s ease-out;
}
.location_box_row .box_list_item .date-time-box span {
  color: #626366;
  font-size: 14px;
}
.location_box_row h3 {
  margin: 0px;
  color: #626366;
  font-size: 19px;
  font-weight: 400;
}
.location_box_row h4 {
  margin: 0px;
  color: #626366;
  font-size: 19px;
  font-weight: 400;

  /* width: 250px; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.location_box_row .box_list_item.card-wrap p {
  color: #626366;
  font-size: 11px;
  margin-top: 8px;
  margin-bottom: 0px;
}
.location_box_row .box_description .col-desc p a {
  font-size: 12px;
  color: #287fde;
}
.location_box_row .box_list_item .main-title img {
  margin-right: 10px;
}
.location_box_row .rooms-card .br-btm {
  border-bottom: none;
  padding: 0px 15px 10px 15px;
}
.box_description .event-time .event-date small {
  display: block;
  color: #b9b9b9;
  font-size: 11px;
}
.box_description .event-time .event-date big {
  display: block;
  color: #626366;
  font-family: 'SFProDisplay-Bold';
  font-size: 15px;
}

.box_description
  .event-time
  .event-date
  big
  react-datepicker-wrapper
  react-datepicker__input-container
  react-datepicker-ignore-onclickoutside:focus {
  display: block;
  color: #626366;
  font-family: 'SFProDisplay-Bold';
  font-size: 15px;
  border-bottom: 10px;
}

.react-datepicker-wrapper,
.react-datepicker-wrapper react-datepicker__input-container,
.react-datepicker-wrapper
  react-datepicker__input-container
  input.react-datepicker-ignore-onclickoutside {
  background-color: transparent;
}

input.react-datepicker-ignore-onclickoutside {
  background-color: teal;
}

.box_description .event-time .event-date span {
  display: block;
  color: #626366;
  font-size: 16px;
}
.event-instructor .instructor-bio {
  display: flex;
  align-items: center;
}
.event-instructor .instructor-bio img {
  border-radius: 100%;
  object-fit: cover;
  width: 51px;
  height: 51px;
  margin-right: 12px;
}
.event-instructor .instructor-attending {
  display: flex;
  align-items: center;
}

.event-instructor .instructor-attending img {
  border-radius: 100%;
  object-fit: cover;
  width: 15px;
  height: 15px;
  margin-right: 12px;
}

/* .event-instructor .instructor-bio .title h6 {
  margin: 0px;
  color: #6c6c6c;
  font-size: 14px;
} */
.event-atten {

  width: 100%;
  display: flex;
  flex-wrap: wrap;

}
.event-atten a {
  font-size: 14px;
  color: #6c6c6c;
  display: flex;
  margin-bottom: 0px;
  align-items: center;
}
.event-atten a img {
  margin-left: 5px;
}
.blue-btn {
  display: flex;
  margin-top: 12px;
  justify-content: center;
  width: 100%;
}
.blue-btn a {
  color: #fff;
  font-size: 14px;
  background: #287fde;
  width: 100%;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 10px;
}
.blue-btn a:hover {
  text-decoration: none;
  color: #fff;
}
.blue-btn .react-add-to-calendar {
  width: 100%;
  /* margin-top: 5px; */
}

.calendar-btn {
  display: flex;
  margin-top: 12px;
  justify-content: center;
  width: 100%;
}

/* .react-add-to-calendar__dropdown {
  height: 500px;
} */
.calendar-btn a {
  border: 3px solid #287fde;
  background-color: #ffffff;
  color: #287fde;
  margin-bottom: 2px;
  font-weight: 600;
  width: 90%;
  max-width: inherit;
  /* padding: 22px; */
  /* position: absolute; */
}
.calendar-btn a:hover {
  text-decoration: none;
  color: #fff;
}
.calendar-btn .react-add-to-calendar {
  width: 100%;
  /* margin-top: 5px; */
}

.btnbox .drpdown_closed,
.btnbox .drpdown_open ul li a {
  /* border: 3px solid #287fde; */
  background-color: #287fde;
  color: #ffffff;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 600;
  width: 100%;
  max-width: inherit;
  padding: 22px;
  border-radius: 5px;
  display: flex;
  margin: 15px auto;
  align-items: center;
  justify-content: center;
}

.drpdown .react-add-to-calendar__button,
.drpdown ul li a {
  border: 3px solid #287fde;
  background-color: #ffffff;
  color: #287fde;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 600;
  width: 100%;
  max-width: inherit;
  padding: 22px;
  border-radius: 5px;
  display: flex;
  margin: 15px auto;
  align-items: center;
  justify-content: center;
}

.btnbox .react-add-to-calendar {
  width: 90%;
  position: relative;
}
.applecom-link {
  margin-top: 5px;
}

.google-link {
  margin-top: 5px;
}

.outlookcom-link {
  margin-top: 5px;
}

.event-image-container {
  position: relative;
  text-align: center;
  color: white;
}
.rsvp-button-drop-down {
  margin-top: 10px;
}

.bottom-left-blue-btn {
  display: flex;
  margin-top: 12px;
  justify-content: center;
  position: absolute;
  bottom: 0px;
  left: 0px;
  color: #fff;
  font-size: 14px;
  background: #287fde;
  width: 40%;
  height: 39px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.rsvped-btn {
  display: flex;
  margin-top: 12px;
  justify-content: center;
  width: 100%;
}
.rsvped-btn a {
  color: #fff;
  font-size: 14px;
  background: red;
  width: 100%;
  /* text-transform: uppercase; */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 10px;
}

.rsvped-btn-cancel-all {
  display: flex;
  margin-top: 12px;
  justify-content: center;
  width: 100%;
}
.rsvped-btn-cancel-all a {
  color: #fff;
  font-size: 14px;
  background: red;
  width: 274px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 10px;
}

.breadcrumb-row {
  margin: 15px 0px;
}
.breadcrumb-row .blue-btn {
  width: auto;
}
.breadcrumb-row .blue-btn a {
  width: 274px;
}
.breadcrumb-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1000px;
  margin: 0px auto 20px;
  padding: 0px 15px;
}
.breadcrumb-title a {
  font-size: 22px;
  color: #626366;
  text-transform: uppercase;
  text-decoration: underline;
  position: relative;
}
.breadcrumb-title a.prev-btn img {
  margin-right: 15px;
}
.breadcrumb-title a.next-btn img {
  margin-left: 15px;
}
.breadcrumb-title h2 {
  margin: 0px;
  font-size: 45px;
  color: #626366;
  text-transform: uppercase;
  letter-spacing: 3.3px;
  font-weight: 400;
}
.rooms-card .box_description .calenday-wrap {
  height: auto;
  border-top: 1px solid #ededed;
  /* padding-top: 10px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 10px; */
  padding: 10px 0px 10px 0px;
}
/* .rooms-card .box_description.open-room-attending .calenday-wrap {
  opacity: 0;
  visibility: hidden;
  height: 0;
} */
.rooms-card .box_description.open-room-attending .calenday-wrap {
  opacity: 1;
  visibility: visible;
  height: auto;
}
/* .rooms-card .box_description .room-attending-info {
  opacity: 0;
  visibility: hidden;
  height: 0;
} */
.rooms-card .box_description .room-attending-info {
  opacity: 1;
  visibility: visible;
  height: auto;
}
.rooms-card .box_description.open-room-attending .room-attending-info {
  opacity: 1;
  visibility: visible;
  height: auto;
}
.room-attending-info .info {
  border-top: 1px solid #ededed;
  padding: 13px 15px 15px 15px;
}
.room-attending-info .info:last-child {
  margin-bottom: 30px;
}
.room-attending-info .info .row {
  margin-bottom: 0;
}
.room-attending-info .info label {
  font-size: 14px;
  color: #b9b9b9;
  margin-bottom: 2px;
  margin-left: 10px;
}
.room-attending-info .info h5 {
  font-size: 15px;
  color: #626366;
  margin-bottom: 0;
}

.date-info {
  display: flex;
  justify-content: space-between;
}
.date-info label {
  width: 100%;
}

.add-to-calendar {
  background-color: #fff;
}

.reservation-alert-modal {
  background-color: #ffffff;
  border-radius: 20px;
  overflow: hidden;
  max-width: 254px !important;
  outline: none;
}

.reservation-alert-modal.booking-details {
  background-color: #ffffff;
  border-radius: 20px;
  overflow: hidden;
  /* max-width: 318px !important; */
  width: 450px;
  max-width: 85% !important;
  outline: none;
}

.reservation-alert-modal .modal-body {
  text-align: center;
  outline: none;
}
.reservation-alert-modal .modal-footer {
  padding: 0;
}
.reservation-alert-modal .modal-footer .modal-btnbox {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin: 0;
  outline: none;
}
.reservation-alert-modal .modal-footer .modal-btnbox button {
  margin: 0;
  border-right: 1px solid #dee2e6;
  border-radius: 0;
  color: #287fde;
  font-size: 15px;
  font-weight: 600;
  outline: none;
}
.reservation-alert-modal .modal-footer .modal-btnbox button:hover,
.reservation-alert-modal .modal-footer .modal-btnbox button:focus {
  background-color: transparent;
  outline: none;
}
.reservation-alert-modal .modal-footer .modal-btnbox button:last-child {
  border-right: none;
}

/* .ReactModal__Content.ReactModal__Content--after-open {
  max-width: 576px;
  width: 100%;
  margin: 0 auto;
  bottom: auto !important;
  top: 0 !important;
  margin: 30px auto;
  border: none !important;
  background-color: white !important;
}
.reservation-alert-modal .modal-body {
  background-color: white !important;
} */

.MuiBackdrop-root {
  z-index: 99999 !important;
}
.MuiCircularProgress-indeterminate {
  z-index: 99999;
}
/*=============== Room Page Css End ===============*/

/* Common Css */
button:focus,
.btn:focus,
.btn-primary.focus,
.btn-primary:focus {
  box-shadow: none !important;
  outline: none !important;
}
.btn {
  font-size: 14px;
  padding: 8px 15px;
}
.btn-primary {
  background-color: #287fde !important;
  border-color: #287fde !important;
  color: #ffffff !important;
  font-size: 14px !important;
  font-weight: bold !important;
  height: 36px !important;
  margin-bottom: 20px !important;
}
.btn-theme {
  background-color: #eb3223 !important;
  border-color: #eb3223 !important;
  color: #ffffff !important;
  margin-bottom: 25px !important;
  font-size: 14px !important;
  font-weight: bold !important;
  height: 43px !important;
}

/* Confirmed Modal */
.post-confirmation-modal {
  position: relative;
  background-color: #287fde;
  max-width: 565px !important;
  min-height: 68vh;
  width: 90% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.post-confirmation-modal h2 {
  font-size: 35px !important;
  color: #ffffff;
  font-weight: bold;
  margin-top: 0px !important;
}
.post-confirmation-modal .imgbox {
  width: 180px !important;
  height: 180px;
  max-width: 180px;
  margin: 0 auto 10px !important;
}
.post-confirmation-modal .imgbox img {
  width: 180px;
}

.add-to-calendar-modal {
  max-width: 665px !important;
  width: 90% !important;
  position: relative;
  text-align: center;
  padding: 100px 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.add-to-calendar-modal h2 {
  color: #287fde;
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 100px;
}
.add-to-calendar-modal .btnbox {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 596px;
  width: 90%;
}
.add-to-calendar-modal .btnbox .btn {
  border: 3px solid #287fde;
  background-color: #ffffff;
  color: #287fde;
  margin-bottom: 20px;
  font-weight: 600;
  width: 90%;
  max-width: inherit;
  padding: 22px;
}
.add-to-calendar-modal .btnbox .btn:last-child {
  margin-bottom: 0;
}

/*========Responsive===========*/
@media screen and (max-width: 1680px) {
  .room-attending-info .info h5 {
    font-size: 14px;
  }
}
@media screen and (max-width: 1440px) {
  .open-sidebar .views_row .box_list_item img.thumb-img {
    height: 150px;
  }
  .views_row .box_list_item img.thumb-img {
    height: 220px;
  }
}
@media screen and (max-width: 1366px) {
  .room-attending-info .info h5 {
    font-size: 13px;
  }
}

@media screen and (max-width: 1024px) {
  .views_row .box_list_item img.thumb-img {
    height: 200px;
  }
  .open-sidebar .views_row .box_list_item img.thumb-img {
    height: 150px;
  }
  .location_box_row h3 {
    font-size: 14px;
  }
  .location_box_row h4 {
    font-size: 14px;
  }
  .publish-btn {
    font-size: 14px;
    padding: 5px 10px;
  }
}

@media screen and (max-width: 991px) {
  .home-content-col {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .desc-detail {
    margin-top: 10px;
  }
  .recommned-content p {
    padding-right: 20px;
  }

  .breadcrumb-title {
    max-width: 100% !important;
    padding: 0px 0px;
  }
  .breadcrumb-title a span {
    display: none;
  }
  .breadcrumb-title h2 {
    font-size: 30px !important;
  }
}

@media screen and (max-width: 767px) {
  .card-box-col {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .graph-sec .card-box-col {
    margin-bottom: 10px;
  }
  .graph-sec .card-box-col:last-child {
    margin-bottom: 0;
  }
  .navbar-collapse form input[type='text']:focus {
    width: 230px;
  }
  .recommned-content {
    padding: 10px;
  }
  .views_row .box_list_item img.thumb-img {
    height: 100%;
  }
  .breadcrumb-title h2 {
    font-size: 20px !important;
  }
  .time-btn {
    font-size: 15px !important;
    width: 25%;
    padding: 4px 2px;
    /* margin-top: 5px; */
  }
}

@media screen and (max-width: 500px) {
  /* .event-layout-box {
    width: 70%;
  } */

  /* .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: 5px;
    flex-direction: column;
  } */
  .views_row .location_box_row .box-bg {
    width: 100%;
  }
  .location-layout-wrapper {
    display: inherit;
    flex-wrap: initial;
    padding-top: 95px !important;
    /* margin-right: 10px !important;  */

    margin-left: 0px !important;
    margin-right: 15px !important;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: 'SFProDisplay-Regular';
  background-color: #f4f4f4;
}

*,
:after,
:before {
  box-sizing: border-box;
  outline: none !important;
  /* box-shadow: none !important; */
}

a,
button {
  text-decoration: none;
}

@font-face {
  font-family: 'FontsFree-Net-SFProDisplay-Regular';
  src: url(/static/media/FontsFree-Net-SFProDisplay-Regular.c9d4c1c9.eot);
  src: url(/static/media/FontsFree-Net-SFProDisplay-Regular.c9d4c1c9.eot?#iefix)
      format('embedded-opentype'),
    url(/static/media/FontsFree-Net-SFProDisplay-Regular.82ed45b4.woff2)
      format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFProDisplay-Regular';
  src: url(/static/media/SFProDisplay-Regular.bf6c83d5.svg#SFProDisplay-Regular)
      format('svg'),
    url(/static/media/SFProDisplay-Regular.379d4eaf.ttf) format('truetype'),
    url('/fonts/SFProDisplay-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FontsFree-Net-SFProDisplay-Bold';
  src: url(/static/media/FontsFree-Net-SFProDisplay-Bold.0ee69510.eot);
  src: url(/static/media/FontsFree-Net-SFProDisplay-Bold.0ee69510.eot?#iefix)
      format('embedded-opentype'),
    url(/static/media/FontsFree-Net-SFProDisplay-Bold.7f048ac3.woff2) format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFProDisplay-Bold';
  src: url(/static/media/SFProDisplay-Bold.5762e2ca.svg#SFProDisplay-Bold)
      format('svg'),
    url(/static/media/SFProDisplay-Bold.70b5d5cd.ttf) format('truetype'),
    url(/static/media/SFProDisplay-Bold.98c89023.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

/*Navbar */
.navbar {
  background-color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 20px;
  flex-flow: row nowrap;
  justify-content: flex-start;
  position: fixed;
  width: 100%;
  z-index: 99;
}

.navbar-collapse {
  flex-grow: 1;
  align-items: center;
  justify-content: space-between !important;
  display: flex !important;
  flex-basis: auto;
}

.navbar-nav {
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  flex-direction: row;
}
.navbrand-logo {
  display: flex;
  align-items: center;
  width: 50%;
}
.navbrand-logo .logo img {
  max-width: 144px;
}

.navbrand-logo .logo {
  margin-right: 29px;
}

.navbar-brand {
  display: block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  color: #2d333e;
  font-family: 'SFProDisplay-Bold';
  font-size: 18px;
  font-weight: 500;
  line-height: inherit;
  white-space: nowrap;
  margin-right: auto;
  margin-left: auto;
  width: 25%;
  text-align: center;
}

.navbar-collapse form input[type='text'] {
  width: 43px;
  height: 40px;
  border-radius: 5px;
  border: 2px solid #2d333e;
  background-color: #ffffff;
  box-sizing: border-box;
  font-size: 16px;
  background-image: url(/static/media/ic-search.cae804b7.svg);
  background-position: 10px 10px;
  background-repeat: no-repeat;
  padding: 12px 12px 12px 27px;
  transition: 0.4s ease-in-out;
  position: absolute;
  left: 20px;
  top: 20px;
}

.navbar-collapse form input[type='text']:focus {
  width: 272px;
  border-color: #2d333e;
  outline: 0;
}

.right-side-menu {
  display: flex;
  margin-left: auto;
  flex-direction: row;
  width: 50%;
  justify-content: flex-end;
}

.navbar-collapse .right-side-menu input[type='text'] {
  position: relative;
  left: auto;
  top: auto;
  width: 280px;
  background-color: #f4f4f4;
  color: #555b6a;
  border: 0;
  background-image: url(/static/media/ic-search-new.f57da257.svg);
  background-position: 12px 12px;
  padding-left: 45px;
}

.navbar-collapse form input[type='text']:focus {
  width: 280px;
}

.navbar-collapse form input[type='text']:focus + .btn-main {
  background-color: #000;
  color: #000;
}

.navbar-collapse form {
  margin-right: 45px;
}

.user-icon {
  width: 43px;
  height: 40px;
  background-color: #2d333e;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.exclamation {
  position: absolute;
  top: -16px;
  right: -6px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dc3537;
  border-radius: 50%;
}

.exclamation .text {
  font-size: 14px;
  color: #ffffff;
}

/**/

h5 {
  font-family: 'SFProDisplay-Regular';
  color: #2d333e;
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 40px;
}

.main-wrap {
  margin-left: 0;
  padding: 20px;
  -webkit-animation: bodyslideout 300ms forwards;
  animation: bodyslideout 300ms forwards;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  position: relative;
  min-height: 100%;
  padding-top: 100px;
}

.event-layout-wrapper {
  display: flex;
  flex-wrap: wrap;

  padding-top: 90px !important;
  /* margin-left: 15px; */
  /* margin-top: 15px; */
  margin-right: 10px !important;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  justify-content: center;
  margin-top: 26px;
}

.open-sidebar .main-wrap {
  margin-left: 280px !important;
  -webkit-animation: bodyslidein 300ms forwards;
  animation: bodyslidein 300ms forwards;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

/* Slide in animation */
@-webkit-keyframes slidein {
  0% {
    left: -240px;
  }
  100% {
    left: 0;
  }
}
@keyframes slidein {
  0% {
    left: -240px;
  }
  100% {
    left: 0;
  }
}
@-webkit-keyframes slideout {
  0% {
    left: 0;
  }
  100% {
    left: -240px;
  }
}
@keyframes slideout {
  0% {
    left: 0;
  }
  100% {
    left: -240px;
  }
}
@-webkit-keyframes bodyslidein {
  0% {
    left: 0;
  }
  100% {
    left: 0;
  }
}
@keyframes bodyslidein {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: 240px;
  }
}
@-webkit-keyframes bodyslideout {
  0% {
    margin-left: 240px;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes bodyslideout {
  0% {
    margin-left: 240px;
  }
  100% {
    margin-left: 0;
  }
}

.family-detail {
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding-bottom: 15px;
  width: auto;
  overflow-x: auto;
  overflow-y: hidden;
}

.family-detail a {
  border-radius: 12px;
  background-color: #2d333e;
  color: #ffffff;
  font-size: 16px;
  padding: 9px 27px;
  margin-right: 10px;
}
.family-detail a.disabled {
  background-color: #dddddd;
}

.family-detail .family-detail-inner {
  display: flex;
}

.add-family-detail-button .modal-toggle {
  width: 32px;
  height: 32px;
  background-color: #2d333e;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 0;
  cursor: pointer;
}

.add-family-detail-button .modal-toggle:focus {
  outline: none;
}

.add-family-detail-button .modal-toggle span {
  line-height: 1;
}

.add-family-detail-button .modal-toggle span img {
  width: 18px;
}

.input-form-group {
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
}

.input-form-group .form-input {
  width: 100%;
  padding: 8px 12px;
  box-sizing: border-box;
  font-size: 16px;
  border: 2px solid #dddddd;
  border-radius: 4px;
}

/* Modals Start */

.modal {
  position: absolute;
  z-index: 10000; /* 1 */
  top: 0;
  left: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
}

.modal.is-visible {
  visibility: visible;
}

.modal-overlay {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: hsla(0, 0%, 0%, 0.5);
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.3s, opacity 0.3s;
}

.modal.is-visible .modal-overlay {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
}

.modal-wrapper {
  position: absolute;
  z-index: 9999;
  top: 6em;
  left: 50%;
  width: 32em;
  margin-left: -16em;
  background-color: #fff;
  box-shadow: 0 0 1.5em hsla(0, 0%, 0%, 0.35);
}

.modal-transition {
  transition: all 0.3s 0.12s;
  -webkit-transform: translateY(-10%);
          transform: translateY(-10%);
  opacity: 0;
}

.modal.is-visible .modal-transition {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1;
}

.modal-header,
.modal-content {
  padding: 1em;
}

.modal-header {
  position: relative;
  background-color: #fff;
  box-shadow: 0 1px 2px hsla(0, 0%, 0%, 0.06);
  border-bottom: 1px solid #e8e8e8;
}

.modal-close-blue,
.modal-close-blue-event,
.modal-close-blue-event img,
.modal-close-blue img {
  position: absolute;
  height: 35px !important;
  width: 35px !important;
  top: 8px !important;
  right: 10px !important;
  /* padding: 1em; */
  color: #aaa;
  background: none;
  border: 0;
  cursor: pointer;
}

.modal-close-blue:focus,
.modal-close-blue-event:focus {
  outline: none;
  background: none;
}

.modal-share-btn {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 10px 25px 10px 25px;
  color: #fff;
  background-color: #eb3223;
  /* background: none; */
  border: 0;
  border-radius: 5px;
  cursor: pointer;
}

.modal-share-btn:focus {
  outline: none;
  background-color: #eb3223;
}

.modal-heading {
  font-size: 1.125em;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.modal-content > *:first-child {
  margin-top: 0;
}

.modal-content > *:last-child {
  margin-bottom: 0;
}

/*Modal End*/

.home-content-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.home-content-col {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  flex: 0 0 50%;
  max-width: 50%;
}

.card-box-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.card-box-col {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  flex: 0 0 50%;
  max-width: 50%;
  margin-bottom: 20px;
}

.card-box {
  padding: 24px;
  background-color: #ffffff;
  border-radius: 19px;
  border: 1px solid #dddddd;
  height: 100%;
}

.card-box .progressbar-sec {
  margin-bottom: 60px;
}

.graph-content {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
}
.graph-content img {
  max-width: 100%;
}

.graph-content p {
  font-size: 14px;
  color: #2d333e;
  margin-bottom: 0;
  margin-top: 34px;
}
.graph-content p a {
  color: #287fde;
  text-decoration: underline;
}

.graph-content .graph-img {
  width: 100%;
}

.detail-card-box {
  border-radius: 19px;
  border: 1px solid #dddddd;
  background-color: #ffffff;
  padding: 17px 33px 17px 17px;
  margin-bottom: 10px;
}
.card-box-inner {
  display: flex;
  align-items: center;
}

.card-box-icon {
  width: 40px;
  height: 40px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-box-icon img {
  max-width: 40px;
}

.card-box-content {
  width: calc(100% - 40px);
  padding-left: 23px;
}

.card-box-content .title {
  color: #2d333e;
  font-size: 14px;
  margin-bottom: 5px;
  display: inline-block;
  font-family: 'SFProDisplay-Bold';
}

.classes-percentage {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.classes-percentage span {
  color: #2d333e;
  font-size: 14px;
}

.viewmore-btn-outer {
  text-align: right;
  display: inline-block;
  width: 100%;
  margin-top: 5px;
}

.viewmore-btn {
  max-width: 60px;
  font-size: 14px;
  background-color: #2d333e;
  color: #ffffff;
  padding: 10px;
  border-radius: 6px;
  border: 0;
  margin: 5px;
}

.slot_btn_unbooked {
  font-size: 16px;
  background-color: #ffffff;
  color: #287fde;
  padding: 3%;
  border-radius: 6px;
  border: 1;
  border-color: #287fde;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 5px;
  width: 30%;
}

.slot_btn_booked {
  font-size: 16px;
  background-color: #287fde;
  color: #ffffff;
  padding: 3%;
  border-radius: 6px;
  border: 1;
  border-color: #287fde;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 5px;
  width: 30%;
}
.slot_btn_booked:focus {
  background-color: #287fde;
  outline: none;
}

.slot_btn_booked_by_others {
  font-size: 16px;
  background-color: #b9b9b9;
  color: #ffffff;
  padding: 10px 25px;
  border-radius: 6px;
  border: 1;
  border-color: #b9b9b9;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 5px;
}
.slot_btn_booked_by_others:focus {
  background-color: #b9b9b9;
  outline: none;
}

.slot_btn_unbooked:focus {
  background-color: #ffffff;
  outline: none;
}
.card-box-outer-scroll {
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
}

.recommend-box {
  border-radius: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.recommned-content {
  width: calc(100% - 122px);
  padding: 22px 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.recommned-content p {
  margin: 0;
  color: #ffffff;
  font-size: 15px;
  line-height: 22px;
  padding-right: 48px;
}

.btn-main {
  padding: 15px;
  font-size: 18px;
  background-color: #000;
  color: #ffffff;
}

.recommend-img {
  width: 122px;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: cover;
  display: flex;
  height: 90px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.progressbar-sec {
  margin-top: 13px;
}

.progressbar-sec .text {
  font-size: 15px;
  color: #2d333e;
  margin-top: 15px;
  display: inline-block;
  width: 100%;
}

.skillBarContainer {
  width: 100%;
  height: 5px;
  background: #dddddd;
  overflow: hidden;
  border-radius: 5px;
}

.skillBarContainer-md {
  height: 12px;
}

.skillBarValue {
  height: 5px;
  border-radius: 5px;
  background: #ef5180;
}

.skillBarValue-md {
  height: 12px;
}

.color-pink {
  background-color: #ef5180;
}
.color-yellow {
  background-color: #f3a611;
}
.color-red {
  background-color: #dc3537;
}
.color-purple {
  background-color: #976aff;
}
.color-skyblue {
  background-color: #35bfd8;
}
.color-green {
  background-color: #64f6d3;
}

/* Allowed values for the css skill bars */
.value-00 {
  width: 0;
}
.value-1 {
  width: 1%;
}
.value-2 {
  width: 2%;
}
.value-3 {
  width: 3%;
}
.value-4 {
  width: 4%;
}
.value-5 {
  width: 5%;
}
.value-6 {
  width: 6%;
}
.value-7 {
  width: 7%;
}
.value-8 {
  width: 8%;
}
.value-9 {
  width: 9%;
}
.value-10 {
  width: 10%;
}
.value-11 {
  width: 11%;
}
.value-12 {
  width: 12%;
}
.value-13 {
  width: 13%;
}
.value-14 {
  width: 14%;
}
.value-15 {
  width: 15%;
}
.value-16 {
  width: 16%;
}
.value-17 {
  width: 17%;
}
.value-18 {
  width: 18%;
}
.value-19 {
  width: 19%;
}
.value-20 {
  width: 20%;
}
.value-21 {
  width: 21%;
}
.value-22 {
  width: 22%;
}
.value-23 {
  width: 23%;
}
.value-24 {
  width: 24%;
}
.value-25 {
  width: 25%;
}
.value-26 {
  width: 26%;
}
.value-27 {
  width: 27%;
}
.value-28 {
  width: 28%;
}
.value-29 {
  width: 29%;
}
.value-30 {
  width: 30%;
}
.value-31 {
  width: 31%;
}
.value-32 {
  width: 32%;
}
.value-33 {
  width: 33%;
}
.value-34 {
  width: 34%;
}
.value-35 {
  width: 35%;
}
.value-36 {
  width: 36%;
}
.value-37 {
  width: 37%;
}
.value-38 {
  width: 38%;
}
.value-39 {
  width: 39%;
}
.value-40 {
  width: 40%;
}
.value-41 {
  width: 41%;
}
.value-42 {
  width: 42%;
}
.value-43 {
  width: 43%;
}
.value-44 {
  width: 44%;
}
.value-45 {
  width: 45%;
}
.value-46 {
  width: 46%;
}
.value-47 {
  width: 47%;
}
.value-48 {
  width: 48%;
}
.value-49 {
  width: 49%;
}
.value-50 {
  width: 50%;
}
.value-51 {
  width: 51%;
}
.value-52 {
  width: 52%;
}
.value-53 {
  width: 53%;
}
.value-54 {
  width: 54%;
}
.value-55 {
  width: 55%;
}
.value-56 {
  width: 56%;
}
.value-57 {
  width: 57%;
}
.value-58 {
  width: 58%;
}
.value-59 {
  width: 59%;
}
.value-60 {
  width: 60%;
}
.value-61 {
  width: 61%;
}
.value-62 {
  width: 62%;
}
.value-63 {
  width: 63%;
}
.value-64 {
  width: 64%;
}
.value-65 {
  width: 65%;
}
.value-66 {
  width: 66%;
}
.value-67 {
  width: 67%;
}
.value-68 {
  width: 68%;
}
.value-69 {
  width: 69%;
}
.value-70 {
  width: 70%;
}
.value-71 {
  width: 71%;
}
.value-72 {
  width: 72%;
}
.value-73 {
  width: 73%;
}
.value-74 {
  width: 74%;
}
.value-75 {
  width: 75%;
}
.value-76 {
  width: 76%;
}
.value-77 {
  width: 77%;
}
.value-78 {
  width: 78%;
}
.value-79 {
  width: 79%;
}
.value-80 {
  width: 80%;
}
.value-81 {
  width: 81%;
}
.value-82 {
  width: 82%;
}
.value-83 {
  width: 83%;
}
.value-84 {
  width: 84%;
}
.value-85 {
  width: 85%;
}
.value-86 {
  width: 86%;
}
.value-87 {
  width: 87%;
}
.value-88 {
  width: 88%;
}
.value-89 {
  width: 89%;
}
.value-90 {
  width: 90%;
}
.value-91 {
  width: 91%;
}
.value-92 {
  width: 92%;
}
.value-93 {
  width: 93%;
}
.value-94 {
  width: 94%;
}
.value-95 {
  width: 95%;
}
.value-96 {
  width: 96%;
}
.value-97 {
  width: 97%;
}
.value-98 {
  width: 98%;
}
.value-99 {
  width: 99%;
}
.value-100 {
  width: 100%;
}

/*///////////////////////////////////////////////////
    // Animation \\ 
///////////////////////////////////////////////////*/
@-webkit-keyframes slideIn {
  0% {
    width: 0;
  }
  25% {
    width: 100%;
  }
  100% {
    width: normal;
  }
}
@keyframes slideIn {
  0% {
    width: 0;
  }
  25% {
    width: 100%;
  }
  100% {
    width: normal;
  }
}
.skillBarValue {
  -webkit-animation: slideIn 2s;
  animation: slideIn 2s;
}

/*=============== Event Page Css Start ===============*/
/* .ReactModal__Content.ReactModal__Content--after-open {
  max-width: 576px;
  width: 100%;
  margin: 0 auto;
  bottom: auto !important;
  top: 0 !important;
  margin: 30px auto;
  border: none !important;
  background: transparent !important;
} */
.reservation-alert-modal .modal-body {
  background-color: white;
}

.share-modal .modal-body {
  background-color: white;
  border-radius: 15px;
  padding-left: 40px;
  padding-right: 30px;
}

.attendees-modal .modal-body {
  background-color: white;
  border-radius: 15px;
  padding-left: 20px;
  padding-right: 30px;
}

.attendees-title {
  background-color: transparent;
  text-align: left;
  margin-top: 25px;
  margin-bottom: 10px;
  margin-right: -5px;
  border-bottom: 1px solid #e5e5e5;

  padding-bottom: 5px;
  text-align: left;
  font-size: 23px;
  /* font-weight: bold; */
  font-family: 'SFProDisplay-Bold';
  letter-spacing: 0px;
  color: #6c6c6c;
  /* opacity: 1; */
}

.ReactModal__Content.ReactModal__Content--after-open .attending {
  background-color: transparent;
  padding: 10px 10px 5px 10px;
  border-radius: 0px 0px 4px 4px;
  color: #6c6c6c;
}
.views_row .box_row .box-bg {
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 30px 30px 30px 30px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.1);
}

.views_row_external .box_row .box-bg {
  margin-bottom: 0px;
  background-color: #fff;
  border-radius: 30px 30px 30px 30px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.1);
}

.views_row .single-event-modal,
.views_row_external .single-event-modal {
  /* max-width: 100% !important; */
  max-width: 536px !important;

  margin: auto;
  /* width: 505px !important; */
}

.event-layout-box {
  width: 370px;
  /* height: 240px; */
  margin-left: 15px;
  margin-right: 5px;
}

/* .event-layout-box:first-child {
  margin-left: 0;
} */

.views_row .box_list_item,
.views_row_external .box_list_item {
  position: relative;
  width: 100%;
}

.views_row .box_list_item img.thumb-img,
.views_row_external .box_list_item img.thumb-img {
  transition: all 0.4s ease-in-out;
  border-radius: 30px 30px 0px 0px;
  width: 100%;
  max-width: 100%;
  height: 240px;
  object-fit: cover;
}

.views_row .box_list_item h5,
.views_row_external .box_list_item h5 {
  display: inline-block;
  width: 80%;
  padding-left: 20px;
  margin-bottom: 0;
  transition: all 0.4s ease-in-out;
}

.box_row .box_list_item .date-time-box-event {
  /* padding: 10px 15px 15px 15px; */
  padding: 10px 10px 1px 15px !important;
}

.views_row .box_row .box_description,
.views_row_external .box_row .box_description {
  padding: 0px 15px 15px 15px;
  display: none;
  color: #000;
  font-size: 14px;
  /* line-height: 20px; */
}
.box_row {
  position: relative;

}
.box_row .d-flex-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.box_row .d-flex-box .date {
  font-size: 14px;
}

.box_row .box_list_item .main-title {
  margin-top: 5px;
  margin-bottom: 15px;
}
.box_row .box_list_item .date-time-box span {
  color: #626366;
  font-size: 14px;
}
.box_row h3 {
  margin: 0px;
  color: #626366;
  font-size: 19px;
  font-weight: 400;
}
.box_row h4 {
  margin: 0px;
  color: #626366;
  font-size: 19px;
  font-weight: 400;

  /* width: 240px; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.box_row p {
  color: #626366;
  font-size: 12px;
  margin-top: 8px;
  margin-bottom: 0px;
}
.box_row pre {
  color: #626366;
  font-size: 12px;
  margin-top: 8px;
  margin-bottom: 0px;
  white-space: pre-wrap;
  font-family: 'SFProDisplay-Regular';
}
.box_row .box_description .col-desc p a {
  font-size: 12px;
  color: #287fde;
}
.box_row .box_list_item .main-title img {
  margin-right: 10px;
}
.box_row .br-btm {
  border-bottom: 1px solid #e5e5e5;
  padding: 12px 0px;
}

.box_row .br-btm-desc {
  border-bottom: 1px solid #e5e5e5;
  padding: 0px 0px 12px 0px;
}

.box_row .br-btm p,
.box_row .br-btm-desc p {
  padding: 0px 0px 0px 0px !important;
}

.box_description .event-time .event-date small {
  display: block;
  color: #b9b9b9;
  font-size: 11px;
}
.box_description .event-time .event-date span {
  display: block;
  color: #626366;
  font-size: 16px;
}

/* .event-instructor { */
/* flex-wrap: wrap !important; */
/* width: 100% !important; */
/* display: flex !important; */
/* justify-content: flex-end !important; */
/* } */
.event-instructor .instructor-bio {
  display: flex;
  align-items: center;
  width: 100%;
}
.event-instructor .instructor-bio img {
  border-radius: 100%;
  object-fit: cover;
  width: 51px;
  height: 51px;
  margin-right: 12px;
}
.event-instructor .instructor-attending {
  display: flex;
  align-items: center;
  /* justify-content: flex-end !important; */
}

.event-instructor .instructor-attending img {
  border-radius: 100%;
  object-fit: cover;
  width: 15px;
  height: 15px;
  margin-right: 12px;
}

.event-instructor .instructor-bio .title h6,
.event-instructor .instructor-bio .title p {
  margin: 0px;
  color: #6c6c6c !important;
  font-size: 16px !important;
  margin-left: 0px !important;

  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: none; */
}

.event-atten .instructor-attending h6,
.event-atten .instructor-attending p {
  margin-bottom: 0;
  color: #6c6c6c !important;
  font-size: 15px !important;
  /* font-size: 50%; */
  /* font-size: calc(100% - width); */

  margin: 0px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.event-atten .instructor-attending img {
  margin-left: 15px;
}

.event-atten a {
  font-size: 14px;
  color: #6c6c6c;
  display: flex;
  margin-bottom: 0px;
  align-items: center;
}
.event-atten a img {
  margin-left: 5px;
}

.more_less-btn {
  /* font-size: 15px !important; */
  color: #287fde !important;
  border: none !important;
  background-color: transparent;
  width: 25%;
  /* padding: 6px 3px; */
}

.rsvp-btn {
  display: flex;
  margin-top: 12px;
  justify-content: center;
  width: 100%;
}
.rsvp-btn a {
  color: #fff;
  font-size: 14px;
  background: #287fde;
  width: 100%;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 10px;
}
.rsvp-btn a:hover {
  text-decoration: none;
  color: #fff;
}
.rsvp-btn .react-add-to-calendar {
  width: 100%;
  /* margin-top: 5px; */
}

.google-link {
  margin-top: 5px;
}

.outlookcom-link {
  margin-top: 5px;
}

.applecom-link {
  margin-top: 5px;
}

.event-image-container {
  position: relative;
  text-align: center;
  color: white;
}
.rsvp-button-drop-down {
  margin-top: 10px;
}
.bottom-left-rsvp-btn {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0px;
  left: 0px;
  color: #fff;
  font-size: 14px;
  background: #eb3223;
  /* width: 40%;
  height: 39px; */
  /* text-transform: uppercase; */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin: 0px 0px 0px 20px;
  padding: 5px 20px 5px 20px;
}

.bottom-left-rsvp-btn svg {
  margin-right: 10px;
}

.Demo__some-network {
  margin-right: 30px !important;
  margin-top: 30px !important;
}

.social-media-icon {
  width: 60px;
  height: 60px;
  border-radius: 10px;
}

.social-media-text1 {
  background-color: transparent;
  text-align: left;
  margin-top: 25px;
  /* margin-bottom: 30px; */
  margin-right: 10px;
  /* margin-left: 20px; */

  text-align: left;
  font-size: 23px;
  /* font-weight: bold; */
  font-family: 'SFProDisplay-Bold';
  letter-spacing: 0px;
  color: #287fde;
  /* opacity: 1; */
}

.social-media-text2 {
  background-color: transparent;
  text-align: left;
  margin-top: 30px;
  margin-right: 10px;
  /* margin-left: 20px; */

  text-align: left;
  font-size: 20px;
  /* font-weight: bold; */
  font-family: 'SFProDisplay-Bold';
  color: #313133;
  /* opacity: 1; */
}

.social-media-textbox {
  height: 40px;
  background-color: transparent;
  text-align: left;
  margin-top: 25px;
  margin-right: 10px;
  margin-bottom: 30px;

  align-items: center;
  display: flex;
  justify-content: space-between;
  /* justify-item; */

  border: 2px solid #287fde;
  border-radius: 5px;
  /* opacity: 1; */
}

.social-media-textbox-text {
  margin-top: 3px;
  margin-left: 10px;

  /* background-color: #35bfd8; */
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-size: 14px;
  /* font-weight: 700; */
  font-family: 'SFProDisplay-Bold';
  letter-spacing: 0px;
  color: #313133;
  /* opacity: 1; */
}

.social-media-copytext {
  margin-top: 3px;
  text-align: right;
  font-size: 14px;
  /* font-weight: bold; */
  font-family: 'SFProDisplay-Bold';

  margin-right: 10px;
  letter-spacing: 0px;
  color: #287fde;
  /* opacity: 1; */
}

.rsvped-btn {
  display: flex;
  margin-top: 12px;
  justify-content: center;
  width: 100%;
}
.rsvped-btn a,
.rsvped-btn button {
  color: #fff;
  font-size: 14px;
  background: #eb3223;
  width: 94%;
  /* text-transform: uppercase; */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 10px;
  border: 0;
}

.rsvped-btn-cancel-all {
  display: flex;
  margin-top: 12px;
  justify-content: center;
  width: 100%;
}
.rsvped-btn-cancel-all a,
.rsvped-btn-cancel-all button {
  color: #fff;
  font-size: 14px;
  background: red;
  width: 274px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 10px;
}

.breadcrumb-row {
  margin: 15px 0px;
}
.breadcrumb-row .rsvp-btn {
  width: auto;
}
.breadcrumb-row .rsvp-btn a {
  width: 274px;
}
.breadcrumb-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1000px;
  margin: 0px auto 20px;
  padding: 0px 15px;
}
.breadcrumb-title a {
  font-size: 22px;
  color: #626366;
  text-transform: uppercase;
  text-decoration: underline;
  position: relative;
}
.breadcrumb-title a.prev-btn img {
  margin-right: 15px;
}
.breadcrumb-title a.next-btn img {
  margin-left: 15px;
}
.breadcrumb-title h2 {
  margin: 0px;
  font-size: 45px;
  color: #626366;
  text-transform: uppercase;
  letter-spacing: 3.3px;
  font-weight: 400;
}

.btn-primary.focus,
.btn-primary:focus {
  box-shadow: none !important;
  outline: none !important;
}

.btn-primary {
  padding-top: 12px !important;
  background-color: #287fde !important;
  border-color: #287fde !important;
  color: #ffffff !important;
  font-size: 14px !important;
  /* font-weight: bold !important; */
  /* font-family: "SFProDisplay-Medium" !important; */
  font: normal normal medium 14px/11px SF Pro Display;
  letter-spacing: 0.6px !important;
  height: 43px !important;
}

/* Confirmed Modal */
.post-confirmation-modal {
  position: relative;
  background-color: #287fde;
  max-width: 565px !important;
  min-height: 68vh;
  width: 90% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.post-confirmation-modal h2 {
  font-size: 40px;
  color: #ffffff;
  font-weight: bold;
}
.post-confirmation-modal .imgbox {
  width: 100%;
  height: 100%;
  max-width: 236px;
  height: 236px;
  margin: 0 auto 20px;
}
.post-confirmation-modal .imgbox img {
  width: 100%;
}

/*=============== Event Page Css End ===============*/

/*========Responsive===========*/
@media screen and (max-width: 1440px) {
  .open-sidebar .views_row .box_list_item img.thumb-img {
    height: 240px;
  }
  .views_row .box_list_item img.thumb-img,
  .views_row_external .box_list_item img.thumb-img {
    height: 240px;
  }
  /* .news-banner {
    width:200;
    height:auto;
   } */
}

@media screen and (max-width: 1440px) {
  .event-atten {
    width: 100%;
  }
  .event-instructor .instructor-attending {
    justify-content: flex-end !important;
  }
  /* .instructor-details {
    display: flex;
    flex-direction: column;
    justify-content: right !important;
  } */
  /* .news-banner {
    width:200;
    height:auto;
   } */
}

@media screen and (max-width: 1200px) {
  /* .instructor-details {
    justify-content: space-between !important;
    display: flex !important;
    flex-direction: initial;
  } */
}

@media screen and (max-width: 1024px) {
  .views_row .box_list_item img.thumb-img {
    height: 240px;
  }
  .open-sidebar .views_row .box_list_item img.thumb-img {
    height: 240px;
  }
  /* .box_row h3 {
    font-size: 14px;
  } */
  /* .box_row h4 {
    font-size: 14px;
  } */
  .publish-btn {
    font-size: 14px;
    padding: 5px 10px;
  }
}

@media only screen and (max-width: 600px) {
  /* .news-banner{
    display: inline-block;
    width: 417px;
    height: auto;
    background-color: rgb(0, 191, 255);
    margin-left: -19px ;
    margin-top: -8px;
   } */
}

@media screen and (max-width: 991px) {
  .home-content-col {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .desc-detail {
    margin-top: 10px;
  }
  .recommned-content p {
    padding-right: 20px;
  }

  .breadcrumb-title {
    max-width: 100% !important;
    padding: 0px 0px;
  }
  .breadcrumb-title a span {
    display: none;
  }
  .breadcrumb-title h2 {
    font-size: 30px !important;
  }
}

@media screen and (max-width: 767px) {
  .card-box-col {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .graph-sec .card-box-col {
    margin-bottom: 10px;
  }
  .graph-sec .card-box-col:last-child {
    margin-bottom: 0;
  }
  .navbar-collapse form input[type='text']:focus {
    width: 230px;
  }
  .recommned-content {
    padding: 10px;
  }
  /* .views_row .box_list_item img.thumb-img {
    height: 100%;
  } */
  .breadcrumb-title h2 {
    font-size: 20px !important;
  }
}

@media screen and (max-width: 600px) {
  .Demo__some-network {
    margin-right: 20px !important;
  }
  .social-media-icon {
    width: 55px;
    height: 55px;
    border-radius: 8px;
  }

  .share-modal .modal-body {
    padding-left: 30px;
    padding-right: 20px;
  }

  .social-media-text1 {
    margin-top: 35px;
    margin-right: 10px;
    font-size: 20px;
  }

  .social-media-text2 {
    margin-top: 30px;
    margin-right: 10px;
    font-size: 18px;
  }
  .social-media-textbox {
    height: 40px;
    text-align: left;
    margin-top: 25px;
    margin-right: 10px;
    margin-bottom: 30px;
    /* opacity: 1; */
    word-wrap: break-word;
  }
  .social-media-textbox-text,
  .social-media-copytext {
    font-size: 12.5px;
  }
}

/* .testing{
  color: #000;
} */
.news-banner {
  position: -webkit-sticky;
  position: sticky;
  top: 90px;
  z-index: 1000;
  width: 100% !important;
  height: auto;
  background-color: #287fde;
  /* top: 78px; */
  /* width: 1279px !important; */
  /* margin-left: -19px;
      margin-right: -30px;
      margin-top: -20px; */
  /* ^^ old, ~sahil */
}

.lunch-menu {
  color: #ffffff;
  margin-top: 22px;
  font-size: 20px;
}

.move-center {
  text-align: center;
}
.order-lunch {
  color: #ffffff;
  margin-top: 4px;
}

.banner_image {
  width: auto;
}

.ship {
  text-align: center;
  background-color: #000;
}
.image-change {
  height: 745px;
  width: 18%;
  margin-top: 28px;
  margin-bottom: 16px;
}
.frame_work {
  height: 700px;
}

@media screen and (max-width: 500px) {
  /* .event-layout-box {
    width: 70%;
  } */

  .row {
    display: flex;
    flex-wrap: wrap;
    /* margin-right: 5px; */
    margin-right: -5px;
    flex-direction: column;
  }
  .event-layout-box {
    width: 100%;
    /* max-width: 80%; */
    /* height: 240px; */
    margin-left: 10px;
    margin-right: 10px;
  }
  .event-layout-wrapper {
    display: inherit;
    flex-wrap: initial;
    padding-top: 95px !important;
  }
  /* .news-banner{
    width: 417px !important;
    height: auto;
    background-color: rgb(0, 191, 255);
    margin-left: -19px;
    margin-top: -8px;
   } */
}

.ios-popconfirm {
  position: relative;
  background-color: #a8323280;
}

.ios-popconfirm .rsvp-confirm-model-wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #40404180;
  cursor: default;
  display: flex;
  align-items: center;
  place-content: center;
  border-radius: 18px;
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.cancel-modal {
  background: #fff;
  border-right: 1px #6c6c6c solid !important;
  border-radius: 0 0 0 18px !important;
}

.confirm-modal {
  background: #fff;
}

.button-model{
  width: 30% !important;
  border-right: none !important;
}
.red-button{
  color: red !important;
}
.seprater {
  border-right: solid 1px #6c6c6c !important;
border-radius: 0 !important;
/* border-bottom-left-radius: 18px !important;
border-bottom-right-radius: 18px !important; */
}

.rsvp-confirm-model-wrapper .rsvp-confirm-model {
  background-color: #fff;
  width: 275px;
  max-width: 90%;
  height: 150px;
  border-radius: 18px;
  position: relative;
  padding-top: 24px;
  text-align: center;
}

.rsvp-confirm-model h5 {
  color: #6c6c6c;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0px;
  /* font-family: "SFProDisplay"; */
}

.rsvp-confirm-model p {
  color: #6c6c6c;
  font-size: 14px;
  margin: 0;
  padding: 12px;
}

.rsvp-confirm-model-wrapper .confirm-button-wrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top: 1px #6c6c6c solid;
}

.rsvp-confirm-model-wrapper .confirm-button-wrapper button {
  width: 50%;
  height: 42px;
  border-radius: 18px;
  color: #287fde;
  border: 0;
  font-size: 16px;
}

.rsvp-confirm-model-wrapper .confirm-button-wrapper button:focus {
  background-color: transparent;
}

.rsvp-confirm-card-wrapper {
  background-color: #287fde;
  max-width: 565px !important;
  min-height: 68vh;
  width: 90% !important;
  position: absolute;
  /* height: 64%;
  width: 32%; */
  cursor: default;
  display: flex;
  align-items: center;
  place-content: center;
  border-radius: 18px;
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  /* z-index: 99999; */
}

.confirm-popup-wraper {
  background-color: #313133cf;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  cursor: default;
  display: flex;
  align-items: center;
  place-content: center;
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  z-index: 2222;
}

.rsvp-confirm-card-wrapper .close-icon-card {
  position: absolute;
  top: 28px;
  right: 22px;
  cursor: pointer;
}

.rsvp-confirm-card-wrapper .checksymbol-confirm-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  /* width: 100%;
  height: 100%;
  max-width: 236px;
  height: 236px;
  margin: 0 auto 20px; */
}
.rsvp-confirm-card-wrapper .checksymbol-confirm-card p {
  /* color: white;
  margin-top: 48px;
  font-size: 28px; */

  font-size: 40px;
  color: #ffffff;
  font-weight: bold;
}

.rsvp-confirm-card-wrapper .addcalander-button-wrapper-confirm-card p {
  color: white;
  margin: 0;
  margin-left: 12px;
  font-size: 20px;
}

.rsvp-confirm-card-wrapper .addcalander-button-wrapper-confirm-card {
  display: flex;
  position: absolute;
  width: 80%;
  bottom: 28px;
  height: 50px;
  align-items: center;
  place-content: center;
  cursor: pointer;
  border: 2px solid white;
  border-radius: 5px;
}

.rsvp-chooseCalender-model-wrapper {
  background-color: white;
  max-width: 565px !important;
  min-height: 68vh;
  width: 90% !important;
  position: absolute;
  /* background-color: white;
  position: absolute;
  height: 71%;
  width: 32%; */
  cursor: default;
  display: flex;
  padding: 62px;
  align-items: center;
  place-content: center;
  border-radius: 18px;
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  z-index: 2323;
}

.rsvp-chooseCalender-model-wrapper .close-icon-card {
  position: absolute;
  top: 28px;
  right: 22px;
  cursor: pointer;
}

.rsvp-chooseCalender-model-wrapper .chooseCalender-model {
  text-align: center;
  width: 100%;
  height: 100%;
  padding-top: 28px;
}

.rsvp-chooseCalender-model-wrapper .chooseCalender-model h5 {
  color: #287fde;
  font-size: 38px;
  margin-bottom: 58px;
  font-weight: bold !important;
}
.rsvp-chooseCalender-model-wrapper .chooseCalender-model p {
  /* border-top: 2px #b4cfec solid; */
  color: #287fde;
  font-size: 15px;
  margin: 0;
  padding: 12px;
  cursor: pointer;
  border: 2.5px solid #287fde;
  border-radius: 5px;
  margin-bottom: 25px;
  font-weight: bold !important;
}
.rsvp-chooseCalender-model-wrapper .cancle-button-wrapper {
  position: absolute;
  bottom: 0;
  height: 32px;
  width: 100%;
}

.rsvp-chooseCalender-model-wrapper .cancle-button-wrapper button {
  border: 0;
  background-color: transparent;
  color: #c14f63;
  font-size: 16px;
}
.rsvp-chooseCalender-model-wrapper .cancle-button-wrapper button:focus {
  background-color: transparent;
}

.box_description {
  transition: all 0.5s ease-in-out;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

.test {
  height: 300px;
  width: 300px;
  background: red;
}

.img-container img {
  max-width: 100%;
  display: block;
  position: relative;
}

.cross-icon {
  position: absolute;
  right: 15px;
  top: 13px;
  border: 2px solid #ffffff;
  opacity: 1;
  border-radius: 50%;
  color: #ffffff;
  cursor: pointer;
  line-height: 77%;
  font-size: 30px;
}

.event-info .event {
  display: flex;
  justify-content: space-around;
}

.event-info {
  padding: 5%;
}

.event-info h4 {
  opacity: 0.6;
}

.event-btn {
  text-align: center;
  padding-top: 8px;
  /* margin-bottom: 5%; */
}

.message {
  font-size: 18px;
}

body {
  font-family: 'Arial', sans-serif;
  background-color: white;
  padding: 20px;
}

.Demo__some-network {
  vertical-align: top;
  display: inline-block;
  margin-right: 10px;
  text-align: center;
}

.Demo__some-network__share-count {
  display: inline-flex;
  justify-content: center;
  white-space: nowrap;
  overflow: visible;
  width: 0;
  margin-top: 3px;
  font-size: 12px;
}

.Demo__some-network__share-button {
  cursor: pointer;
}

.Demo__some-network__share-button:hover:not(:active) {
  opacity: 0.75;
}

.Demo__some-network__custom-icon {
  width: 32px;
  height: 32px;
}

.amenty-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}

.amenty-card {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  background: white;
  margin: 10px;
}

.amenities-info {
  color: black;
  margin-top: 15vh;
}

.amenity-description {
  padding: 15px;
}

.amenity-description__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* medial query enable css >>>>> */
.amenty-image {
  height: 240px;
  width: 100%;
  object-fit: cover;
}

.amenity__arrows {
  width: 35px;
}

@media (min-width: 500px) {
  .amenty-card {
    width: 443px;
    /* ^^ width of card in screens of width greater than 500px, i.e., tabs and desktops. */
  }
}
@media (max-width: 499px) {
  .amenty-image {
    min-width: calc(100vw - 50px);
  }
}
/* <<<< medial query enable css */

body {
  margin: 0;
  padding: 0;
  font-family: 'SFProDisplay-Regular';
}

*,
:after,
:before {
  box-sizing: border-box;
}

a,
button {
  text-decoration: none;
}

@font-face {
  font-family: 'FontsFree-Net-SFProDisplay-Regular';
  src: url(/static/media/FontsFree-Net-SFProDisplay-Regular.c9d4c1c9.eot);
  src: url(/static/media/FontsFree-Net-SFProDisplay-Regular.c9d4c1c9.eot?#iefix)
      format('embedded-opentype'),
    url(/static/media/FontsFree-Net-SFProDisplay-Regular.82ed45b4.woff2) format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFProDisplay-Regular';
  src: url(/static/media/SFProDisplay-Regular.bf6c83d5.svg#SFProDisplay-Regular) format('svg'),
    url(/static/media/SFProDisplay-Regular.379d4eaf.ttf) format('truetype'),
    url(/static/media/SFProDisplay-Regular.252dc797.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FontsFree-Net-SFProDisplay-Bold';
  src: url(/static/media/FontsFree-Net-SFProDisplay-Bold.0ee69510.eot);
  src: url(/static/media/FontsFree-Net-SFProDisplay-Bold.0ee69510.eot?#iefix)
      format('embedded-opentype'),
    url(/static/media/FontsFree-Net-SFProDisplay-Bold.7f048ac3.woff2) format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFProDisplay-Bold';
  src: url(/static/media/SFProDisplay-Bold.5762e2ca.svg#SFProDisplay-Bold) format('svg'),
    url(/static/media/SFProDisplay-Bold.70b5d5cd.ttf) format('truetype'),
    url(/static/media/SFProDisplay-Bold.98c89023.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

h2 {
  font-size: 44px;
  font-family: 'SFProDisplay-Bold';
  color: #ffffff;
  margin-top: 0;
  margin-bottom: 40px;
}

.field-input {
  margin-bottom: 15px;
  width: 100%;
  max-width: 415px;
  display: inline-block;
}

.field-input:last-child {
  margin-bottom: 0;
}

.custom-input {
  position: relative;
  margin: 0 auto;
  max-width: 415px;
  width: 100%;
}

.custom-input .icon-input {
  position: absolute;
  left: 10px;
  top: 44%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.custom-input .input-control {
  padding-left: 40px !important;
  max-width: 415px;
  width: 100%;
}
.navbrand-logo .hamburg-icon {
  cursor: pointer;
}

input.input-control:not([type]),
input.input-control[type='text']:not(.browser-default),
input.input-control[type='password']:not(.browser-default),
input.input-control[type='email']:not(.browser-default),
input.input-control[type='url']:not(.browser-default),
input.input-control[type='time']:not(.browser-default),
input.input-control[type='date']:not(.browser-default),
input[type='datetime']:not(.browser-default),
input.input-control[type='datetime-local']:not(.browser-default),
input.input-control[type='tel']:not(.browser-default),
input.input-control[type='number']:not(.browser-default),
input.input-control[type='search']:not(.browser-default),
textarea.materialize-textarea {
  background-color: #eceef0;
  border: 1px solid #adb1b0;
  height: 53px;
  border-radius: 6px;
  padding: 15px;
  font-size: 18px;
  line-height: 24px;
  color: #adb1b0;
  box-sizing: border-box;
}

.input-control {
  background-color: #eceef0;
  border: 1px solid #adb1b0;
  height: 53px;
  border-radius: 6px;
  padding: 15px;
  font-size: 18px;
  line-height: 24px;
  color: #adb1b0;
  box-sizing: border-box;
}

.btn {
  width: 100%;
  max-width: 94%;
  padding: 10px;
  border-radius: 6px;
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 21px;
  border: 1px solid #ffffff;
  text-transform: uppercase;
  cursor: pointer;
  margin: 0 auto;
  display: block;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
  height: auto;
  box-shadow: none;
}

.dropdown-menu {
  width: 100%;
  max-width: 94%;
}

.dropdown-item {
  font-size: 20px;
  padding: 10px;
  text-align: center;
}

.btn-blue {
  background-color: #287fde;
  color: #ffffff;
}

.btn-white {
  background-color: transparent;
  color: #ffffff;
}

.login-body {
  font-family: 'SFProDisplay-Regular';
  position: relative;
  text-align: center;
  display: flex;
  min-height: 100%;
  height: 100vh;
  background: #ffffff;
}

.sign-up-body {
  flex-direction: row-reverse;
}

.login-body .login-bg {
  background: linear-gradient(146deg, #7cf5ff 0%, #6bb4e5 27%, #3d009f 100%);
  width: 40%;
  text-align: left;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
}

.welcome {
  background: linear-gradient(146deg, #7cf5ff 0%, #6bb4e5 27%, #3d009f 100%);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  color: white;
  font-size: x-large;
  font-family: 'SFProDisplay-Regular';
}

.login-body .login-bg .login-bg-content {
  max-width: 480px;
  text-align: center;
}

.login-body .login-bg .login-bg-content p {
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  margin-bottom: 80px;
}

.login-body .login-content {
  display: flex;
  padding-top: 120px;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 40px;
  overflow-y: auto;
  position: relative;
}

.navbar-brand-logo {
  margin: 0;
  position: absolute;
  left: 40px;
  top: 40px;
  z-index: 1;
}

.navbar-brand-logo .logo {
  display: inline-block;
}

.navbar-brand-logo .logo img {
  max-width: 207px;
  width: 100%;
}

.login-body .login-content .login-content-inner {
  max-width: 480px;
}

.login-body .login-content .login-content-inner p {
  margin-top: 0;
  margin-bottom: 45px;
  font-size: 18px;
  line-height: 24px;
  color: #282c31;
}

.login-body .login-content .login-content-inner .forgot-password {
  font-size: 18px;
  line-height: 24px;
  color: #282c31;
  margin-top: 45px;
  display: inline-block;
  width: 100%;
  text-decoration: underline;
}

.login-body .login-content .login-content-inner .btn {
  margin-top: 50px;
}

.social-icon-group {
  margin-bottom: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-icon-group a {
  width: 53px;
  height: 54px;
  border-radius: 6px;
  border: 1px solid #adb1b0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 20px;
}

.social-icon-group a img {
  max-width: 27px;
  width: 100%;
}

.login-content-inner h2 {
  color: #287fde;
}

/*========Responsive===========*/

@media screen and (max-width: 991px) {
  h2 {
    font-size: 40px;
    margin-bottom: 30px;
  }
  .navbar-brand-logo .logo img {
    max-width: 180px;
  }
  .social-icon-group {
    margin-bottom: 30px;
  }
  .login-body .login-content .login-content-inner p {
    margin-bottom: 30px;
  }
  .login-body .login-content .login-content-inner .forgot-password {
    margin-top: 30px;
  }
  .login-body .login-content .login-content-inner .btn {
    margin-top: 30px;
  }
  .login-body .login-bg .login-bg-content p {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 767px) {
  h2 {
    font-size: 26px;
    margin-bottom: 25px;
  }
  .input-control {
    height: 47px;
    font-size: 15px;
  }
  .btn {
    max-width: 94%;
    padding: 15px;
    font-size: 17px;
    line-height: 17px;
  }
  .login-body {
    flex-wrap: wrap;
    height: auto;
    min-height: unset;
  }
  .login-bg .login-content {
    width: 100%;
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .login-body .login-bg {
    width: 100%;
  }
  .navbar-brand-logo {
    position: relative;
  }
  .navbar-brand-logo .logo img {
    max-width: 150px;
  }
  .login-body .login-content .login-content-inner {
    max-width: 100%;
  }
  .login-body .login-content .login-content-inner p {
    margin-bottom: 25px;
  }
  .social-icon-group {
    margin-bottom: 25px;
  }
  .social-icon-group a {
    width: 41px;
    height: 42px;
    margin: 0 15px;
  }
  .social-icon-group a img {
    max-width: 21px;
  }
  .login-body .login-content .login-content-inner p {
    font-size: 15px;
  }
  .login-body .login-content .login-content-inner .btn {
    margin-top: 5px;
  }
  .login-body .login-bg .login-bg-content p {
    display: none;
  }
  .login-body .login-bg .login-bg-content {
    max-width: 100%;
  }
  .sign-up-body {
    flex-direction: row;
  }
  /* Login Screen Css Start */
  .login-body .login-content {
    margin-top: 60px;
  }
  .login-body .login-content .login-content-inner h2 {
    margin-bottom: 25px;
  }
  .login-body .login-content .login-content-inner form {
    padding: 0px;
    margin-top: 0px;
  }
  /* Login Screen Css End */
}

body {
  margin: 0;
  padding: 0;
  font-family: 'SFProDisplay-Regular';
  background-color: #f4f4f4;
}

*,
:after,
:before {
  box-sizing: border-box;
}

a,
button {
  text-decoration: none;
}

@font-face {
  font-family: 'FontsFree-Net-SFProDisplay-Regular';
  src: url(/static/media/FontsFree-Net-SFProDisplay-Regular.c9d4c1c9.eot);
  src: url(/static/media/FontsFree-Net-SFProDisplay-Regular.c9d4c1c9.eot?#iefix)
      format('embedded-opentype'),
    url(/static/media/FontsFree-Net-SFProDisplay-Regular.82ed45b4.woff2)
      format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFProDisplay-Regular';
  src: url(/static/media/SFProDisplay-Regular.bf6c83d5.svg#SFProDisplay-Regular)
      format('svg'),
    url(/static/media/SFProDisplay-Regular.379d4eaf.ttf) format('truetype'),
    url('/fonts/SFProDisplay-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FontsFree-Net-SFProDisplay-Bold';
  src: url(/static/media/FontsFree-Net-SFProDisplay-Bold.0ee69510.eot);
  src: url(/static/media/FontsFree-Net-SFProDisplay-Bold.0ee69510.eot?#iefix)
      format('embedded-opentype'),
    url(/static/media/FontsFree-Net-SFProDisplay-Bold.7f048ac3.woff2) format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFProDisplay-Bold';
  src: url(/static/media/SFProDisplay-Bold.5762e2ca.svg#SFProDisplay-Bold)
      format('svg'),
    url(/static/media/SFProDisplay-Bold.70b5d5cd.ttf) format('truetype'),
    url(/static/media/SFProDisplay-Bold.98c89023.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

/**/

h5 {
  font-family: 'SFProDisplay-Regular';
  color: #2d333e;
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 40px;
}

.explore_main-wrap {
  margin-left: 0;
  padding: 20px;
  -webkit-animation: bodyslideout 300ms forwards;
  animation: bodyslideout 300ms forwards;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  position: relative;
  min-height: 100%;
  padding-top: 100px;
}

.explore-layout-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* padding-top: 80px !important; */
  /* margin-left: 15px; */
  /* margin-top: 15px; */

  /* margin-left: 10px !important;
  margin-right: 10px !important; */
}

.open-sidebar .explore_main-wrap {
  margin-left: 240px !important;
  -webkit-animation: bodyslidein 300ms forwards;
  animation: bodyslidein 300ms forwards;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

/* Slide in animation */
@-webkit-keyframes slidein {
  0% {
    left: -240px;
  }
  100% {
    left: 0;
  }
}
@keyframes slidein {
  0% {
    left: -240px;
  }
  100% {
    left: 0;
  }
}
@-webkit-keyframes slideout {
  0% {
    left: 0;
  }
  100% {
    left: -240px;
  }
}
@keyframes slideout {
  0% {
    left: 0;
  }
  100% {
    left: -240px;
  }
}
@-webkit-keyframes bodyslidein {
  0% {
    left: 0;
  }
  100% {
    left: 0;
  }
}
@keyframes bodyslidein {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: 240px;
  }
}
@-webkit-keyframes bodyslideout {
  0% {
    margin-left: 240px;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes bodyslideout {
  0% {
    margin-left: 240px;
  }
  100% {
    margin-left: 0;
  }
}

.player-detail {
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding-bottom: 15px;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.player-detail a {
  border-radius: 12px;
  background-color: #2d333e;
  color: #ffffff;
  font-size: 16px;
  padding: 9px 27px;
  margin-right: 10px;
}
.player-detail a.disabled {
  background-color: #dddddd;
}

.player-detail .player-detail-inner {
  display: flex;
}

.add-player-detail-button .modal-toggle {
  width: 32px;
  height: 32px;
  background-color: #2d333e;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 0;
  cursor: pointer;
  margin-right: 15px;
}

.add-player-detail-button .btn-back {
  width: 32px;
  height: 32px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 0;
  cursor: pointer;
  margin-right: 15px;
}

.add-player-detail-button .modal-toggle-close,
.add-player-detail-button .modal-toggle-close img {
  /* width: 32px;
  height: 32px;
  background-color: gainsboro;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
  border-radius: 50%;
  border-color: #000000;
  border: 0;
  cursor: pointer; */

  width: 32px;
  height: 32px;
  top: 0px !important;
  right: 0px !important;
  padding-left: 0px;
  padding-top: 0px;
  /* padding: 1em; */
  color: #aaa;
  background: none;
  border: 0;
  cursor: pointer;
  object-fit: cover;
}

.modal-toggle-close:focus {
  outline: none;
}

.add-player-detail-button .modal-toggle-search {
  width: 32px;
  height: 32px;
  background-color: gainsboro;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  border-radius: 50%;
  border-color: #000000;
  border: 0;
  cursor: pointer;
}
.modal-toggle-search:focus {
  outline: none;
}

.add-player-detail-button .modal-toggle:focus {
  outline: none;
}

.add-player-detail-button .modal-toggle span {
  line-height: 1;
}

.add-player-detail-button .modal-toggle span img {
  width: 18px;
}

.search-space-div {
  width: 0px;
  height: 55px;
  background-color: #ffffff;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  /* margin-left: 15px; */
}

.input-form {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.input-form-group {
  display: inline-block;
  width: 100%;
}

.input-form-group-desc {
  display: inline-block;
  width: 100%;
  margin-bottom: 15px;
}

.input-form-group .form-input {
  width: 100%;
  padding: 8px 12px;
  /* padding: 8px 20px !important; */
  box-sizing: border-box;
  font-size: 16px;
  /* font-size: 22px !important; */
  border-radius: 4px;
  border: 1px solid #000000 !important;
  color: #6c6c6c;
  height: 64px !important;
}

.input-form-group-desc .form-input {
  width: 100%;
  /* padding: 8px 12px; */
  padding: 8px 20px !important;
  box-sizing: border-box;
  /* font-size: 16px; */
  font-size: 22px !important;
  border-radius: 4px;
  border: 1px solid #000000 !important;
  color: #6c6c6c;
  height: 64px !important;
}

.input-form-group-central-div {
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Drop down */
/* .css-yk16xz-control {
  background-color: #fff;
  border-color: #6c6c6c !important;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
}
.css-1okebmr-indicatorSeparator {
  background-color: #6c6c6c !important;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  width: 1px !important;
  box-sizing: none !important;
  border-width: 10px !important;
}
.css-1hb7zxy-IndicatorsContainer,
.css-1wy0on6 {
  visibility: hidden;
}

.css-1pahdxg-control, 
.css-1pahdxg-control :hover {
  border-color: #6c6c6c !important;
  box-shadow: none !important;
  outline: none !important;
}

.css-b8ldur-Input {
  margin: 2px;
  padding-bottom: 2px;
  padding-top: 2px;
  visibility: visible;
  font-size: 16px !important;
  color: #6c6c6c !important;
  box-sizing: border-box;
}
.css-1uccc91-singleValue {
  color: #6c6c6c !important;
  margin-left: 2px;
  margin-right: 2px;
  font-size: 16px !important;
  max-width: calc(100% - 8px);
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  white-space: nowrap;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  box-sizing: border-box;
}

.css-1hwfws3 {
  color: #6c6c6c;
  margin-left: 0px !important;
  margin-right: 0px !important;
  position: absolute;
  box-sizing: border-box;
  font-size: 16px;
  padding: 2px 22px !important;
  height: 67px;
}

.css-g1d714-ValueContainer {
  padding: 2px 23px !important;
  border: none;
  margin-bottom: 0;

  color: #6c6c6c;
  box-shadow: none;
  background-color: white !important;
  border-bottom: none;
  font-size: 22px !important;
  font-family: "SFProDisplay-Regular";
  font-weight: 300;
  letter-spacing: 0;
  border-radius: 4px;
  width: -webkit-fill-available;
}

.css-1wa3eu0-placeholder {
  color: #6c6c6c;
  margin-left: -15px !important;
  margin-right: 0px !important;
  position: absolute;
  box-sizing: border-box;
  font-size: 16px;
  padding: 15px 15px !important;
  opacity: 0.5;
} */
/* Drop down */

/* Modals Start */

.modal {
  position: absolute;
  z-index: 10000; /* 1 */
  top: 0;
  left: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
}

.modal.is-visible {
  visibility: visible;
}

.modal-overlay {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: hsla(0, 0%, 0%, 0.5);
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.3s, opacity 0.3s;
}

.modal.is-visible .modal-overlay {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
}

.modal-wrapper {
  position: absolute;
  z-index: 9999;
  top: 6em;
  left: 50%;
  width: 32em;
  margin-left: -16em;
  background-color: #fff;
  box-shadow: 0 0 1.5em hsla(0, 0%, 0%, 0.35);
}

.modal-transition {
  transition: all 0.3s 0.12s;
  -webkit-transform: translateY(-10%);
          transform: translateY(-10%);
  opacity: 0;
}

.modal.is-visible .modal-transition {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1;
}

.modal-header,
.modal-content {
  padding: 1em;
}

.modal-header {
  position: relative;
  background-color: #fff;
  box-shadow: 0 1px 2px hsla(0, 0%, 0%, 0.06);
  border-bottom: 1px solid #e8e8e8;
}

.modal-close,
.modal-close-blue {
  position: absolute;
  top: -10px !important;
  right: 0px !important;
  padding: 1em;
  color: #aaa;
  background: none;
  border: 0;
  cursor: pointer;
}

.modal-close:focus,
.modal-close-blue:focus {
  outline: none;
}

.modal-heading {
  /* font-size: 1.125em;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */

  background-color: transparent;
  text-align: left;
  margin-top: 25px;
  margin-bottom: 10px;
  margin-right: -5px;
  padding-right: 40px;
  border-bottom: 1px solid #e5e5e5;

  padding-bottom: 5px;
  text-align: left;
  font-size: 23px;
  /* font-weight: bold; */
  font-family: 'SFProDisplay-Bold';
  letter-spacing: 0px;
  color: #6c6c6c !important;
}

.modal-content > *:first-child {
  margin-top: 0;
}

.modal-content > *:last-child {
  margin-bottom: 0;
}

/*Modal End*/

.home-content-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.home-content-col {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  flex: 0 0 50%;
  max-width: 50%;
}

.card-box-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.card-box-col {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  flex: 0 0 50%;
  max-width: 50%;
  margin-bottom: 20px;
}

.card-box {
  padding: 24px;
  background-color: #ffffff;
  border-radius: 19px;
  border: 1px solid #dddddd;
  height: 100%;
}

.card-box .progressbar-sec {
  margin-bottom: 60px;
}

.graph-content {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
}
.graph-content img {
  max-width: 100%;
}

.graph-content p {
  font-size: 14px;
  color: #2d333e;
  margin-bottom: 0;
  margin-top: 34px;
}
.graph-content p a {
  color: #287fde;
  text-decoration: underline;
}

.graph-content .graph-img {
  width: 100%;
}

.detail-card-box {
  border-radius: 19px;
  border: 1px solid #dddddd;
  background-color: #ffffff;
  padding: 17px 33px 17px 17px;
  margin-bottom: 10px;
}
.card-box-inner {
  display: flex;
  align-items: center;
}

.card-box-icon {
  width: 40px;
  height: 40px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-box-icon img {
  max-width: 40px;
}

.card-box-content {
  width: calc(100% - 40px);
  padding-left: 23px;
}

.card-box-content .title {
  color: #2d333e;
  font-size: 14px;
  margin-bottom: 5px;
  display: inline-block;
  font-family: 'SFProDisplay-Bold';
}

.classes-percentage {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.classes-percentage span {
  color: #2d333e;
  font-size: 14px;
}

.searchbar-input-div {
  /* padding-bottom: 5px;
  padding-top: 5px; */
  padding-left: 5px;
  padding-right: 5px;
  width: 100%;
  max-width: 400px;
  /* background-color: gainsboro;
  border-radius: 10px;
  border: 1px;
  border-color: gainsboro; */
}

.searchbar-input {
  position: relative;
  width: 100%;
  max-width: 100%;
  padding-left: 20px;
  margin-left: 20px;
  flex: 1 1;
  flex-direction: row;
}

/* .searchbar-input:focus {
  border-color: ghostwhite;
  box-shadow: none;
} */

.viewmore-btn-outer {
  text-align: right;
  display: inline-block;
  width: 100%;
  margin-top: 5px;
}

.viewmore-btn {
  font-size: 16px;
  background-color: #2d333e;
  color: #ffffff;
  padding: 10px;
  border-radius: 6px;
  border: 0;
}

.publish-btn {
  font-size: 15px;
  background-color: #287fde;
  color: #ffffff;
  padding: 7px 10.5px;
  border-radius: 6px;
  border: 0;
  /* margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 5px; */
  margin: 1%;
}
.publish-btn:focus {
  background-color: #287fde;
  outline: none;
}
.dlt-btn {
  background-color: rgb(211, 27, 27);
}

.dlt-btn:focus {
  background-color: rgb(211, 27, 27);
  outline: none;
}

.edit-btn {
  font-size: 16px;
  background-color: #287fde;
  color: #ffffff;
  padding: 10px;
  border-radius: 6px;
  border: 0;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 15px;
}

.card-box-outer-scroll {
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
}

.recommend-box {
  border-radius: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.recommned-content {
  width: calc(100% - 122px);
  padding: 22px 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.recommned-content p {
  margin: 0;
  color: #ffffff;
  font-size: 15px;
  line-height: 22px;
  padding-right: 48px;
}

.btn-main {
  padding: 15px;
  font-size: 18px;
  background-color: #000;
  color: #ffffff;
}

.recommend-img {
  width: 122px;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: cover;
  display: flex;
  height: 90px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.progressbar-sec {
  margin-top: 13px;
}

.progressbar-sec .text {
  font-size: 15px;
  color: #2d333e;
  margin-top: 15px;
  display: inline-block;
  width: 100%;
}

.skillBarContainer {
  width: 100%;
  height: 5px;
  background: #dddddd;
  overflow: hidden;
  border-radius: 5px;
}

.skillBarContainer-md {
  height: 12px;
}

.skillBarValue {
  height: 5px;
  border-radius: 5px;
  background: #ef5180;
}

.skillBarValue-md {
  height: 12px;
}

.color-pink {
  background-color: #ef5180;
}
.color-yellow {
  background-color: #f3a611;
}
.color-red {
  background-color: #dc3537;
}
.color-purple {
  background-color: #976aff;
}
.color-skyblue {
  background-color: #35bfd8;
}
.color-green {
  background-color: #64f6d3;
}

/* Allowed values for the css skill bars */
.value-00 {
  width: 0;
}
.value-1 {
  width: 1%;
}
.value-2 {
  width: 2%;
}
.value-3 {
  width: 3%;
}
.value-4 {
  width: 4%;
}
.value-5 {
  width: 5%;
}
.value-6 {
  width: 6%;
}
.value-7 {
  width: 7%;
}
.value-8 {
  width: 8%;
}
.value-9 {
  width: 9%;
}
.value-10 {
  width: 10%;
}
.value-11 {
  width: 11%;
}
.value-12 {
  width: 12%;
}
.value-13 {
  width: 13%;
}
.value-14 {
  width: 14%;
}
.value-15 {
  width: 15%;
}
.value-16 {
  width: 16%;
}
.value-17 {
  width: 17%;
}
.value-18 {
  width: 18%;
}
.value-19 {
  width: 19%;
}
.value-20 {
  width: 20%;
}
.value-21 {
  width: 21%;
}
.value-22 {
  width: 22%;
}
.value-23 {
  width: 23%;
}
.value-24 {
  width: 24%;
}
.value-25 {
  width: 25%;
}
.value-26 {
  width: 26%;
}
.value-27 {
  width: 27%;
}
.value-28 {
  width: 28%;
}
.value-29 {
  width: 29%;
}
.value-30 {
  width: 30%;
}
.value-31 {
  width: 31%;
}
.value-32 {
  width: 32%;
}
.value-33 {
  width: 33%;
}
.value-34 {
  width: 34%;
}
.value-35 {
  width: 35%;
}
.value-36 {
  width: 36%;
}
.value-37 {
  width: 37%;
}
.value-38 {
  width: 38%;
}
.value-39 {
  width: 39%;
}
.value-40 {
  width: 40%;
}
.value-41 {
  width: 41%;
}
.value-42 {
  width: 42%;
}
.value-43 {
  width: 43%;
}
.value-44 {
  width: 44%;
}
.value-45 {
  width: 45%;
}
.value-46 {
  width: 46%;
}
.value-47 {
  width: 47%;
}
.value-48 {
  width: 48%;
}
.value-49 {
  width: 49%;
}
.value-50 {
  width: 50%;
}
.value-51 {
  width: 51%;
}
.value-52 {
  width: 52%;
}
.value-53 {
  width: 53%;
}
.value-54 {
  width: 54%;
}
.value-55 {
  width: 55%;
}
.value-56 {
  width: 56%;
}
.value-57 {
  width: 57%;
}
.value-58 {
  width: 58%;
}
.value-59 {
  width: 59%;
}
.value-60 {
  width: 60%;
}
.value-61 {
  width: 61%;
}
.value-62 {
  width: 62%;
}
.value-63 {
  width: 63%;
}
.value-64 {
  width: 64%;
}
.value-65 {
  width: 65%;
}
.value-66 {
  width: 66%;
}
.value-67 {
  width: 67%;
}
.value-68 {
  width: 68%;
}
.value-69 {
  width: 69%;
}
.value-70 {
  width: 70%;
}
.value-71 {
  width: 71%;
}
.value-72 {
  width: 72%;
}
.value-73 {
  width: 73%;
}
.value-74 {
  width: 74%;
}
.value-75 {
  width: 75%;
}
.value-76 {
  width: 76%;
}
.value-77 {
  width: 77%;
}
.value-78 {
  width: 78%;
}
.value-79 {
  width: 79%;
}
.value-80 {
  width: 80%;
}
.value-81 {
  width: 81%;
}
.value-82 {
  width: 82%;
}
.value-83 {
  width: 83%;
}
.value-84 {
  width: 84%;
}
.value-85 {
  width: 85%;
}
.value-86 {
  width: 86%;
}
.value-87 {
  width: 87%;
}
.value-88 {
  width: 88%;
}
.value-89 {
  width: 89%;
}
.value-90 {
  width: 90%;
}
.value-91 {
  width: 91%;
}
.value-92 {
  width: 92%;
}
.value-93 {
  width: 93%;
}
.value-94 {
  width: 94%;
}
.value-95 {
  width: 95%;
}
.value-96 {
  width: 96%;
}
.value-97 {
  width: 97%;
}
.value-98 {
  width: 98%;
}
.value-99 {
  width: 99%;
}
.value-100 {
  width: 100%;
}

/*///////////////////////////////////////////////////
    // Animation \\ 
///////////////////////////////////////////////////*/
@-webkit-keyframes slideIn {
  0% {
    width: 0;
  }
  25% {
    width: 100%;
  }
  100% {
    width: normal;
  }
}
@keyframes slideIn {
  0% {
    width: 0;
  }
  25% {
    width: 100%;
  }
  100% {
    width: normal;
  }
}
.skillBarValue {
  -webkit-animation: slideIn 2s;
  animation: slideIn 2s;
}

/*=============== Event Page Css Start ===============*/
.explore_views_row .explore_box_row .box-bg {
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
  background-color: #fff;
  border-radius: 20px 20px 20px 20px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.1);

  width: 380px !important;
  /* height: 240px !important; */
}
.explore_views_row .explore_box_list_item {
  position: relative;
  width: 100%;
}
.explore_views_row
  .explore_box_list_item
  .explore_box_image
  img.explore_thumb-img {
  transition: all 0.4s ease-in-out;
  border-radius: 20px 20px 0px 0px;
  width: 100%;
  max-width: 100%;
  height: 250px;
  /* height: calc(width/2); */
  object-fit: cover;
}

.explore_views_row .explore_box_list_item .explore_box_image {
  position: relative;
}

.MuiBackdrop-root {
  z-index: 99999 !important;
}
.MuiCircularProgress-indeterminate {
  z-index: 99999;
}

.explore-image-container {
  position: relative;
  text-align: center;
  color: white;
}
.explore_views_row
  .explore_box_list_item
  .explore_box_image
  .explore_play_icon {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  /* background-color: white; */
}

.explore_views_row
  .explore_box_list_item
  .explore_box_image
  .explore_play_icon
  img {
  max-width: 50px;
}

.explore_views_row .explore_box_list_item h5 {
  display: inline-block;
  width: 80%;
  padding-left: 20px;
  margin-bottom: 0;
  transition: all 0.4s ease-in-out;
}
.explore_box_row .explore_box_list_item .explore_category_text {
  padding: 10px 15px 15px 15px;
}
.explore_views_row .explore_box_row .box_description {
  padding: 15px;
  display: none;
  color: #000;
  font-size: 14px;
  line-height: 20px;
}
.explore_box_row .explore_d-flex-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.explore_box_row .explore_d-flex-box-left {
  display: flex;
  align-items: center;
  justify-content: left;
}

.explore_box_row .explore_box_list_item .explore_main-title {
  margin-top: 5px;
}
.explore_box_row .explore_box_list_item .explore_category_text span {
  color: #626366;
  font-size: 14px;
}

.explore_box_row h4 {
  margin: 0px;
  color: #626366;
  font-size: 22px;
  font-weight: 400;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.explore_box_row h6 {
  margin: 0px;
  margin-left: 0px !important;
  color: #626366;
  font-size: 19px;
  font-weight: 400;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.explore_box_row p {
  color: #626366;
  font-size: 12px;
  margin-top: 8px;
  margin-bottom: 0px;
}
.explore_box_row .box_description .col-desc p a {
  font-size: 12px;
  color: #287fde;
}
.explore_box_row .explore_box_list_item .explore_main-title img {
  margin-right: 10px;
}
.explore_box_row .br-btm {
  border-bottom: 1px solid #e5e5e5;
  padding: 12px 0px;
}
/*=============== Explore Page Css End ===============*/

/* Tab Buttons */
.tab-nav-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 15px 0 25px;
}
.tab-nav-wrap.nav-pills .nav-link {
  border-radius: 7px;
  padding: 5px 10px;
  min-width: 120px;
  text-align: center;
  color: #2d333e;
}
.tab-nav-wrap.nav-pills .nav-link.active {
  color: #ffffff;
  background-color: #287fde;
}

/*========Responsive===========*/
@media screen and (max-width: 1440px) {
  /* .open-sidebar
    .explore_views_row
    .explore_box_list_item
    .explore_box_image
    img.explore_thumb-img {
    height: 150px;
  }
  .explore_views_row
    .explore_box_list_item
    .explore_box_image
    img.explore_thumb-img {
    height: 250px;
  } */
}

@media screen and (max-width: 1024px) {
  /* .explore_views_row
    .explore_box_list_item
    .explore_box_image
    img.explore_thumb-img {
    height: 200px;
  }
  .open-sidebar
    .explore_views_row
    .explore_box_list_item
    .explore_box_image
    img.explore_thumb-img {
    height: 150px;
  } */
  /* .explore_box_row h4 {
    font-size: 14px;
  } */
  /* .publish-btn {
    font-size: 14px;
    padding: 5px 10px;
  } */
}

@media screen and (max-width: 991px) {
  .home-content-col {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .desc-detail {
    margin-top: 10px;
  }
  .recommned-content p {
    padding-right: 20px;
  }
}

@media screen and (max-width: 767px) {
  .card-box-col {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .graph-sec .card-box-col {
    margin-bottom: 10px;
  }
  .graph-sec .card-box-col:last-child {
    margin-bottom: 0;
  }
  .navbar-collapse form input[type='text']:focus {
    width: 230px;
  }
  .recommned-content {
    padding: 10px;
  }
  /* .explore_views_row
    .explore_box_list_item
    .explore_box_image
    img.explore_thumb-img {
    height: 100%;
  } */
}

@media screen and (max-width: 500px) {
  /* .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: 5px;
    flex-direction: column;
  } */
  .explore_views_row .explore_box_row .box-bg {
    width: 100% !important;
    /* max-width: 80%; */
    /* height: 240px; */
    margin-left: 10px;
    margin-right: 10px;
  }
  .explore-layout-wrapper {
    display: inherit;
    flex-wrap: initial;
    /* padding-top: 100px !important; */

    margin-left: 0px !important;
    margin-right: 10px !important;
  }

  /* .tab-nav-wrap {
    font-size: 13.5px;
    margin: 5px 0 20px;
  }
  .tab-nav-wrap.nav-pills .nav-link {
    border-radius: 6px;
    padding: 5px 5px;
    min-width: 100px;
  } */
}

body {
  margin: 0;
  padding: 0;
  font-family: 'SFProDisplay-Regular';
}

*,
:after,
:before {
  box-sizing: border-box;
}

a,
button {
  text-decoration: none;
}

@font-face {
  font-family: 'FontsFree-Net-SFProDisplay-Regular';
  src: url(/static/media/FontsFree-Net-SFProDisplay-Regular.c9d4c1c9.eot);
  src: url(/static/media/FontsFree-Net-SFProDisplay-Regular.c9d4c1c9.eot?#iefix)
      format('embedded-opentype'),
    url(/static/media/FontsFree-Net-SFProDisplay-Regular.82ed45b4.woff2)
      format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFProDisplay-Regular';
  src: url(/static/media/SFProDisplay-Regular.bf6c83d5.svg#SFProDisplay-Regular)
      format('svg'),
    url(/static/media/SFProDisplay-Regular.379d4eaf.ttf) format('truetype'),
    url(/static/media/SFProDisplay-Regular.252dc797.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FontsFree-Net-SFProDisplay-Bold';
  src: url(/static/media/FontsFree-Net-SFProDisplay-Bold.0ee69510.eot);
  src: url(/static/media/FontsFree-Net-SFProDisplay-Bold.0ee69510.eot?#iefix)
      format('embedded-opentype'),
    url(/static/media/FontsFree-Net-SFProDisplay-Bold.7f048ac3.woff2)
      format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFProDisplay-Bold';
  src: url(/static/media/SFProDisplay-Bold.5762e2ca.svg#SFProDisplay-Bold)
      format('svg'),
    url(/static/media/SFProDisplay-Bold.70b5d5cd.ttf) format('truetype'),
    url(/static/media/SFProDisplay-Bold.98c89023.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

/* Login Screen Css Start */
.logo-header a.logo img {
  /* padding-left: 85px;
  padding-top: 70px; */
  padding-left: 5px;
  padding-top: 20px;
}

.login-back-img {
  width: 100%;
  height: 100vh;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(/static/media/login-back.334b2abf.png);
  position: relative;
}

.email-confirmed-back-img {
  width: 100%;
  height: 100vh;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(/static/media/confirmed-back.f24d77f6.png);
  position: relative;
}

.loginpass-back-img {
  width: 100%;
  height: 100vh;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(/static/media/login-screen-back.66b5c7d8.png);
  position: relative;
}

.modal-layout {
  width: 740px;
  max-width: 90%;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #6c6c6c5c;
  border-radius: 46px;
  padding: 70px 70px;
}
.modal-layout h2 {
  color: #287fde;
  font-size: 55px;
  letter-spacing: 0;
  font-family: 'SFProDisplay-Bold';
  font-weight: 400;
  margin-bottom: 50px;
  text-align: center;
}
.mb-50 {
  margin-bottom: 50px;
}

.modal-layout .email-input {
  border: 1px solid #6c6c6c;
  border-radius: 4px;
  font-size: 31px;
  font-family: 'SFProDisplay-Light';
  /* opacity: 0.5; */
}

.modal-layout input.form-control,
.modal-layout select.form-control {
  padding: 10px 25px;
  border: none;
  margin-bottom: 0;

  color: #6c6c6c;
  background-color: white;
  border-bottom: none;
  font-size: 22px;
  font-family: 'SFProDisplay-Regular';
  font-weight: 300;
  letter-spacing: 0;
  background-color: #fff !important;
  border-radius: 4px;
  width: -webkit-fill-available;
}

.modal-layout input.form-control:focus {
  border-bottom: none !important;
}

.modal-layout input.form-control::-webkit-input-placeholder, .css-1uccc91-singleValue::-webkit-input-placeholder {
  opacity: 0.5;
}

.modal-layout input.form-control::placeholder,
.css-1uccc91-singleValue::placeholder {
  opacity: 0.5;
}

.modal-layout p {
  color: #6c6c6c;
  font-size: 22px;
  font-family: 'SFProDisplay-Regular';
  font-weight: 400;
  letter-spacing: 0;
  margin: 30px 0 70px 0;
  text-align: left;
}

/* Drop down */
.css-yk16xz-control {
  background-color: #fff;
  border-color: #6c6c6c !important;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
}
.css-1okebmr-indicatorSeparator {
  background-color: #6c6c6c !important;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  width: 1px !important;
  box-sizing: none !important;
  border-width: 10px !important;
}
.css-1hb7zxy-IndicatorsContainer,
.css-1wy0on6 {
  visibility: hidden;
}

.css-1pahdxg-control,
.css-1pahdxg-control :hover {
  border-color: #6c6c6c !important;
  box-shadow: none !important;
  outline: none !important;
}

.css-b8ldur-Input {
  margin: 2px;
  padding-bottom: 2px;
  padding-top: 2px;
  visibility: visible;
  font-size: 22px !important;
  color: #6c6c6c !important;
  box-sizing: border-box;
}
.css-1uccc91-singleValue {
  color: #6c6c6c !important;
  margin-left: 2px;
  margin-right: 2px;
  font-size: 22px !important;
  max-width: calc(100% - 8px);
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  white-space: nowrap;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  box-sizing: border-box;
  /* padding: 2px 15px !important; */
}

.css-1hwfws3 {
  color: #6c6c6c;
  margin-left: 0px !important;
  margin-right: 0px !important;
  position: absolute;
  box-sizing: border-box;
  font-size: 22px;
  padding: 2px 22px !important;
  height: 67px;
}

.css-g1d714-ValueContainer {
  padding: 2px 23px !important;
  border: none;
  margin-bottom: 0;

  color: #6c6c6c;
  box-shadow: none;
  background-color: white !important;
  border-bottom: none;
  font-size: 22px !important;
  font-family: 'SFProDisplay-Regular';
  font-weight: 300;
  letter-spacing: 0;
  border-radius: 4px;
  width: -webkit-fill-available;
}

.css-1wa3eu0-placeholder {
  color: #6c6c6c;
  margin-left: -15px !important;
  margin-right: 0px !important;
  position: absolute;
  box-sizing: border-box;
  font-size: 22px;
  padding: 15px 15px !important;
  /* height: 67px; */
  opacity: 0.5;
}
/* Drop down */

.btn-next a {
  background: #287fde 0% 0% no-repeat padding-box;
  border: 1px solid #287fde;
  border-radius: 6px;
  padding: 20px;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'SFProDisplay-Bold';
  font-weight: 400;
  text-decoration: none;
  cursor: pointer;
  width: 100%;
  display: block;
  text-align: center;
  margin-bottom: 30px;
}
.btn-next a:hover {
  opacity: 0.9;
  color: #fff;
}

.link-info a {
  letter-spacing: 0px;
  color: #287fde;
  font-size: 22px;
  font-family: 'SFProDisplay-Regular';
  font-weight: 400;
  text-decoration: underline;
  text-align: left;
  border-bottom: 1px;
}

.link-info span {
  color: #6c6c6c;
  font-size: 22px;
  font-family: 'SFProDisplay';
  font-weight: 400;
}
h2.mb-30 {
  margin-bottom: 30px;
}

.forgot-pass {
  color: #287fde;
  font-size: 22px;
  font-family: 'SFProDisplay';
  font-weight: 400;
  text-decoration: none;
}
.forgot-pass:hover {
  color: #287fde;
  text-decoration: none;
}

/*========Responsive===========*/

@media screen and (max-width: 991px) {
  /* .modal-layout {
    padding: 50px 30px;
    width: 650px;
  }
  .modal-layout h2 {
    margin-bottom: 30px;
  }
  .modal-layout p {
    margin-bottom: 30px;
  }
  .btn-next a {
    padding: 10px;
    margin-bottom: 30px;
  } */

  /* .modal-layout h2 {
    font-size: 30px;
  }
  .modal-layout p {
    font-size: 16px;
  }
  .modal-layout input.form-control,
  .css-g1d714-ValueContainer {
    font-size: 16px;
    padding-left: 10px !important;
  }
  .css-g1d714-ValueContainer {
    font-size: 16px;
    padding: 2px 10px !important;
  } */
}

@media screen and (max-width: 768px) {
  .modal-layout h2 {
    font-size: 30px;
  }
  .modal-layout p {
    font-size: 16px;
  }
  .modal-layout input.form-control,
  .css-g1d714-ValueContainer,
  .css-1hwfws3 {
    font-size: 16px;
    padding-left: 10px !important;
  }
  .css-g1d714-ValueContainer {
    font-size: 16px;
    /* padding: 2px 10px !important; */
  }
  .css-1wa3eu0-placeholder,
  .css-1uccc91-singleValue {
    font-size: 16px !important;
    /* padding: 2px 2px !important; */
  }

  .btn-next a {
    font-size: 16px;
    padding: 10px;
    margin-bottom: 20px;
  }
  .modal-layout p {
    margin-bottom: 20px;
  }
  .link-info a {
    font-size: 16px;
  }
  .logo-header a.logo img {
    max-width: 144px;
    padding-left: 0;
  }
  .link-info span {
    font-size: 16px;
  }
  /* .xs-text-center {
    text-align: center;
  } */
}

/* Login Screen Css End */

.box_list_item.card-wrap img {
  height: 275px ;
}
#alert-cancel-modal  {
  margin-top: 10rem !important;
  /* margin-left: 23rem !important;  */
}

.invite-modal {
  width: 100%;
  height: 100%;
}

.invite-modal-close {
  position: absolute;
  top: 0;
  right: 0;
  /* padding: 1em; */
  color: black;
  background: none;
  border: 0;
  cursor: pointer;
  padding: 20px 10px 10px 10px !important;
}
#invite-close-icon {
  margin-bottom: 2rem;
  margin-left: 12rem;
}
.react-calendar {
  position: absolute;
  left: 11%;
  top: 5%;
}

.date-time-box .event-date {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-top: 1rem;
}

.date-time-box .event-date .modal-arrow {
  position: relative;
  cursor: pointer;
  /* padding: 10px !important; */
  border: none;
  background: transparent;
  margin: 0px 45px !important;
}

.date-time-box .event-date .modal-arrow img {
  width: 12px !important;
  height: auto !important;
}

.date-time-box .event-date big {
  color: #287fde;
  position: unset !important;
  font-size: 19px;
  white-space: nowrap;
  font-family: 'SFProDisplay-Bold';
  text-decoration: underline;
}

.searchContainer {
  display: flex;
  /* flex: 1 1 300px; */
  position: relative;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  align-items: center;
  margin-bottom: 2rem;
}

.searchIcon {
  padding: 0.5rem;
}

.searchBox {
  border: 0;
  padding: 0.5rem 0.5rem 0.5rem 0;
  flex: 1 1;
  outline: none;
  border-bottom: none;
}
input[type=search]:not(.browser-default), textarea.materialize-textarea {
  border-bottom: none;
}
input[type=search]:not(.browser-default):focus:not([readonly]), textarea.materialize-textarea:focus:not([readonly]) {
  border-bottom: none !important;
  webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.searchButton {
  background: #538AC5;
  border: 0;
  color: white;
  padding: 0.5rem;
  border-radius: 0;
}
#invite-name {
  margin-left:  0px !important;
}
.invite-alert-modal {
  max-width: 470px !important;
  min-height: 30vh !important;
}
/* #send-invitee-modal {
    z-index:  99999999999999 !important;
} */

.date-picker{
  border-bottom: none !important;
  opacity: 0;
  cursor: pointer;
}

.react-datepicker-ignore-onclickoutside{
  height: 2px !important;
}

.react-datepicker-wrapper{
  position: absolute;
  top: 30%;
}


.imgDiv{
  width: 15%;
  /* float: right; */
  justify-content: end;
  display: flex;
}

.no-found-class{
  font-weight: 500;
  text-align: center;
  width:500px;
  margin: auto;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 15px;
  font-size: 20px;
  color: #2d333e;
  word-spacing: 3px;
  padding: 2%;
  line-height: 2em;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.blur {
    /* display: none; */
    opacity: 0.3;
}

.myBookingClass {
    margin: 0 !important;
    padding: 0 !important;
    margin-left: 0 !important;
    -webkit-animation: bodyslidein 300ms forwards;
    animation: bodyslidein 300ms forwards;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.myBookingClass>.row {
    margin-top: 20px !important;
}



.myBookingCard {
    /* flex: 1 */
}

@media (max-width: 1050px) {
    .event-title-box {
        justify-content: center !important;
        width: 100%;
    }
}

@media (min-width: 1050px) {
    .event-title-box {
        justify-content: start !important;
        width: 100%;
    }
}

.imgDiv {
    width: 15%;
    display: flex;
    justify-content: flex-end;
}
.no-found-class{
    font-weight: 500;
    text-align: center;

}

.mybooking-layout-wrapper{
    display: flex;
    flex-wrap: wrap;
    padding-top: 80px !important;
    /* margin-left: 15px; */
    /* margin-top: 15px; */
    margin-left: 0px !important;
    margin-right: 10px !important;
}
